import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick/lib/slider';
import cmsService from '~/services/cms/pages';
import useHasMobileDownloadHeader from '~/hooks/useHasMobileDownloadHeader';
import { trackingViewPromotion } from '~/analytics';
import * as S from './style';
import CarouselItem from './components/CarouselItem';
import 'slick-carousel/slick/slick.css';
import { mockData } from './data';
import { flow } from '~/analytics/home';

const checkTopStyle = () => {
  const element = document.getElementById('m-main');
  if (element) {
    const style = window.getComputedStyle(element);
    return style.top !== '0px';
  }
  return false;
};

const HeroCarousel = () => {
  const [bannerData, setBannerData] = useState([]);
  const [height, setHeight] = useState(0);
  const hasMobileHeader = useHasMobileDownloadHeader();

  useEffect(() => {
    const header = document.getElementById('m-main-header');
    const hasTopOnBody = checkTopStyle();

    if (header && !hasTopOnBody) {
      setHeight(header.offsetHeight);

      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (entry.target.id === 'm-main-header') {
            setHeight(entry.target.offsetHeight);
          }
        });
      });

      resizeObserver.observe(header);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const settings = {
    afterChange: index => {
      const banner = bannerData[index];
      trackingViewPromotion(
        banner.redirectUrl,
        flow.assistencias,
        banner.title,
        banner.name
      );
    },
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 6000,
    overflow: 'hidden',
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'HeroRoot',
    accessibility: true,
  };

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerInsurance(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(mockData);
    } catch (error) {
      setBannerData(mockData);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  if (bannerData.length > 0) {
    return (
      <S.StyledContainer
        data-testid="banners"
        aria-label="Banner home insurance"
        bannerHeight={height}
        hasMobileHeader={hasMobileHeader}
      >
        <Slider
          {...settings}
          onInit={() => {
            const banner = bannerData[0];
            trackingViewPromotion(
              banner.redirectUrl,
              flow.home,
              banner.title,
              banner.name
            );
          }}
        >
          {bannerData?.map(item => (
            <CarouselItem key={item.title} {...item} />
          ))}
        </Slider>
      </S.StyledContainer>
    );
  }
  return null;
};

export default HeroCarousel;
