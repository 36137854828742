import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #cacecd;
  margin-top: 16px;
  line-height: 150%;
  font-size: 11px;
  strong {
    font-size: 14px;
    margin: 16px 0 10px 0;
  }
`;
