import styled from 'styled-components';

export const BilletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  text-align: center;
`;

export const Code = styled.span`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #252525;
`;

export const Description = styled.span`
  margin-top: 2rem;
  text-align: start;
  color: #646464;
`;

export const Title = styled.span`
  margin: 1rem;
  font-size: 18px;
  font-weight: 500;
`;
