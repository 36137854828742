import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@midway/web-ui-component';
import moment from 'moment';
import QRCode from '../../components/QRCode';
import ButtonCopy from '../../components/ButtonCopy';
import BilletDescription from '../../components/BilletDescription';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import { trackingSelectContent, trackingViewContent } from '~/analytics';
import { contentPage, contentType, flow } from '~/analytics/PaymentChannel';
import format from '~/services/format';
import * as S from './styles';
import { screens } from '~/pages/PaymentChannel/routes';

const Duplicate = () => {
  const navigate = useHistory();
  const { paymentChannelData } = usePaymentChannelContext();
  const { billet, elegibility } = paymentChannelData;

  const isPix = billet?.pixCopyAndPaste;

  const trackingContentPage = isPix
    ? contentPage.duplicatePix
    : contentPage.duplicateBillet;

  useEffect(() => {
    trackingViewContent(trackingContentPage, flow.cards, {
      contrato: paymentChannelData?.selected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingContentPage]);

  const onClickCopyHandler = () => {
    trackingSelectContent(
      isPix ? contentType.copyPix : contentType.copyBillet,
      flow.cards,
      trackingContentPage,
      {
        contrato: paymentChannelData?.selected,
      }
    );
  };

  const newSimulationHandler = () => {
    trackingSelectContent(
      contentType.newSimulation,
      flow.cards,
      trackingContentPage,
      {
        contrato: paymentChannelData?.selected,
      }
    );

    if (isEmpty(elegibility))
      return navigate.push(screens.ERROR, {
        contentPage: trackingContentPage,
      });

    navigate.push(screens.RESUME_TYPES);
  };

  return (
    <S.Wrapper>
      <S.ContainerContent>
        <S.Title>Você já possui um acordo vigente</S.Title>
        <S.Span size={14} color="#646464">
          Identificamos que você já realizou uma negociação em um dos nossos
          canais ou em uma das assessorias de cobrança parceiras.
        </S.Span>

        <S.Authenticate>
          <Icon icon="check" state="black" />
          Acordo autenticado pela Riachuelo
        </S.Authenticate>
        <S.ContainerDuplicate>
          <S.Flex direction="row">
            <S.Flex alignItems="center">
              <S.Span>Vencimento</S.Span>
              <S.Span weight="bold">
                {moment(billet?.dueDate).format('DD/MM/YYYY')}
              </S.Span>
            </S.Flex>
            <S.Flex alignItems="center">
              <S.Span>Valor a pagar</S.Span>
              <S.Span weight="bold">{format.currency(billet?.amount)}</S.Span>
            </S.Flex>
          </S.Flex>

          {isPix ? (
            <QRCode value={billet?.pixCopyAndPaste} />
          ) : (
            <BilletDescription value={billet?.formatTypeableLine} />
          )}

          <S.Flex alignItems="center" top={32}>
            <ButtonCopy
              isPix={isPix}
              toCopy={isPix ? billet?.pixCopyAndPaste : billet?.typeableLine}
              onClick={onClickCopyHandler}
            />
          </S.Flex>
        </S.ContainerDuplicate>

        <S.Flex>
          <S.Span size={18} weight="500">
            Deseja simular um novo acordo?
          </S.Span>
          <S.Span color="#646464" top={10}>
            Lembre-se que as condições negociadas anteriormente não são
            garantidas em uma nova negociação.
          </S.Span>
          <S.Flex top={16}>
            <Button
              block
              outline
              state="black"
              title="Simular novo acordo"
              aria-label="Simular novo acordo"
              size="lg"
              onClick={newSimulationHandler}
            />
          </S.Flex>
        </S.Flex>
      </S.ContainerContent>
    </S.Wrapper>
  );
};

export default Duplicate;
