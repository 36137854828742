import React from 'react';
import AppleStore from '~/assets/img/apple-store.png';
import GooglePlay from '~/assets/img/google-play.png';
import AppMidwayImg from '~/assets/img/CardBenefits/app-midway.png';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const DownloadTheApp = () => {
  return (
    <S.GrayBackground>
      <S.Container>
        <S.Title>Baixe agora o App Midway!</S.Title>
        <S.ImageContainer>
          <S.GrayCircle />
          <S.AppMidwayImage
            src={AppMidwayImg}
            draggable="false"
            alt="Imagem mostrando a tela de login do app midway"
          />
        </S.ImageContainer>
        <S.TextContainer>
          <S.Text>
            Tenha praticidade para consultar todos os produtos do seu cartão,
            pagar e parcelar a fatura também.
          </S.Text>
          <S.Text>Seus produtos financeiros em um só lugar!</S.Text>
        </S.TextContainer>
        <S.ButtonsContainer>
          <S.LinkButton
            href="https://play.google.com/store/apps/details?id=br.com.midway"
            title="Baixe nosso app na Google Play Store"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Baixe o nosso app na Play Store (abre uma nova janela)"
            onClick={() =>
              trackingSelectContent(
                'baixe-o-app-midway:google-play',
                'beneficios'
              )
            }
          >
            <img src={GooglePlay} alt="" role="presentation" />
          </S.LinkButton>
          <S.LinkButton
            href="https://apps.apple.com/br/app/midway-app/id1548732480"
            title="Baixe nosso app na Apple Store"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Baixe o nosso app na App Store (abre uma nova janela)"
            onClick={() =>
              trackingSelectContent(
                'baixe-o-app-midway:app-store',
                'beneficios'
              )
            }
          >
            <img src={AppleStore} alt="" role="presentation" />
          </S.LinkButton>
        </S.ButtonsContainer>
      </S.Container>
    </S.GrayBackground>
  );
};

export default DownloadTheApp;
