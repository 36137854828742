import React, { useEffect, useState } from 'react';
import { Image } from '@midway/web-ui-component';
import { trackingSelectContent } from '~/analytics';

import Banner from '~/assets/img/odonto/banner.svg';
import BannerMobile from '~/assets/img/odonto/banner-mobile.svg';
import Accordion from '~/components/Accordion';
import MidsMobile from '~/assets/img/odonto/mids-mobile.svg';
import MidsDesktop from '~/assets/img/odonto/mids-desktop.svg';
import MidsStore from '~/assets/img/odonto/mids-store.svg';
import MidsMap from '~/assets/img/odonto/mids-map.svg';
import ButtonFull from '~/assets/img/odonto/button-full.svg';
import MidsCheck from '~/assets/img/odonto/mids-check.svg';
import MidsCircleX from '~/assets/img/odonto/circle-x.svg';
import IphoneMockup from '~/assets/img/odonto/iphone-12-mockup.svg';
import Store from '~/assets/img/odonto/btn-store.svg';
import StoreApple from '~/assets/img/odonto/btn-store-apple.svg';
import Woman from '~/assets/img/odonto/mulher-seguro.svg';
import Odontoprev from '~/assets/img/odonto/logo-odonto-prev.svg';
import * as S from './styles';

const FLOW = 'assistencia-odonto';

export default function AssistanceOdontological24h() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 767);

  const [isOpenAccordion, setIsOpenAccordion] = useState(false);

  const toggleAccordion = () => {
    setIsOpenAccordion(!isOpenAccordion);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleClickSeeTopazioGeneralConditions = () => {
    trackingSelectContent(
      'consulte-os-termos-e-condicoes-dos-planos-topazio-g2',
      FLOW
    );
  };

  const handleClickSeeDiamondGeneralConditions = () => {
    trackingSelectContent(
      'consulte-os-termos-e-condicoes-dos-planos-diamond-g2',
      FLOW
    );
  };

  const handleClickSeeGeneralConditionsWellBeingPlan = () => {
    trackingSelectContent(
      'consulte-os-termos-e-condicoes-do-plano-bem-estar',
      FLOW
    );
  };

  const handleClickOdontoprevService = () => {
    trackingSelectContent('encontre-uma-rede-de-atendimento-odontoprev', FLOW);
  };

  const handleClickPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.midway&hl=en&gl=BR',
      '_blank'
    );
    trackingSelectContent('download:google-play', FLOW);
  };

  const handleClickAppleStore = () => {
    window.open(
      'https://apps.apple.com/br/app/midway-%C3%A9-riachuelo/id1548732480',
      '_blank'
    );
    trackingSelectContent('download:apple-store', FLOW);
  };

  return (
    <>
      <S.Container id="ready-page-for-screen-reader">
        <S.BannerContainer className="container-fluid">
          <Image
            src={!isMobile ? Banner : BannerMobile}
            alt="Imagem com uma mulher sorrindo olhando diretamente para a câmera."
            aria-hidden="true"
          />
          <div className="banner-title">
            <p className="is-primary-default bold uppercase color-white --title-page">
              Seu sorriso, nossa prioridade!
            </p>
            <p className="is-primary-default uppercase color-white --title-page">
              tratamento de qualidade para você e toda família.
            </p>
          </div>
          <div>
            <div className="background-benefits" />
            <div className="container benefits">
              <div className="row">
                <h2>Principais benefícios</h2>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <S.ContainerCards>
                    <S.Benefits className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <Image
                        src={MidsMobile}
                        alt="icone celular."
                        aria-hidden="true"
                      />
                      <p>
                        Acesso a informações do Plano direto pelo aplicativo
                      </p>
                    </S.Benefits>
                    <S.Benefits className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <Image
                        src={MidsDesktop}
                        alt="icone desktop."
                        aria-hidden="true"
                      />
                      <p>Conforto e liberdade com dentista online</p>
                    </S.Benefits>
                    <S.Benefits className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <Image
                        src={MidsStore}
                        alt="icone loja."
                        aria-hidden="true"
                      />
                      <p>Descontos exclusivos em lojas parceiras</p>
                    </S.Benefits>
                    <S.Benefits className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <Image
                        src={MidsMap}
                        alt="icone mapa."
                        aria-hidden="true"
                      />
                      <p>A maior rede credenciada do Brasil</p>
                    </S.Benefits>
                  </S.ContainerCards>
                </div>
              </div>
            </div>
          </div>
        </S.BannerContainer>

        <S.Session className="container">
          <S.Content className="row">
            <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h4 className="--title-page bold">Por quê contratar?</h4>
              <p className="subtitle">
                Muito mais do que o tratamento odontológico! A Odontoprev é uma
                plataforma de cuidado que inspira a autoestima e a busca pela
                melhor versão do seu sorriso!
              </p>
              <p className="subtitle">
                Da prevenção ao tratamento, nossos planos possuem ampla
                cobertura e a maior rede credenciada do Brasil. Além de
                benefícios que tornam a sua experiência segura e descomplicada.
              </p>
              <S.ColAnchor>
                <S.StyleAnchor
                  className="subtitle link"
                  href="https://www.midway.com.br/static/documents/tags/contratos/condicoes-gerais-assistencia-odontologica_plano-topazio.pdf"
                  target="_blank"
                  onClick={handleClickSeeTopazioGeneralConditions}
                >
                  Consulte os termos e condições dos Planos Topázio G2
                </S.StyleAnchor>
                <S.StyleAnchor
                  className="subtitle link"
                  href="https://www.midway.com.br/static/documents/tags/contratos/condicoes-gerais-assistencia-odontologica_plano-diamond.pdf"
                  target="_blank"
                  onClick={handleClickSeeDiamondGeneralConditions}
                >
                  Consulte os termos e condições do Plano Diamond G2
                </S.StyleAnchor>
                <S.StyleAnchor
                  className="subtitle link"
                  onClick={handleClickSeeGeneralConditionsWellBeingPlan}
                  target="_blank"
                  href="https://www.midway.com.br/static/documents/tags/contratos/condicoes-gerais-plano-bem-estar(exclusivo-para-reten%C3%A7%C3%A3o).pdf"
                >
                  Consulte os termos e condições do Plano Bem-estar
                </S.StyleAnchor>
              </S.ColAnchor>
              <S.BarLink
                onClick={() => {
                  window.open(
                    'https://www.odontoprev.com.br/redecredenciada/selecaoProduto?cdMarca=1',
                    '_blank'
                  );
                  handleClickOdontoprevService();
                }}
                data-gtm-event-action="clique:botao"
                data-gtm-event-label="atendimento-odontoprev"
              >
                <span>Encontre uma rede de atendimento Odontoprev</span>
                <Image src={ButtonFull} alt="seta" aria-hidden="true" />
              </S.BarLink>
            </S.Col>
            <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <br />
              <br />
              <h4 className="--title-page bold">Nossos planos</h4>
              <p className="subtitle">
                Conheça nossas opções de planos para encontrar a escolha ideal
                para você e sua família!
              </p>
            </S.Col>
            <div className="rowPlan">
              <S.Col className="planTable">
                <p className="title">Topázio</p>

                <ul>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Urgências e emergências;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Consultas;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Limpezas;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Restauração;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Tratamento de canal;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Tratamento infantil;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Cirurgias;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Raio-X;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Tratamento de gengiva;
                  </li>
                  <li>
                    <Image src={MidsCircleX} alt="check" aria-hidden="true" />{' '}
                    Documentação ortodôntica.
                  </li>
                </ul>
              </S.Col>
              <S.Col className="planTable">
                <p className="title">Diamond</p>

                <ul>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Urgências e emergências;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Consultas;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Limpezas;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Restauração;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Tratamento de canal;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Tratamento infantil;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Cirurgias;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Raio-X;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Tratamento de gengiva;
                  </li>
                  <li>
                    <Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Documentação ortodôntica.
                  </li>
                </ul>
              </S.Col>
            </div>
          </S.Content>
        </S.Session>

        <S.Session className="container mock">
          <div className="BG" />
          <S.Content className="row">
            <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6 iphoneMock">
              <Image
                src={IphoneMockup}
                alt="Mockup iphone"
                aria-hidden="true"
              />
            </S.Col>
            <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6 assistData">
              <p className="title">
                Para contratar a Assistência Odontológica é simples!
              </p>
              <ol>
                <li>Baixe o nosso aplicativo Midway;</li>
                <li>Encontre a opção Serviços e procure por Odonto;</li>
                <li>
                  Clique em contratar assistência e selecione para quem será a
                  assistência;
                </li>
                <li>Escolha o plano e informe os dados dos beneficiários;</li>
                <li>
                  Selecione a forma de pagamento. Confira o resumo, aceite os
                  termos e finalize a contratação.
                </li>
              </ol>
              <p className="subtitleStores">Baixe o aplicativo Midway</p>
              <div className="store-buttons">
                <Image
                  data-testid="playstore-button"
                  src={Store}
                  alt="Mockup iphone"
                  aria-hidden="true"
                  onClick={handleClickPlayStore}
                />
                <Image
                  data-testid="applestore-button"
                  src={StoreApple}
                  alt="Mockup iphone"
                  aria-hidden="true"
                  onClick={handleClickAppleStore}
                />
              </div>
            </S.Col>
          </S.Content>
        </S.Session>
        <S.Session className="container">
          <S.Content className="row">
            <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p className="subtitle">
                Você também pode contratar nas Lojas Riachuelo mais próximas ou,
                se preferir, pode optar pelas centrais de atendimento pelos
                telefones:
              </p>
              <p className="subtitle">
                Capitais e regiões metropolitanas: <br />
                <span className="underline">3004 5417</span>
              </p>
              <p className="subtitle">
                Demais localidades: <br />
                <span className="underline">0800 727 4417</span>
              </p>
            </S.Col>
          </S.Content>
        </S.Session>
      </S.Container>

      <S.BlackSession className="container-fluid">
        <S.Content className="row">
          <S.Col className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <Image
              src={Woman}
              alt="Mulher segurando celular"
              aria-hidden="true"
            />
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-7 col-xl-7 center">
            <div className="content">
              <h4>Para utilizar a Assistência Odonto</h4>
              <p>Entre em contato através dos telefones a seguir:</p>
              <p>
                Central de atendimento e relacionamento Odontoprev <br />
                <strong>0800 702 9000</strong>
              </p>
              <p>
                SAC <br />
                <strong>0800 702 2255</strong> <br />
                Serviços de Atendimento telefônico específico e exclusivo para
                Reclamações e cancelamentos
              </p>
              <p>
                Atendimento ao Deficiente Auditivo e de Fala <br />
                <strong>0800 722 2191</strong>
              </p>
            </div>
          </S.Col>
        </S.Content>
      </S.BlackSession>

      <S.Session className="container">
        <S.Content className="row faq">
          <S.AccordionTitle>Perguntas frequentes</S.AccordionTitle>
          <S.HandleShow
            onClick={() => {
              trackingSelectContent(
                isOpenAccordion
                  ? 'faq:fechar:o-que-o-plano-odontologico-oferece?'
                  : 'faq:abrir:o-que-o-plano-odontologico-oferece?',
                FLOW
              );
              toggleAccordion();
            }}
          >
            <Accordion
              key="1"
              title="O que o plano odontológico oferece?"
              content="Nossos planos possuem ampla cobertura e a maior rede credenciada do Brasil, além de vantagens e benefícios que tornam a sua experiência segura e descomplicada. Os planos garantem aos beneficiários, ou seja, as pessoas que o contratam, acesso regular a tratamentos em diversas especialidades, como limpeza dos dentes, tratamento de cáries, tratamento de gengivas e canal, extrações e restaurações, radiologias, entre outros."
            />
          </S.HandleShow>
          <S.HandleShow
            onClick={() => {
              trackingSelectContent(
                isOpenAccordion
                  ? 'faq:fechar:como-utilizar-o-plano-odontologico?'
                  : 'faq:abrir:como-utilizar-o-plano-odontologico?',
                FLOW
              );
              toggleAccordion();
            }}
          >
            <Accordion
              key="2"
              title="Como utilizar o plano odontológico?"
              content={`Primeiro, acesse o App Odontoprev ou portal beneficiário para localizar um dentista credenciado. Na opção "Busca de dentista", selecione a especialidade ou utilize a assistente para descobrir amais adequada para seu caso. em seguida, ative a localização ou preencha seu endereço para buscar e visualizar os profissionais de sua região. Pela Central de Atendimento e relacionamento 0800 771 3455 - ligação gratuita, você também pode solicitar informações sobre a utilização dos serviços oferecidos.`}
            />
          </S.HandleShow>
          <S.HandleShow
            onClick={() => {
              trackingSelectContent(
                isOpenAccordion
                  ? 'faq:fechar:qual-a-diferenca-entre-os-planos-oferecidos?'
                  : 'faq:abrir:qual-a-diferenca-entre-os-planos-oferecidos?',
                FLOW
              );
              toggleAccordion();
            }}
          >
            <Accordion
              key="3"
              title="Qual a diferença entre os planos oferecidos?"
              content="A principal diferença é a cobertura para documentação ortodôntica, ou seja, o conjunto de exames e procedimentos necessários para auxiliar no diagnóstico e definição do tratamento ortodôntico adequado."
            />
          </S.HandleShow>
        </S.Content>
      </S.Session>
      <S.Session className="container">
        <S.Content className="row">
          <S.Col className="col-sm-12 col-md-12 col-lg-5 col-xl-5 odontoprevImg">
            <Image
              src={Odontoprev}
              alt="Mulher segurando celular"
              aria-hidden="true"
            />
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-7 col-xl-7 odontoprevTxt">
            <p>
              Atuando desde 1987, somos uma plataforma de cuidado com a saúde
              bucal que oferece planos odontológicos para diversos perfis de
              cliente, como grandes corporações, pequenas e médias empresas e
              pessoas físicas. Nosso objetivo é seguir promovendo a odontologia
              nos quatro cantos do Brasil, com base em nossos valores de
              sustentabilidade, qualidade, segurança e inovação.
            </p>
          </S.Col>
        </S.Content>
      </S.Session>

      <S.Session className="container">
        <S.Content className="row">
          <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p className="subtitle">
              Plano Topázio G2 – no Registro ANS 480.830/18-4 e Plano Diamond G2
              – no Registro ANS 480.831/18-2 para a operadora Odontoprev S/A.
              Plano Rede Credenciada e Livre Escolha. Não é permitido a inclusão
              de titular e dependentes em planos diferentes.
            </p>
            <p className="subtitle">
              Plano Topázio G2 com coparticipação de 30% na especialidade de
              prótese. A coparticipação deverá ser paga diretamente ao dentista.
              Consulte os valores médios na tabela de eventos cobertos com e sem
              coparticipação que consta no Guia de Boas Vindas. Este plano não
              cobre os eventos na especialidade de Ortodontia, Implantes e
              alguns procedimentos de Prótese. Em atendimento à Lei 12.741/12 –
              Lei da Transparência Fiscal, à alíquota aproximada dos tributos
              incidentes sobre a operação de planos odontológicos (PIS/COFINS/
              ISS) é de R$ 3,91%. Valor sujeito a alteração, sem aviso prévio.
              Carências válidas a partir da data de adesão ao plano.
            </p>
            <p className="subtitle">
              Odontoprev S/A garante a assistência odontológica e possui a
              operação de planos privados de assistência odontológica.
            </p>
            <p className="subtitle">
              Midway Financeira possui contrato com a Odontoprev S/A com
              objetivo de atuar como canal de venda dos planos odontológicos.
            </p>
            <p className="subtitle">
              Lojas Riachuelo S/A é correspondente não bancário da Midway
              Financeira (Resolução 4935 Bacen) divulga e oferta os respectivos
              planos odontológicos.
            </p>
          </S.Col>
        </S.Content>
      </S.Session>
    </>
  );
}
