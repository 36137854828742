import React from 'react';

import arrowBackIcon from '~/assets/img/GuaranteedLimitLP/arrow-back.svg';
import userIcon from '~/assets/img/GuaranteedLimitLP/user.svg';
import ticketIcon from '~/assets/img/GuaranteedLimitLP/ticket.svg';
import creditCardIcon from '~/assets/img/GuaranteedLimitLP/credit-card.svg';
import brandRchloIcon from '~/assets/img/GuaranteedLimitLP/brands-rchlo.svg';
import {
  Container,
  Content,
  BenefitsContainer,
  Header,
  BenefitsWrapper,
  BenefitRow,
  BenefitContent,
  BenefitCard,
  Title,
  Text,
  Footer,
  BenefitText,
} from './styles';

export const Benefits = ({ isMobile }) => (
  <Container>
    <Content>
      <Header>
        <Title>Seu cartão com vários benefícios</Title>
        <Text>
          Além de todas as facilidades, o Cartão Riachuelo te oferece inúmeros
          benefícios para você viver a sua moda!
        </Text>
      </Header>
      <BenefitsContainer>
        <BenefitsWrapper>
          <BenefitRow mobile={isMobile}>
            <BenefitContent mobile={isMobile}>
              <BenefitCard>
                <img src={arrowBackIcon} alt="Icone de retorno de dinheiro" />
                <BenefitText>
                  <strong>10% de desconto*</strong> na primeira compra!
                </BenefitText>
              </BenefitCard>
            </BenefitContent>
            <BenefitContent mobile={isMobile}>
              <BenefitCard>
                <img src={userIcon} alt="Icone de uma pessoa" />
                <BenefitText>
                  Faça compras usando apenas o seu <strong>CPF</strong>
                </BenefitText>
              </BenefitCard>
            </BenefitContent>
          </BenefitRow>
          <BenefitRow>
            <BenefitContent mobile={isMobile}>
              <BenefitCard>
                <img src={ticketIcon} alt="Icone de benefícios" />
                <BenefitText>
                  <strong>Descontos</strong> e benefícios no mundo RCHLO
                </BenefitText>
              </BenefitCard>
            </BenefitContent>
            <BenefitContent mobile={isMobile}>
              <BenefitCard>
                <img src={creditCardIcon} alt="Icone de cartão de crédito" />
                <BenefitText>
                  Condições especiais de <strong>parcelamentos</strong>
                </BenefitText>
              </BenefitCard>
            </BenefitContent>
          </BenefitRow>
        </BenefitsWrapper>
      </BenefitsContainer>
      <Footer>
        <p>Aproveite em todas as lojas do Grupo:</p>
        <img
          src={brandRchloIcon}
          alt="Na imagem, temos o cartão das Lojas Riachuelo, contendo o nome/logo da Midway e da Riachuelo, juntamente com o número e nome do titular do cartão, de forma ilustrativa. O cartão é na cor preta e apresenta um relevo quadriculado."
        />
        <span>
          *Desconto válido para cartões da loja e em compras feitas no app, site
          ou lojas físicas. Exceto eletrônicos.
        </span>
      </Footer>
    </Content>
  </Container>
);
