import React, { useCallback, useEffect, useRef, useState } from 'react';
import DownloadButtonApp from '~/components/DownloadButtonApp';
import Modal from '~/components/Modal';
import { trackingSelectContent } from '~/analytics';
import { banner } from './data';
import RenderBannerImage from '~/components/RenderBannerImage';
import cmsService from '~/services/cms/pages';
import CardImage from '~/assets/img/cartoes-pl/cartoes_pl.png';
import Iphone from '~/assets/img/cartoes-pl/iphone.png';
import QrCodeImage from '~/assets/img/cartoes-pl/qrcode.svg';

import CardIconModal from '~/assets/img/cartoes-pl/card.svg';
import GiftIconModal from '~/assets/img/cartoes-pl/gift.svg';
import CoinIconModal from '~/assets/img/cartoes-pl/coin.svg';
import ConfettiIconModal from '~/assets/img/cartoes-pl/confetti.svg';
import FileCheckIconModal from '~/assets/img/cartoes-pl/file-check.svg';
import PercentageIconModal from '~/assets/img/cartoes-pl/percentage.svg';
import ShoppingIconModal from '~/assets/img/cartoes-pl/shopping-cart.svg';
import BuildingIconModal from '~/assets/img/cartoes-pl/building-store.svg';

import CalendarEventImage from '~/assets/img/cartoes-pl/calendar-event.svg';
import CreditCardImage from '~/assets/img/cartoes-pl/credit-card.svg';
import GiftImage from '~/assets/img/cartoes-pl/gift-block.svg';
import NotesImage from '~/assets/img/cartoes-pl/notes.svg';
import ReportMoneyImage from '~/assets/img/cartoes-pl/report-money.svg';
import ShoppingCartImage from '~/assets/img/cartoes-pl/shopping-cart-discount.svg';
import PlayStore from '~/assets/img/cartoes-pl/play-store.svg';
import AppleStore from '~/assets/img/cartoes-pl/apple-store.svg';
import Card from './components/Card';
import * as S from './styles';

const Anchor = ({ href, children, onClick }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor onClick={onClick} href={href}>
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

const BlockModalOptions = ({ icon, description, observation }) => {
  return (
    <S.ContainerBlockModal>
      <S.CardModal>
        <S.IconModal src={icon} />
        <S.CentralizeTextModal>
          <S.DescriptionModal>{description}</S.DescriptionModal>
          {observation && (
            <S.ObservationModal>{observation}</S.ObservationModal>
          )}
        </S.CentralizeTextModal>
      </S.CardModal>
    </S.ContainerBlockModal>
  );
};

const CardsPl = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerRiachueloCard(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banners = bannerData[0] || banner;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    trackingSelectContent('fechar:peca-agora', 'cartao-rchlo');
  };

  const ScrollDown = useRef(null);

  const handleClick = () => {
    ScrollDown.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <S.ModalStyle>
            <S.RightModal>
              <S.AdjustamentRightModal>
                <S.TitleModal>Peça já o seu cartão Riachuelo </S.TitleModal>
                <S.TextModal>E aproveite todas as vantagens</S.TextModal>
                <S.CentralizeCardsModal>
                  <S.ModalOptions>
                    <BlockModalOptions
                      icon={GiftIconModal}
                      description="Ganhe 10% OFF na primeira compra!"
                      observation="Válido para cartões da loja e em compras feitas no app, site ou lojas físicas. Exceto eletrônicos"
                    />
                    <BlockModalOptions
                      icon={CoinIconModal}
                      description="Condições especiais de parcelamento no site e nas lojas físicas"
                    />
                    <BlockModalOptions
                      icon={PercentageIconModal}
                      description="Até 35% de desconto em farmácias parceiras"
                    />
                    <BlockModalOptions
                      icon={FileCheckIconModal}
                      description="Acesse e pague sua fatura nos apps Midway ou Riachuelo"
                    />
                    <BlockModalOptions
                      icon={CardIconModal}
                      description="Programa Mastercard Surpreenda*"
                      observation="*Apenas para clientes do cartão Mastercard."
                    />
                    <BlockModalOptions
                      icon={ConfettiIconModal}
                      description="Parcerias exclusivas"
                    />
                    <BlockModalOptions
                      icon={BuildingIconModal}
                      description="Entrega instantânea do cartão RCHLO na loja"
                    />
                    <BlockModalOptions
                      icon={ShoppingIconModal}
                      description="Compra on-line com retirada em loja"
                    />
                  </S.ModalOptions>
                </S.CentralizeCardsModal>
                <S.TextfinalModal>
                  Baixe o aplicativo da Riachuelo, peça o seu cartão e já comece
                  a usar!
                </S.TextfinalModal>
              </S.AdjustamentRightModal>
              <S.QRCodeImage src={QrCodeImage} />
              <S.ModalButton
                data-testid="modal-button"
                target="_blank"
                href="https://www.midway.com.br/app/"
              >
                Baixar App Riachuelo
              </S.ModalButton>
            </S.RightModal>
          </S.ModalStyle>
        </Modal>
      )}

      <S.Container>
        <S.ContainerGlobal>
          <S.BackgroundContainer>
            <S.BannerContainer data-testid="banner-click" onClick={handleClick}>
              <RenderBannerImage {...banners} />
            </S.BannerContainer>
          </S.BackgroundContainer>
          <S.CentralizeContent>
            <S.BlockCard ref={ScrollDown}>
              <S.CentrilizeBlock>
                <S.LeftSection>
                  <S.LeftImage src={CardImage} />
                </S.LeftSection>
                <S.RightSection>
                  <S.AdjustamentRight>
                    <S.RightTitle>Cartão Riachuelo</S.RightTitle>
                    <S.RightText>
                      Limite disponível:{' '}
                      <S.TextBold fontSize="20px">Sujeito à análise</S.TextBold>
                    </S.RightText>
                    <S.RightText>
                      Anuidade: <S.TextBold fontSize="20px">Grátis</S.TextBold>
                    </S.RightText>
                    <S.RightText>
                      Utilização:{' '}
                      <S.TextBold fontSize="20px">
                        Em todas as lojas e site Riachuelo
                      </S.TextBold>
                    </S.RightText>
                    <S.RightButton
                      data-testid="button-request-now"
                      onClick={openModal}
                    >
                      <div
                        data-testid="button-request"
                        onClick={() =>
                          trackingSelectContent(
                            'veja-o-contrato-do-cartao-rchlo',
                            'cartao-rchlo'
                          )
                        }
                      >
                        Peça agora
                      </div>
                    </S.RightButton>
                    <S.RightText>
                      <Anchor
                        onClick={() =>
                          trackingSelectContent(
                            'veja-o-contrato-do-cartao-rchlo',
                            'cartao-rchlo'
                          )
                        }
                        target="_blank"
                        href="https://www.midway.com.br/static/documents/tags/contratos/contrato-cartao-private-label-30-10.pdf"
                      >
                        Veja o contrato do Cartão RCHLO
                      </Anchor>
                    </S.RightText>
                    <S.RightText>
                      <Anchor
                        onClick={() =>
                          trackingSelectContent(
                            'consulte-taxas-e-tarifas',
                            'cartao-rchlo'
                          )
                        }
                        target="_blank"
                        href="https://www.midway.com.br/tarifas-rchlo"
                      >
                        Consulte taxas e tarifas
                      </Anchor>
                    </S.RightText>
                  </S.AdjustamentRight>
                </S.RightSection>
              </S.CentrilizeBlock>
            </S.BlockCard>
          </S.CentralizeContent>
          <S.CentralizeCards data-testid="card-benefits">
            <S.AdjustamentTitleCard>
              <S.TitleCards>Conheça todos os nossos benefícios</S.TitleCards>
            </S.AdjustamentTitleCard>
            <S.ContainerCards>
              <Card
                icon={GiftImage}
                title="Ganhe 10% de desconto na primeira compra!"
                description="Desconto válido para cartões da loja e em compras feitas no app, site ou lojas físicas. Exceto eletrônicos"
              />
              <Card
                icon={CreditCardImage}
                title="Parcele compras em 5 vezes sem juros"
                description="Com o Cartão Riachuelo você compra em até 5 vezes sem juros em nossas lojas físicas e virtual."
              />
              <Card
                icon={CalendarEventImage}
                title="Comece a pagar em até 60 dias de 8 a 10 vezes com juros¹"
                description="Fazendo o Cartão Riachuelo, você tem a opção de começar a pagar em até 60 dias da data de sua compra com condições especiais de parcelamento¹."
              />
              <Card
                icon={NotesImage}
                title="Cadastro simplificado"
                description="Aquisição do seu cartão de uma forma simplificada e em menos de 7 minutos em nossas lojas Riachuelo e aplicativo Riachuelo."
                observation="*Valor de referência da rede das nossas lojas, podendo ter variações. "
              />
              <Card
                icon={ReportMoneyImage}
                title="Aquisição de outros produtos financeiros"
                description="Você pode adquirir os produtos financeiros da Riachuelo (seguros, assistências e empréstimos)."
              />
              <Card
                icon={ShoppingCartImage}
                title="Descontos e benefícios no ecossistema Riachuelo"
                description="Utilize seu cartão nas nossas lojas Riachuelo, Casa Riachuelo, Carter's e FanLab."
              />
            </S.ContainerCards>
          </S.CentralizeCards>
          <S.CentralizePanel>
            <S.InformationPanel>
              <S.LeftPanel>
                <S.LeftImagePanel src={Iphone} />
              </S.LeftPanel>
              <S.RightPanel>
                <S.CentralizeRightPanel>
                  <S.RchloLogo>RCHLO</S.RchloLogo>
                  <S.TextPanel fontSize="23px">
                    Baixe o App Riachuelo
                  </S.TextPanel>
                  <S.TextPanel fontSize="18px">
                    Que tal fazer um cartão de maneira simples e prática?
                  </S.TextPanel>
                  <S.AdjustamentButton>
                    <div
                      data-testid="google-play-button"
                      onClick={() =>
                        trackingSelectContent(
                          'download:-google-play',
                          'cartao-rchlo'
                        )
                      }
                    >
                      <DownloadButtonApp
                        iconSrc={PlayStore}
                        onClick={() =>
                          (window.location.href =
                            'https://play.google.com/store/apps/details?id=br.com.riachuelo.app')
                        }
                      >
                        Download via{' '}
                        <S.TextBold fontSize="18px">Google Play</S.TextBold>
                      </DownloadButtonApp>
                    </div>
                    <div
                      data-testid="apple-store-button"
                      onClick={() =>
                        trackingSelectContent(
                          'download-apple-store',
                          'cartao-rchlo'
                        )
                      }
                    >
                      <DownloadButtonApp
                        iconSrc={AppleStore}
                        onClick={() =>
                          (window.location.href =
                            'https://apps.apple.com/br/app/riachuelo-comprar-roupas/id1488532824')
                        }
                      >
                        Download via{' '}
                        <S.TextBold fontSize="18px">Apple Store</S.TextBold>
                      </DownloadButtonApp>
                    </div>
                  </S.AdjustamentButton>
                </S.CentralizeRightPanel>
              </S.RightPanel>
            </S.InformationPanel>
          </S.CentralizePanel>
          <S.ObservationSectionContainer>
            <S.ObservationText>
              ¹Condição de parcelamento somente para compras em lojas físicas da
              Riachuelo, com pagamento exclusivo com Cartão Riachuelo, em até
              10x com juros e sem entrada, com início do pagamento em até 60
              dias e juros de 7,9% ao mês ou 149,03% ao ano + IOF, inclusive
              sobre o período de carência (60 dias), condicionada à data de
              compra e à data de vencimento da fatura. Sujeita a atualização
              cadastral. Condições válidas para todos os produtos, exceto:
              eletrônicos, perfumaria, relógios e recarga e celular.
            </S.ObservationText>
          </S.ObservationSectionContainer>
        </S.ContainerGlobal>
      </S.Container>
    </>
  );
};

export default CardsPl;
