import styled from 'styled-components';

export const Container = styled.div`
  max-width: 560px;
  display: flex;
  height: auto;

  @media screen and (max-width: 1000px) {
    width: 100% !important;
    max-width: 100%;
  }
`;

export const Card = styled.div`
  max-width: 600px;
  min-height: 240px;
  padding: 20px;
  border: 2.2px solid #1f2b2a;
  border-radius: 24px;
  background-color: #fff;

  @media (max-width: 1000px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const Icon = styled.img`
  width: 10%;
  margin-bottom: 10px;
`;

export const Title = styled.p`
  font-size: 18px;
  font-family: Montserrat;
  line-height: 32px;
  font-weight: bold;
  padding-bottom: 16px;
`;

export const Line = styled.div`
  max-width: 40px;
  border: 1px solid #a06d2c;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  font-size: 16px;
`;

export const Observation = styled.p`
  padding-top: 5px;
  font-size: 12px;
`;
