/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { IconSignRight } from '@tabler/icons-react';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';
import Modal from '../../../components/Modal';

const OurCashbacks = () => {
  const [isCashbackModalOpen, setIsCashbackModalOpen] = useState(false);

  return (
    <>
      <S.DarkBackground>
        <S.Container>
          <S.TitleContainer>
            <S.Title>Nossos cashbacks</S.Title>
            <S.Text white>
              Quanto mais você compra, mais desconto você tem!
            </S.Text>
          </S.TitleContainer>
          <S.CardContainer>
            <S.Card>
              <S.CardTitle>
                <IconSignRight />
                Compras fora das Lojas Riachuelo
              </S.CardTitle>
              <S.CardDescription>
                Garanta 5% de cashback em compras fora da Riachuelo!
              </S.CardDescription>
              <S.CardText>
                Disponível para uso nas Lojas Riachuelo e vinculado ao CPF
              </S.CardText>
              <S.CardText>
                Exclusivo para clientes selecionados e com Cartão Mastercard
              </S.CardText>
              <S.Button
                onClick={() => {
                  trackingSelectContent(
                    'confira-as-condicoes-do-cashback',
                    'beneficios'
                  );
                  setIsCashbackModalOpen(true);
                }}
              >
                Confira as condições do cashback
              </S.Button>
            </S.Card>
          </S.CardContainer>
        </S.Container>
      </S.DarkBackground>
      <Modal
        title="Condições do cashback"
        isOpen={isCashbackModalOpen}
        onClose={() => setIsCashbackModalOpen(false)}
      >
        <S.ModalContent>
          <S.Text bold>
            Cashback de 5% válido no uso do Cartão Riachuelo Mastercard como
            forma de pagamento em estabelecimentos fora das lojas do Grupo
            Riachuelo, durante o período de um mês e totalizando um valor acima
            de R$1.500,00.
          </S.Text>
          <S.Text>
            O valor do Cashback será considerado como desconto nas compras
            realizadas com o mesmo Cartão nas lojas físicas Riachuelo, FANLAB,
            Carter's ou Casa Riachuelo, exceto para as lojas de Recife, Vitória
            de Santo Antão, Cabo de Santo Agostinho, Jaboatão dos Guararapes,
            Camaragibe, Paulista, Olinda, Sorocaba, Votorantim e Itu, durante o
            mês seguinte (Aapós o dia 10 com validade de 30 dias).
          </S.Text>
          <S.Text>
            O desconto do cashback é limitado a 50% do valor total da compra e
            válido mediante ao uso do Cartão Riachuelo Mastercard como forma de
            pagamento. Não é válido nas compras de produtos das categorias de
            Eletrônicos, Eletroportáteis, Celulares, Relógios e Recarga de
            Celulares. Não é cumulativo e no momento da compra será aplicado o
            maior desconto disponível e atrelado ao CPF do portador do cartão.
          </S.Text>
          <S.Text>
            Em caso de cancelamento da compra, o valor do crédito gerado será
            estornado automaticamente. Para valores em fatura, relacionados a
            Seguros, Assistências e Empréstimos, não serão considerados para o
            cálculo do Cashback de 5% sobre o valor gasto em estabelecimentos
            fora do Grupo Riachuelo.
          </S.Text>
          <S.Text>
            A campanha é condicionada aos clientes que estiverem com seus
            pagamentos em dia (sem atraso ou inadimplência). O limite disponível
            poderá sofrer alterações mediante uso do cartão ou política vigente.
            Para conferir o seu, acesse o aplicativo Riachuelo.
          </S.Text>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default OurCashbacks;
