import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import { screens } from '~/pages/PaymentChannel/routes';
import { default as GlobalLoading } from '~/components/Loading';

import * as S from './styles';

const Loading = () => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const navigate = useHistory();
  const pathName = navigate?.location?.pathname;

  if (pathName === screens.HOME || pathName === screens.INSTALLMENTS)
    return null;
  if (isFetching === 0 && isMutating === 0) return null;

  return (
    <S.Overlay>
      <div>
        <GlobalLoading size={10} />
        <S.Message>Aguarde</S.Message>
      </div>
    </S.Overlay>
  );
};

export default Loading;
