import React from 'react';
import * as S from './styles';

const SectionFindNewWays = () => {
  const list = [
    {
      icon: <S.IconWhatsapp />,
      content: (
        <S.Text>
          Realize negociações de dívidas pelo{' '}
          <a
            href="https://api.whatsapp.com/send/?phone=551130030950"
            target="_blank"
            rel="noopener noreferrer"
          >
            Whatsapp RCHLO
          </a>
        </S.Text>
      ),
    },
    {
      icon: <S.IconRchlo />,
      content: (
        <S.Text>
          Confira saldo, fatura e boletos{' '}
          <a
            href="https://www.riachuelo.com.br/baixe-o-app"
            target="_blank"
            rel="noopener noreferrer"
          >
            no app RCHLO
          </a>
        </S.Text>
      ),
    },
    {
      icon: <S.IconMidway />,
      content: (
        <S.Text>
          Confira saldo, fatura e boletos{' '}
          <a
            href="https://www.midway.com.br/app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            no app Midway
          </a>
        </S.Text>
      ),
    },
    {
      icon: <S.IconStore />,
      content: (
        <S.Text>
          Resolva assuntos financeiros em{' '}
          <a
            href="https://www.midway.com.br/buscar-lojas"
            target="_blank"
            rel="noopener noreferrer"
          >
            nossas lojas Rchlo
          </a>
        </S.Text>
      ),
    },
  ];

  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.Title>
            Descubra novas formas de pagar em nossos canais digitais
          </S.Title>
          <S.List>
            {list.map((li, index) => (
              <S.ListItem key={index}>
                {li.icon}
                {li.content}
              </S.ListItem>
            ))}
          </S.List>
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default SectionFindNewWays;
