import styled from 'styled-components';
import { INPUT_STATE } from '~/pages/InvoiceInstallment/components/InstallmentSimulation/components/EntryValueInput';
import { colors } from '~/constants';

export const Input = styled.input`
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  width: 100%;

  border: none;
  border-bottom: 1px solid
    ${({ state }) => (state === INPUT_STATE.ERROR ? colors.error : colors.dark)};
  background-color: ${colors.light_gray};
  padding: 8px 0;
  margin-bottom: 4px;

  font-size: 1rem;
  font-weight: 400;

  color: ${colors.black};
`;
