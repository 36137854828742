import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import PaymentChannelRoutes from '~/pages/PaymentChannel/routes';
import Loading from '~/pages/PaymentChannel/components/Loading';
import { PaymentChannelProvider } from '~/context/PaymentChannel';

const PaymentChannel = () => {
  return (
    <PaymentChannelProvider>
      <MemoryRouter>
        <Loading />
        <PaymentChannelRoutes />
      </MemoryRouter>
    </PaymentChannelProvider>
  );
};

export default PaymentChannel;
