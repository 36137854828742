import { useQuery } from '@tanstack/react-query';
import CollectionApi from '~/services/CollectionApi';
import { usePaymentChannelContext } from '../../../context/PaymentChannel';

const useBalance = ({ document, contract }) => {
  const { updateData } = usePaymentChannelContext();

  const queryFn = async ({ document, contract }) => {
    const response = await CollectionApi.getBalance(
      document,
      contract,
      'cards'
    );

    if (!response?.detail || response.error) {
      throw new Error(response.message ?? response.error);
    }

    updateData('balance', response?.detail?.cards);

    return response?.detail?.cards;
  };

  return useQuery([document, contract], {
    queryFn: () => queryFn({ document, contract }),
    enabled: Boolean(document, contract),
    refetchOnWindowFocus: false,
  });
};

export default useBalance;
