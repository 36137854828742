import styled from 'styled-components';
import { ContentContainer, ContentTitle } from '~/pages/PaymentChannel/styles';

export const Wrapper = styled.div`
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  background-color: #f5f5f5;
`;

export const ContainerContent = styled(ContentContainer)``;

export const Title = styled(ContentTitle)``;

export const SubTitleDescription = styled.span`
  color: #646464;
  margin-top: 6px;
`;

export const ContainerMethod = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 8px;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 24px;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justify }) => (justify ? justify : 'space-between')};
  margin-top: ${({ top }) => top}px;

  svg {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
  }
`;

export const TitleMethod = styled.span`
  font-size: ${({ size }) => (size ? size : 16)}px;
  font-weight: 500;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  flex-wrap: wrap;
`;

export const TitleRow = styled(Row)`
  margin-bottom: 16px;
  margin-top: 0;
`;

export const EditButton = styled.button`
  border: none;
  background: transparent;

  svg {
    stroke: #221f20;
  }
`;

export const TextKey = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  color: #221f20;
`;

export const TextValue = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: #221f20;
`;

export const TextDescription = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  flex-basis: 100%;
  color: #646464;
`;

export const Separator = styled.hr`
  border: 1px solid #cacecd;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  height: 0;
`;

export const DescriptionMethod = styled.span`
  margin-top: 8px;
  color: #646464;
`;

export const ContainerResume = styled.div`
  background: #ffffff;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;

  &:first-of-type {
    margin-top: 2rem;
  }

  &:last-of-type {
    margin-bottom: 2rem;
  }
`;

export const Span = styled.span`
  font-size: ${({ size }) => (size ? size : 16)}px;
  font-weight: ${({ weight }) => weight};
  text-decoration: ${({ line }) => line && 'line-through'};
  color: ${({ color }) => color};
`;
