export const faqData = [
  {
    id: 1,
    title: 'Sobre o produto',
    questions: [
      {
        id: 1,
        title: 'O que é o Empréstimo Pessoal?',
        content:
          'O Empréstimo Pessoal é uma linha de crédito projetada para quem precisa de ajuda financeira sem um objetivo específico. Diferente de outras modalidades, como o Empréstimo Imobiliário, por exemplo, no Empréstimo Pessoal você pode utilizar o valor para qualquer finalidade — seja para pagar contas, fazer um curso ou realizar uma reforma. Além disso, a contratação do crédito na Midway é ágil, e você ainda pode escolher se prefere receber o dinheiro diretamente em sua conta (Crédito Pessoal) ou sacar o valor no momento (Saque Fácil).',
      },
      {
        id: 2,
        title: 'O que é Saque Fácil e Crédito Pessoal?',
        content:
          'O Saque Fácil e o Crédito Pessoal são as opções disponíveis para você receber o valor do seu Empréstimo Pessoal. Ambos são exclusivos para clientes que possuem o cartão Riachuelo ou são titulares da conta Midway. As condições são individuais para cada cliente e dependem da análise de crédito. O limite máximo é de R$ 17 mil, e é possível parcelar em até 36 vezes, com o pagamento da primeira parcela em até 90 dias, mediante aprovação. Consulte suas condições.',
      },
      {
        id: 3,
        title: 'Qual a diferença entre Saque Fácil e Crédito Pessoal?',
        content:
          'A diferença entre os dois é que, no Saque Fácil, o cliente pode sacar o dinheiro diretamente no caixa das lojas Riachuelo (dinheiro em mãos). Já no Crédito Pessoal, o valor é transferido para a conta do cliente (conta corrente, conta de pagamento ou poupança), com o crédito realizado em até 1 dia útil. Caso o Crédito Pessoal seja contratado pelo app da conta Midway, o valor é creditado na hora na sua conta.',
      },
      {
        id: 4,
        title: 'Em quanto tempo recebo o Crédito Pessoal?',
        content:
          'Se você for cliente da conta Midway e fizer a contratação pelo app, o valor será creditado na hora diretamente na sua conta. No entanto, se você indicar outra conta de sua titularidade, o valor será creditado no momento da contratação, mas estará disponível na conta indicada em até 1 dia útil. Lembre-se de informar corretamente os dados bancários para evitar a necessidade de refazer a operação.',
      },
      {
        id: 5,
        title: 'Qual é o limite máximo?',
        content:
          'O limite máximo é individual e varia de acordo com o seu perfil, podendo chegar a até R$ 17 mil.',
      },
      {
        id: 6,
        title: 'O que é carência? E qual é a carência máxima?',
        content:
          'A carência é o período entre a contratação do empréstimo e o pagamento da primeira parcela. Atualmente, oferecemos carências que variam de 30 a 90 dias, de acordo com o perfil de crédito. Com base no período de carência escolhido, o sistema calcula automaticamente a data de vencimento da primeira parcela.',
      },
      {
        id: 7,
        title: 'O que é prazo? E qual é o prazo máximo?',
        content:
          'O prazo é o período de tempo no qual você pagará o empréstimo, com uma parcela vencendo a cada mês. Os prazos para pagamento do valor emprestado variam entre 7 e 36 meses, de acordo com o seu perfil de crédito. Por exemplo, se você optar pelo Saque Fácil em 21 parcelas fixas, terá uma parcela a pagar mensalmente durante 21 meses.',
      },
      {
        id: 8,
        title: 'Em qual o tipo de conta posso receber o meu Crédito Pessoal?',
        content:
          'Conta corrente, conta de pagamento ou conta poupança, desde que seja de sua titularidade. Você também pode receber o valor na conta Midway e optar pelo pagamento em débito automático, evitando a preocupação de lembrar do pagamento da parcela todo mês.',
      },
      {
        id: 9,
        title: 'Quais bancos são aceitos para o Crédito Pessoal?',
        content:
          'Atualmente trabalhamos com todos os bancos, inclusive bancos digitais.',
      },
      {
        id: 10,
        title: 'O que é IOF?',
        content:
          'IOF é a sigla para Imposto sobre Operações Financeiras. Esse imposto é pago por pessoas físicas e jurídicas (empresas) que realizam ou contratam operações de crédito, como empréstimos. A porcentagem do imposto sobre o valor depende do tipo de operação.',
      },
      {
        id: 11,
        title: 'O que é CET?',
        content:
          'CET é a sigla para Custo Efetivo Total, que corresponde a todas as despesas e encargos relacionados a operações de crédito. O Custo Efetivo Total deve ser expresso em uma taxa percentual anual, incluindo todos os encargos e despesas da operação. Isso significa que o CET engloba não apenas a taxa de juros, mas também tarifas, seguros, tributos e outras despesas cobradas do cliente.',
      },
      {
        id: 12,
        title: 'Tenho limites distintos para Saque Fácil e Crédito Pessoal?',
        content:
          'Não, existe um único limite para o empréstimo, seja na modalidade Saque Fácil ou Crédito Pessoal. Se você tem um limite pré-aprovado de R$ 7 mil e já utilizou R$ 3 mil no Saque Fácil, ainda pode contratar um novo empréstimo no Crédito Pessoal, utilizando o valor restante de R$ 4 mil.',
      },
      {
        id: 13,
        title: 'Como sei o meu limite disponível?',
        content:
          'Você pode consultar seu limite no app Midway, na Central de Relacionamento ou em qualquer Loja Riachuelo. No app, basta acessar a área de empréstimos para visualizar as informações disponíveis.',
      },
      {
        id: 14,
        title: 'O limite é reestabelecido conforme o pagamento das parcelas? ',
        content:
          'Sim, à medida que você paga as parcelas, seu limite é restabelecido, sujeito à análise de crédito.',
      },
      {
        id: 15,
        title:
          'A Midway tem a opção do seguro atrelado ao Empréstimo (Seguro Proteção Financeira)?',
        content:
          'No momento da contratação do empréstimo, o cliente pode optar pelo Seguro Proteção Financeira, caso disponível. A oferta do seguro varia conforme o perfil do cliente.',
      },
    ],
  },
  {
    id: 2,
    title: 'Elegibilidade do cliente',
    questions: [
      {
        id: 1,
        title:
          'Quem tem direito ao produto do Empréstimo (Saque Fácil e Crédito Pessoal)?',
        content:
          'Os produtos de empréstimo são exclusivos para clientes do Cartão Riachuelo (PL ou Bandeira) ou da Conta Midway, sujeitos à aprovação na análise de crédito.',
      },
      {
        id: 2,
        title: 'Como é feita análise de crédito?',
        content:
          'Antes de definir seu limite, taxa, carência e prazo, avaliamos sua saúde financeira com base em diversos critérios, como seu histórico de crédito no mercado.',
      },
      {
        id: 3,
        title:
          'Preciso de dinheiro, mas não tenho limite pré-aprovado. O que fazer?',
        content:
          'Sentimos por não poder ajudar neste momento. A análise de crédito é revisada mensalmente, então você pode tentar novamente em 30 dias.',
      },
      {
        id: 4,
        title:
          'Se atraso a fatura do cartão de crédito, perco o limite disponível do Empréstimo?',
        content:
          'Sim, a inadimplência no Cartão de Crédito Riachuelo torna o cliente inelegível para a contratação do empréstimo.',
      },
      {
        id: 5,
        title:
          'O limite do Empréstimo influencia no limite do meu cartão de crédito?',
        content:
          'Não, os limites são independentes. A utilização de um não afeta o limite do outro.',
      },
      {
        id: 6,
        title:
          'Tenho limite disponível, porém na simulação meu Empréstimo foi negado. O que acontece?',
        content:
          'Após a simulação para contratação do empréstimo, realizamos uma validação final com dados internos e externos, o que pode resultar na não aprovação do Empréstimo Pessoal.',
      },
      {
        id: 7,
        title: 'Existe uma renda mínima para solicitação de Empréstimo?',
        content:
          'Não, a Midway leva em consideração outros fatores na hora de conceder crédito.',
      },
    ],
  },
  {
    id: 3,
    title: 'Contratação',
    questions: [
      {
        id: 1,
        title:
          'Onde posso contratar o Empréstimo (Saque Fácil e Crédito Pessoal)?',
        content:
          'Saque Fácil: A contratação do Saque Fácil pode ser feita nas lojas Riachuelo, onde você já sai com o dinheiro em mãos. Crédito Pessoal: O Crédito Pessoal pode ser contratado nas lojas, no aplicativo ou pela Central de Atendimento.',
      },
      {
        id: 2,
        title:
          'Quais documentos são necessários para a realização do Empréstimo?',
        content:
          'Nas lojas, você precisará de um documento de identificação (original com foto) e do cartão Riachuelo. No aplicativo e na Central de Atendimento, não é necessário apresentar documentos.',
      },
      {
        id: 3,
        title: 'Quanto tempo leva a contratação do Empréstimo na Midway?',
        content:
          'Apenas alguns minutos. Com o crédito pré-aprovado, a contratação é muito rápida e simples.',
      },
      {
        id: 4,
        title:
          'Preciso informar os meus dados bancários para fazer um Empréstimo?',
        content:
          'Atenção! A Midway solicita os dados bancários apenas em caso de contratação do Crédito Pessoal, caso você opte por receber o valor na conta. Nunca pedimos senhas pessoais ou dígitos do cartão Riachuelo.',
      },
      {
        id: 5,
        title: 'É necessário ter conta corrente para contratar um Empréstimo?',
        content:
          'Você pode contratar um empréstimo mesmo sem ter uma conta corrente, utilizando a opção de Saque Fácil, onde o dinheiro é retirado na loja no momento da contratação. Além disso, se você tiver uma conta Midway, em um banco digital ou uma conta poupança, também poderá contratar o Crédito Pessoal.',
      },
      {
        id: 6,
        title:
          'Posso indicar a conta de outra pessoa para receber o Empréstimo?',
        content:
          'Por questões de segurança, não é permitido indicar conta de terceiros. Para a efetivação da contratação, é necessário que a conta indicada seja do mesmo CPF do contratante.',
      },
      {
        id: 7,
        title:
          'Consigo fazer um Empréstimo mesmo tendo restrições no meu nome?',
        content:
          'Mesmo estando negativado ou com alguma restrição, você ainda pode receber uma oferta de Empréstimo, isso vai depender do tipo de restrição. Você pode fazer uma simulação agora mesmo. Lembre-se que restrições afetam o seu perfil, impactando nas condições da oferta, assim como taxa de juros, carência, prazo e limite.',
      },
      {
        id: 8,
        title:
          'Já tenho um contrato com a Midway e preciso pegar mais dinheiro, é possível?',
        content:
          'Se você já contratou um empréstimo e não utilizou todo o limite aprovado, pode contratar mais empréstimos até atingir o limite total. Caso já tenha utilizado todo o limite, será possível fazer um novo empréstimo conforme for pagando o contrato atual e o limite for sendo liberado.',
      },
      {
        id: 9,
        title: 'Quais são as tarifas aplicadas e taxa de juros cobrada?',
        content:
          'A Midway não cobra tarifas, apenas a taxa de juros e impostos. A taxa de juros para o Empréstimo Pessoal varia conforme o perfil do cliente, a partir de 3,49%. Faça a simulação pelo aplicativo e confira as condições.',
      },
      {
        id: 10,
        title:
          'É cobrada alguma taxa para a contratação do Empréstimo Pessoal?',
        content:
          'A Midway não cobra taxa de pagamento para a contratação do Empréstimo Pessoal. A cobrança de taxa de cadastro (TAC – Taxa de Abertura de Cadastro) é proibida pelo banco central. Geralmente, quando se cobra esse tipo de taxa se trata de um golpe, tome cuidado. ',
      },
      {
        id: 11,
        title:
          'A Midway solicita alguma antecipação para liberação do valor do Empréstimo?',
        content:
          'A Midway não solicita pagamento antecipado para a liberação do seu Empréstimo e a cobrança desse tipo de taxa é ilegal. Geralmente, quando se cobra esse tipo de taxa se trata de um golpe, tome cuidado.',
      },
      {
        id: 12,
        title:
          'O que acontece se eu informar dados bancários incorretos na contratação do Crédito Pessoal?',
        content:
          'Se os dados bancários informados estiverem incorretos, o valor não será creditado na conta e a operação será cancelada. Um colaborador Midway pode entrar em contato para ajudar a refazer a operação. Por isso, é fundamental que você informe os dados bancários corretos.',
      },
    ],
  },
  {
    id: 4,
    title: 'Pagamento',
    questions: [
      {
        id: 1,
        title: 'Como posso pagar as parcelas do meu Empréstimo?',
        content:
          'Você pode efetuar o pagamento do Empréstimo em qualquer loja física Riachuelo, utilizando carnê ou CPF, ou através de boleto bancário em qualquer instituição que aceite o recebimento de boletos (bancos, lotéricas, etc). Se a contratação foi realizada pelo app da conta Midway, o pagamento acontece via débito automático. Além disso, você pode gerar o boleto pelo site:  <a href="https://www.midway.com.br/consulta-boleto" target="_blank" style={{ color: "black", textDecoration: "underline", fontWeight: "bold",}}> https://www.midway.com.br/consulta-boleto </a>',
      },
      {
        id: 2,
        title: 'Quando recebo os boletos?',
        content:
          'O valor do Empréstimo Pessoal não é cobrado na sua fatura do cartão. Você pode gerar o boleto para pagamento da parcela a qualquer momento através do app, Central de Atendimento ou site. O pagamento pode ser realizado em qualquer instituição que aceite o recebimento de boletos (bancos, lotéricas, etc). Além disso, você pode efetuar o pagamento em qualquer loja física Riachuelo, informando o seu CPF.',
      },
      {
        id: 3,
        title:
          'Qual é a data de vencimento do Empréstimo? Consigo alterar essa data?',
        content:
          'Você pode escolher a carência do seu empréstimo, que varia de 30 a 90 dias, conforme sua necessidade no momento da contratação. A data de vencimento será definida com base na carência escolhida. Por exemplo: se você contratar um empréstimo no dia 1º de setembro com carência de 30 dias, o vencimento da primeira parcela será no 1º dia útil de outubro. As parcelas seguintes seguirão o mesmo dia de vencimento (1º dia útil de cada mês). Lembre-se: a carência e a data de vencimento são definidas na proposta e não podem ser modificadas após a contratação.',
      },
      {
        id: 4,
        title: 'Quanto tempo demora para pagar a 1ª parcela?',
        content:
          'O prazo para início do pagamento é definido no momento da contratação e depende da carência disponível para o seu perfil, podendo variar entre 30 a 90 dias, conforme o período de carência escolhido.',
      },
      {
        id: 5,
        title:
          'Onde acompanho as parcelas pagas e as parcelas em aberto do meu Empréstimo?',
        content:
          'Você pode acompanhar pelo app da conta Midway, Central de Atendimento ou em qualquer loja física Riachuelo. Para mais detalhes, acesse a seção de Empréstimos no app ou consulte o site <a href="https://www.midway.com.br/consulta-boleto" target="_blank" style={{ color: "black", textDecoration: "underline", fontWeight: "bold",}}> https://www.midway.com.br/consulta-boleto </a>',
      },
      {
        id: 6,
        title: 'Pagando antecipadamente, tenho desconto na parcela?',
        content:
          'Sim, ao antecipar o pagamento, você recebe desconto nos juros, proporcional aos dias restantes.',
      },
      {
        id: 7,
        title: 'Como posso antecipar uma das parcelas?',
        content:
          'Você pode antecipar ou até mesmo liquidar o contrato a qualquer momento. A antecipação pode ser feita em qualquer loja física Riachuelo, Central de Atendimento ou app Midway. No site, você consegue antecipar apenas a próxima parcela: <a ref="https://www.midway.com.br/consulta-boleto" target="_blank" style={{ color: "black", textDecoration: "underline", fontWeight: "bold",}}> https://www.midway.com.br/consulta-boleto </a>',
      },
      {
        id: 8,
        title: 'O que acontece se atrasar o pagamento da parcela?',
        content:
          'Se você não conseguir pagar a parcela até a data de vencimento, estará sujeito ao pagamento de 1% a.m de mora + 2% de multa + Juros remuneratório + IOF por atraso. Além disso, atrasar o pagamento da sua parcela impacta no seu perfil. Tente manter o seu pagamento sempre em dia.',
      },
    ],
  },
  {
    id: 5,
    title: 'Cancelamento',
    questions: [
      {
        id: 1,
        title:
          'Em quais situações é possível cancelar um Empréstimo contratado?',
        content:
          'Você tem o direito de cancelar o empréstimo em até 7 dias corridos após a contratação, independentemente do canal utilizado. Para isso, entre em contato pelo canal de adesão.',
      },
    ],
  },
  {
    id: 6,
    title: 'Canais de atendimento',
    questions: [
      {
        id: 1,
        title:
          'Quais são os serviços relacionados a Empréstimo que a Central de Atendimento realiza para o cliente?',
        content:
          'Na Central de Atendimento é possível realizar a simulação, contratação do Empréstimo, geração de boletos antecipados, em dia ou em atraso, renegociação para clientes inadimplentes, cancelamento e atendimento de dúvidas no geral.',
      },
      {
        id: 2,
        title:
          'Quais os serviços relacionados a Empréstimo que o cliente pode efetuar no app da conta Midway?',
        content:
          'No aplicativo é possível realizar a simulação, contratação do Empréstimo, verificar o limite disponível, consultar histórico de contratos antigos, consultar as parcelas em aberto e as pagas e também gerar boletos.',
      },
    ],
  },
];
