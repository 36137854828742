import React from 'react';
import copy from 'copy-to-clipboard';
import { Button } from '@midway/web-ui-component';
import { toast } from 'react-toastify';

const ButtonCopy = ({ toCopy, isPix, onClick = () => {} }) => {
  const handleCopy = async () => {
    copy(toCopy);
    toast.success('Código copiado com sucesso');
    onClick();
  };

  const buttonTitle = isPix ? 'Copiar código Pix' : 'Copiar código';

  return (
    <Button
      block
      state="black"
      title={buttonTitle}
      aria-label={buttonTitle}
      size="lg"
      onClick={() => handleCopy()}
    />
  );
};

export default ButtonCopy;
