import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;

  &.open {
    background-color: #a5df9f;
  }
  &.paid {
    background-color: #107906;
  }
  &.closed {
    background-color: #ffe7a8;
  }
  &.due-today {
    background-color: #e4602f;
  }
  &.late {
    background-color: #edabb6;
  }
  &.big {
    background-color: #12a656;
  }
`;

export const Text = styled.p`
  font-size: 0.75rem;
  font-weight: 400;

  svg {
    height: 0.625rem;
    width: 0.625rem;
    margin-right: 0.15rem;
  }

  .open > & {
    color: #053d00;
  }
  .paid > & {
    color: #fff;
  }
  .closed > & {
    color: #704810;
  }
  .due-today > & {
    color: #fff;
  }
  .late > & {
    color: #6c1322;
  }
  .big > & {
    color: #fff;
    font-size: 0.875rem;
  }
`;
