import React from 'react';
import * as S from './styles';

const SectionSatisfiedClients = () => {
  return (
    <S.BlackWrapper>
      <S.Container>
        <S.Title>Mais de 100 mil clientes satisfeitos</S.Title>
        <S.CardsContainer>
          <S.Card>
            <S.CardTitle>+ R$300 milhões</S.CardTitle>
            <S.Text>em descontos concedidos</S.Text>
          </S.Card>
          <S.Card>
            <S.CardTitle>+ 100.000 clientes</S.CardTitle>
            <S.Text>satisfeitos com nossos serviços</S.Text>
          </S.Card>
        </S.CardsContainer>
      </S.Container>
    </S.BlackWrapper>
  );
};

export default SectionSatisfiedClients;
