import produce from 'immer';

const INITIAL_STATE = {
  toogles: {
    isEnabledContaOnline: true,
    isEnabledContaOnlineServiceV2: false,
    isEnabledUpsell: false,
    isEnabledInvoiceInstallment: true,
    isEnabledCallToActionsNegotiation: false,
    isEnabledNewHome: true,
    isEnabledBenefitsTagsNegotiation: true,
    isEnabledMiajudaInvoiceInstallment: false,
    isEnabledSendToDynamics: true,
    isEnabledBannerFeiraoMiAjuda: false,
    isEnabledMiajudaBannerDesenrola: false,
    isEnabledDataValidation: false,
    isEnabledRedirectpf: false,
    isEnableNonNegotiable: false,
    isEnabledCardRedirectWP: false,
    isEnabledNegotiationSicc: false,
    isEnabledNegotiationTopaz: false,
    isEnabledNegotiationSipf: false,
    isEnabledNegotiationAgreements: false,
    isEnabledNegotiationTsys: false,
    isEnabledNewHomeMiAjuda: true,
    isEnabledRedirectSIPF: false,
    isEnabledUseCorrectText: false,
    isEnabledPreLoading: false,
    isEnabled412TOPAZ: false,
    isEnabledPaymentMethodMiAjuda: false,
    isDisablePaymentMethodPixSipf: false,
    isEnabledOnly2Proposals: false,
    isEnabledIofInfo: false,
    isEnabledPaymentMethodDebit: false,
    isDisablePaymentMethodPixSicc: false,
    isEnabledPaymentMethodTopaz: false,
    isEnabledMtopPaymentMethod: false,
    isEnabledBiCardsIntegration: false,
    isDisableHeaderButtonBilletInvoice: false,
    isEnablePaymentDate: false,
    isEnablePaymentDebtAccount: false,
    isEnableQRCodePayment: false,
    isEnableInsider: false,
    isEnableTopazDebit: false,
    isEnableTopazAcquittance: false,
    isEnableTopazAgreements: false,
    isEnableMonestLink: false,
    isEnableEqualWeb: false,
    web_enabled_qrcode_payment: false,
    isEnabledPaymentChannel: false,
  },
};

export default function featuretoggle(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@featuretoggle/SAVE_FEATURE_TOGGLES': {
        draft.toogles = action.payload;
        break;
      }
      default:
    }
  });
}
