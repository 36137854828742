import React from 'react';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { first, kebabCase, last, lowerCase } from 'lodash';
import { Button } from '@midway/web-ui-component';
import { useNegotiation } from '~/context/negotiationV2';
import { gtagSelectContent } from '~/utils/TaggingGA4';

const GTAG_FLOW = 'miajuda';

function ButtonCopy({ toCopy, title }) {
  const {
    historyPages,
    system,
    negotiationData,
    typeNegotiationRegistered,
    paymentMethodSelected,
    type,
    duplicateData,
  } = useNegotiation();

  const isDebit =
    typeNegotiationRegistered === 'debit' ||
    typeNegotiationRegistered === 'acquittance';

  const ga4Params = () => {
    if (last(historyPages) === 'duplicate') {
      const isTopaz = system === 'TOPAZ';
      const duplicate =
        (!isTopaz &&
          first(
            duplicateData?.filter(
              d => d.contract === negotiationData?.selectedContract?.contract
            )
          )) ||
        first(duplicateData);
      return {
        sistema: lowerCase(system),
        proposta: negotiationData?.gaParamProposta,
        contrato: duplicate?.contract,
      };
    }

    const negotiable = negotiationData?.eligibility[typeNegotiationRegistered];
    const installment = negotiable?.simulation?.selectedInstallmentData;

    if (isDebit) {
      return {
        valor: negotiationData?.registered?.amount,
        tipo_proposta: typeNegotiationRegistered,
        tipo_cartao: type,
        forma_pagamento: lowerCase(paymentMethodSelected),
        sistema: lowerCase(system),
        proposta: negotiationData?.gaParamProposta,
        contrato: negotiationData?.selectedContract?.contract,
      };
    }
    return {
      valor_das_parcelas: installment?.installmentAmount,
      numero_de_parcelas: installment?.installmentQuantity,
      valor_de_entrada: negotiationData?.registered?.amount,
      tipo_proposta: typeNegotiationRegistered,
      tipo_cartao: type,
      forma_pagamento: lowerCase(paymentMethodSelected),
      sistema: lowerCase(system),
      proposta: negotiationData?.gaParamProposta,
      contrato: negotiationData?.selectedContract?.contract,
    };
  };
  const contentPage = () => {
    if (last(historyPages) === 'registered') return 'tela-de-comprovante';
    if (last(historyPages) === 'duplicate')
      return 'pegue-aqui-a-segunda-via-do-boleto-do-seu-acordo';
  };

  const handleCopy = () => {
    copy(toCopy);
    toast.success('Código copiado com sucesso');
    gtagSelectContent(contentPage(), GTAG_FLOW, kebabCase(title), ga4Params());
  };

  return <Button leftIcon="copy" title={title} onClick={() => handleCopy()} />;
}

export default ButtonCopy;
