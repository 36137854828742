import OAuthColletion from './OAuthCollection';
import instance from './NegotiationCampaignInstance';

const isPaymentChannel = window.location.pathname.includes(
  '/pagamentos-riachuelo'
);
const dynamicChannel = () => (isPaymentChannel ? 'PORTALPGTO' : 'mi_ajuda');

const dynamicChannelID = () => (isPaymentChannel ? 800 : 498);

let headers = {
  channel: 'WEB',
  'x-correlation-id': dynamicChannel(),
  'channel-id': dynamicChannelID(),
};

const getContracts = async (document, birthDate, captcha) => {
  const uri = 'v2/unified/contracts';
  const OAuthToken = await OAuthColletion.getToken();
  headers['x-app-token'] = OAuthToken?.IdToken;

  return instance
    .get(uri, {
      headers: {
        channel: 'WEB',
        'document-number': document,
        'birth-date': birthDate,
        captcha,
        'x-app-token': OAuthToken?.IdToken,
        'x-correlation-id': headers['x-correlation-id'],
      },
    })
    .then(response => response?.data)
    .catch(err => err.response.data);
};

const getBalance = async (document, contract, system) => {
  const uri = `/v2/${system}/balance`;
  headers['document-number'] = document;
  headers.contract = contract;

  return instance
    .get(uri, { headers })
    .then(response => response?.data)
    .catch(err => err.response.data);
};

const getEligibility = async (date, document, contract, type) => {
  if (!headers['x-app-token']) {
    const OAuthToken = await OAuthColletion.getToken();
    headers['x-app-token'] = OAuthToken?.IdToken;
  }

  const uri = `/v2/${type}/eligibility/payment-date/${date}`;
  headers['document-number'] = document;
  headers.contract = contract;
  return instance
    .get(uri, { headers })
    .then(response => response?.data)
    .catch(err => err?.response?.data);
};
const postSimulation = async (body, system) => {
  const uri = `/v3/${system}/simulation`;
  const token = headers['x-app-token'];

  return instance
    .post(uri, body, {
      headers: {
        'x-app-token': token,
        'x-correlation-id': headers['x-correlation-id'],
      },
    })
    .then(response => response?.data)
    .catch(err => err.response.data);
};

const getPaymentMethod = async ({ system, token }) => {
  const uri = `/v2/${system}/payment-method`;
  return instance
    .get(uri, {
      headers: {
        token,
        'x-app-token': headers['x-app-token'],
        'x-correlation-id': headers['x-correlation-id'],
      },
    })
    .then(response => response?.data)
    .catch(err => err.response.data);
};

const postRegister = async (body, system) => {
  const uri = `/v2/${system}/register`;
  const token = headers['x-app-token'];

  return instance
    .post(uri, body, {
      headers: {
        'x-app-token': token,
        'x-correlation-id': headers['x-correlation-id'],
      },
    })
    .then(response => response?.data)
    .catch(err => err.response.data);
};

const getUnifiedBillet = async ({ billetId, document, contract }) => {
  const token = headers['x-app-token'];

  const uri = 'v2/unified/billet';
  const config = document
    ? {
        contract,
        'document-number': document,
      }
    : { 'billet-id': billetId };
  return instance
    .get(uri, {
      headers: {
        ...config,
        'x-correlation-id': headers['x-correlation-id'],
        'x-app-token': token,
      },
    })
    .then(response => {
      return response?.data;
    })
    .catch(err => err.response.data);
};

const getMDM = async document => {
  const OAuthToken = await OAuthColletion.getToken();

  const uri = `mdm/v1/customer/${document}`;
  return instance
    .get(uri, {
      headers: {
        'x-correlation-id': headers['x-correlation-id'],
        'x-app-token': OAuthToken?.IdToken,
      },
    })
    .then(response => {
      return response?.data?.items[0];
    })
    .catch(err => err);
};

export default {
  getContracts,
  getBalance,
  getEligibility,
  postSimulation,
  getPaymentMethod,
  postRegister,
  getUnifiedBillet,
  dynamicChannel,
  getMDM,
};
