import React, { useEffect, useState } from 'react';
import { Switch, Route as Router, Redirect } from 'react-router-dom';
import { Main, Body, Drawer } from '@midway/web-ui-component';
import { useDispatch, useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { hideDrawer } from '~/store/modules/tools/actions';
import { showSmartBannerAction } from '~/store/modules/smartbanner/actions';
import { menuItems } from '~/constants';
import useUrlParams from '~/hooks/useUrlParams';
import { NegotiationProvider } from '~/context/negotiationV2';
import LoanAdvantages from '~/pages/LoanAdvantages';
import CardBenefits from '~/pages/CardBenefits';
import LendingFgts from '~/pages/LendingFgts';
import Route from './Route';
import ContentDrawer from '../components/ContentDrawer';
import Header from '../components/Header';
import Accessibility from '../pages/Accessibility';
import GuaranteedLimitLandingPage from '../pages/GuaranteedLimitLandingPage';
import AssistanceOdontological24h from '../pages/AssistanceOdontological24h';
import MasterCard from '../pages/MasterCard';
import BePart from '../pages/BePart';
import Cards from '../pages/Cards';
import CardCredit from '../pages/CardCredit';
import CardPL from '../pages/CardPL';
import LimitIncreaseCampaign from '../pages/LimitIncreaseCampaign';
import CardParcelCredit from '../pages/CardParcelCredit';
import CardParcelPL from '../pages/CardParcelPL';
import EasyWithdrawal from '../pages/EasyWithdrawal';
import FinancialProducts from '../pages/FinancialProducts';
import Home from '../pages/Home';
import HomeOld from '../pages/HomeDeprecated/index-old';
import MoreProtection from '../pages/MoreProtection';
import Insurance from '../pages/Insurance';
import Midway from '../pages/Midway';
import Lending from '../pages/Lending';
import PersonalLoan from '../pages/PersonalLoan';
import Startup from '../pages/Startup';
import Governance from '../pages/Governance';
import Complaint from '../pages/Complaint';
import FinancialStatements from '../pages/FinancialStatements';
import AcceptTerm from '../pages/AcceptTerm';
import Risk from '../pages/Risk';
import OmbudsmanReports from '../pages/OmbudsmanReports';
import MonthlyWinners from '../pages/MonthlyWinners';
import DiscontinuedProducts from '../pages/DiscontinuedProducts';
import BaseContractPage from '../pages/Contract';
import NewNegotiationCampaign from '../pages/NewNegotiationCampaign';
import RedirectWhatsApp from '../pages/NewNegotiationCampaign/RedirectWhatsApp';
import DigitalAccount from '../pages/DigitalAccount';
import ServicesAndFaresTable from '../pages/ServicesAndFaresTable';
import RchloTarifs from '../pages/TarifsRchloCard';
import VisaTarifs from '../pages/TarifsVisaCard';
import FaqLoan from '../pages/FaqLoan';
import Error404 from '../pages/Error404';
import Indications from '../pages/Indications';
import BilletInvoiceOrLendingV2 from '../pages/BilletInvoiceOrLendingV2';
import AccountHolderLoan from '../pages/accountHolderLoan';
import AccountHolderLoanStep1 from '../pages/accountHolderLoan/steps/step1';
import NotCartoonistLoan from '../pages/NotCartoonistLoan';
import NewNCLCardRCHLO from '../pages/NotCartoonistLoan/NewNCLCardRCHLO';
import Apps from '../pages/App';
import SimulateLoan from '../pages/SimulateLoan';
import ContractLoan from '../pages/SimulateLoan/ContractLoan';
import LoanBenefits from '../pages/LoanBenefits';
import NewLending from '../pages/NewLending';
import SmartBanner from '../components/SmartBanner';
import FGTSAnniversaryAdvance from '../pages/fgtsAnniversaryAdvance';
import RedirectToStores from '../pages/RedirectToStores';
import SearchStores from '../pages/SearchStores';
import Documents from '../pages/Documents';
import { AttendancePortal } from '~/pages/AttendancePortal';
import LoanFacilitated from '../pages/loanFacilitated';
import CellPhoneInsurace from '../pages/cellPhoneInsurance';
import FinancialProtectionInsurance from '../pages/FinancialProtectionInsurance';
import LendingFinancialEducation from '~/pages/LendingFinancialEducation';
import SafeBuyAwardWinning from '../pages/SafeBuyAwardWinning';
import ProtectedPurchase from '../pages/ProtectedPurchase';
import PaymentChannel from '~/pages/PaymentChannel';
import TestCms from '../pages/TestCms';
import AccountPaidInsurance from '../pages/AccountPaidInsurance';
import ProtectedHomeInsurance from '../pages/ProtectedHomeInsurance';
import PersonalAccidentInsurance from '../pages/PersonalAccidentInsurance';

export default function Routes() {
  const dispatch = useDispatch();

  const drawer = useSelector(state => state.tools.drawer);
  const { showSmartBanner } = useSelector(state => state.smartbanner);
  const { minimal } = useUrlParams();
  const isTerm = window.location.pathname === '/termo-de-aceite';
  const isApps = window.location.pathname === '/apps';
  const isHome = window.location.pathname === '/';
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 712);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [webView, setWebView] = useState(false);
  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 712);
  const { toogles } = useSelector(state => state.featuretoggle);
  const newHome = toogles.isEnabledNewHome;
  const webViewHeight = -1;

  useEffect(() => {
    if (isMobile) {
      webViewVerify();
    }

    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, [isMobile]);

  const handlerClick = () => {
    dispatch(hideDrawer());
  };

  const webViewVerify = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const webViewParam = !!queryParams.get('webView');

    setWebView(webViewParam);
  };

  const closeSmartBanner = () => {
    const now = new Date();
    const smartBanner24h = new Date();

    smartBanner24h.setDate(now.getDate() + 1);
    window.localStorage.setItem('smartBanner24h', smartBanner24h);
    dispatch(showSmartBannerAction(false));
    setHeaderHeight(75);

    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:smartbanner',
      eventAction: 'clique:botao',
      eventLabel: `botao:fechar`,
    });
  };

  const showHeader = () => {
    const pathUrl = window.location.pathname;

    if (
      minimal ||
      minimal === 'true' ||
      pathUrl === '/meunovomastercard' ||
      pathUrl.match(/\/pagamentos-riachuelo.*/g)
    )
      return null;
    if (!isTerm)
      return (
        <>
          {isMobile && showSmartBanner && !isApps && !webView && (
            <SmartBanner closeButton={closeSmartBanner} />
          )}
          {!webView && <Header menuItems={menuItems} />}
        </>
      );
    return null;
  };

  const isProd = false;

  const simulation = prod => {
    return prod ? <Lending /> : <NewLending />;
  };

  return (
    <Main
      header={showHeader()}
      hasDoubleHeader={isHome && !isMobile}
      headerHeightForLazyLoading={
        webView === true ? webViewHeight : headerHeight
      }
    >
      <Drawer
        id="drawer-test"
        toDrawerRight
        showDrawer={drawer}
        checkClosed={handlerClick}
      >
        <ContentDrawer closeDrawer={handlerClick} />
      </Drawer>
      <Body hasDoubleHeader={isHome}>
        <Switch>
          <Route
            exact
            path="/"
            component={() => (newHome ? <Home /> : <HomeOld />)}
          />
          <Route
            exact
            path="/campanha-quitacao"
            component={() => (
              <DocumentTitle title="Negociação de Dívida">
                <NegotiationProvider>
                  <NewNegotiationCampaign />
                </NegotiationProvider>
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/campanha-quitacao/whatsapp"
            component={() => (
              <DocumentTitle title="WhatsApp">
                <RedirectWhatsApp />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/seguros"
            component={() => (
              <DocumentTitle title="Seguros">
                <Insurance />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/mais-protecao"
            component={() => (
              <DocumentTitle title="Mais Proteção">
                <MoreProtection />
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/seguro-compra-protegida"
            component={() => (
              <DocumentTitle title="Seguro Compra Protegida">
                <ProtectedPurchase />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/plano-odontologico"
            component={() => (
              <DocumentTitle title="Plano Odontológico">
                <AssistanceOdontological24h />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/emprestimos"
            component={() => (
              <DocumentTitle title="Empréstimos">
                {simulation(isProd)}
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/antecipacao-saque-fgts"
            component={() => (
              <DocumentTitle title="Saque FGTS">
                <FGTSAnniversaryAdvance />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/campanhaepfgts"
            component={() => (
              <DocumentTitle title="Campanha Empréstimo FGTS">
                <LendingFgts />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/saque-facil"
            component={() => (
              <DocumentTitle title="Saque Fácil">
                <EasyWithdrawal />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/emprestimo-pessoal"
            component={() => (
              <DocumentTitle title="Crédito Pessoal">
                <PersonalLoan />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/faca-parte"
            isWhite
            component={() => (
              <DocumentTitle title="Faça Parte">
                <BePart />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/faca-parte/startup"
            component={() => (
              <DocumentTitle title="Startup">
                <Startup />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/atendimento"
            component={() => (
              <DocumentTitle title="Portal de Atendimento">
                <AttendancePortal />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/apps"
            component={() => (
              <DocumentTitle title="Apps">
                <Apps />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/cartoes"
            component={() => (
              <DocumentTitle title="Cartões">
                <Cards />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/dobro-de-limite-no-cartao-riachuelo"
            component={() => (
              <DocumentTitle title="Dobro de Limite">
                <LimitIncreaseCampaign />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/cartao-rchlo"
            component={() => (
              <DocumentTitle title="Cartão Riachuelo">
                <>
                  {' '}
                  <CardPL />
                </>
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/cartao-de-credito"
            component={() => (
              <DocumentTitle title="Cartão Riachuelo Visa e MasterCard">
                <CardCredit />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/cartao-rchlo/CardParcelPL"
            component={() => (
              <DocumentTitle title="Parcelamento de Fatura">
                <CardParcelPL />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/cartao-rchlo/CardParcelCredit"
            component={() => (
              <DocumentTitle title="Parcelamento de Fatura">
                <CardParcelCredit />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/consulta-boleto"
            component={() => (
              <DocumentTitle title="Cartão Riachuelo Conta Online">
                <BilletInvoiceOrLendingV2 />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/demonstracoes-financeiras"
            component={() => (
              <DocumentTitle title="Demonstrações Financeiras">
                <FinancialStatements />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/produtos-financeiros"
            component={() => (
              <DocumentTitle title="Produtos Financeiros">
                <FinancialProducts />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/midway"
            component={() => (
              <DocumentTitle title="Midway">
                <Midway />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/relatorio-ouvidoria"
            component={() => (
              <DocumentTitle title="Relatórios Ouvidoria">
                <OmbudsmanReports />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/governanca-e-etica"
            component={() => (
              <DocumentTitle title="Governança e Ética">
                <Governance />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/envie-sua-denuncia"
            component={() => (
              <DocumentTitle title="Envie sua Denúncia">
                <Complaint />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/gerenciamento-de-risco"
            component={() => (
              <DocumentTitle title="Gerenciamento de riscos">
                <Risk />
              </DocumentTitle>
            )}
          />
          <Router
            exact
            path="/termo-de-aceite"
            component={() => (
              <DocumentTitle title="Termo">
                <AcceptTerm />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/sorteados-do-mes/"
            component={() => (
              <DocumentTitle title="Sorteados do Mês">
                <MonthlyWinners />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/contratos-de-produtos-descontinuados"
            component={() => (
              <DocumentTitle title="Contratos de Produtos">
                <DiscontinuedProducts />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/beneficios"
            component={() => (
              <DocumentTitle title="Benefícios">
                <CardBenefits />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/acessibilidade"
            component={() => (
              <DocumentTitle title="Acessibilidade Midway">
                <>
                  {' '}
                  <Accessibility />
                </>
              </DocumentTitle>
            )}
          />
          <Redirect
            exact
            from="/contrato/Midway_Contrato_PL"
            to="/contrato/Midway_Contrato_PL.pdf"
          />
          <Redirect
            exact
            from="/contrato/Midway_Contrato_Bandeira"
            to="/contrato/Midway_Contrato_Bandeira.pdf"
          />
          <Route
            exact
            path="/contrato/:contractName"
            component={() => (
              <DocumentTitle title="Contrato">
                <BaseContractPage />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/tarifas-rchlo"
            component={() => (
              <DocumentTitle title="Taxas e Tarifas - Cartão Riachuelo">
                <RchloTarifs />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/tarifas-cartoes"
            component={() => (
              <DocumentTitle title="Taxas e Tarifas - Visa e MasterCard">
                <VisaTarifs />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/conta-digital"
            component={() => (
              <DocumentTitle title="Conta Digital">
                <DigitalAccount />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/tabela-de-tarifas-conta"
            component={() => (
              <DocumentTitle title="Conta Digital - Tabela de serviços e tarifas">
                <ServicesAndFaresTable />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/conta-digital/:contractName"
            component={() => (
              <DocumentTitle title="Regulamento de Conta Premiada">
                <BaseContractPage />
              </DocumentTitle>
            )}
          />

          {/* Loan */}
          <Route
            exact
            path="/emprestimos/correntista"
            component={() => (
              <DocumentTitle title="Correntista">
                <AccountHolderLoan />
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/emprestimos/correntista-steps"
            component={() => (
              <DocumentTitle title="Correntista-steps">
                <AccountHolderLoanStep1 />
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/emprestimos/nao-correntista"
            component={() => (
              <DocumentTitle title="nao-correntista">
                <NotCartoonistLoan />
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/emprestimos/nao-correntista/cartao-rchlo"
            component={() => (
              <DocumentTitle title="nao-correntista-cartao-rchlo">
                <NewNCLCardRCHLO />
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/emprestimos/simulacao"
            component={() => (
              <DocumentTitle title="Simulação Empréstimos">
                <SimulateLoan />
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/emprestimos/contrate"
            component={() => (
              <DocumentTitle title="Quer contratar?">
                <ContractLoan />
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/convide-seu-amigo/:base64"
            component={() => (
              <DocumentTitle title="Programa de Indicação">
                <Indications />
              </DocumentTitle>
            )}
          />

          <Route
            exact
            path="/emprestimos/faq"
            component={() => (
              <DocumentTitle title="FAQ">
                <FaqLoan />
              </DocumentTitle>
            )}
          />
          <Redirect exact from="/a-midway" to="/midway" />
          <Redirect exact from="/cartoes-riachuelo" to="/cartoes" />
          <Redirect
            exact
            from="/seguros-e-servicos/para-cuidar-mais-da-sua-vida-financeira/emprestimo-pessoal"
            to="/emprestimos"
          />
          <Redirect
            exact
            from="/seguros-e-servicos"
            to="/produtos-financeiros"
          />
          <Redirect exact from="/promocoes" to="/parceiros-cartao-rchlo" />
          <Redirect
            exact
            from="/politica-de-privacidade"
            to="/contrato/POLITICA_DE_SEGURANCA_DA_INFORMACAO"
          />
          <Route
            exact
            path="/beneficios-seguro-prestamista"
            component={() => (
              <DocumentTitle title="Empréstimo Pessoal">
                <LoanBenefits />
              </DocumentTitle>
            )}
          />
          <Redirect
            exact
            from="/beneficios-seguro"
            to="/beneficios-seguro-prestamista?webView=true"
          />
          <Route
            exact
            path="/vantagens-seguro-prestamista"
            component={() => (
              <DocumentTitle title="Empréstimo Pessoal">
                <LoanAdvantages />
              </DocumentTitle>
            )}
          />
          <Redirect
            exact
            from="/vantagens-seguro"
            to="/vantagens-seguro-prestamista?webView=true"
          />
          <Route exact path="/app/*" component={() => <RedirectToStores />} />
          <Route
            exact
            path="/buscar-lojas"
            component={() => (
              <DocumentTitle title="Buscar Lojas">
                <SearchStores />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/documentos"
            component={() => (
              <DocumentTitle title="Documentos">
                <Documents />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/meunovomastercard"
            component={() => (
              <DocumentTitle title="Meu novo MasterCard">
                <MasterCard />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/emprestimo-facilitado"
            component={() => (
              <DocumentTitle title="Empréstimo Facilitado">
                <LoanFacilitated />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/lar-protegido"
            component={() => (
              <DocumentTitle title="Seguro Lar Protegido">
                <ProtectedHomeInsurance />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/seguro-celular-e-portateis"
            component={() => (
              <DocumentTitle title="Seguro Celular e Portáteis">
                <CellPhoneInsurace />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/protecao-financeira"
            component={() => (
              <DocumentTitle title="Seguro Proteção Financeira">
                <FinancialProtectionInsurance />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/seguro-compra-premiada"
            component={() => (
              <DocumentTitle title="Seguro Compra Premiada">
                <SafeBuyAwardWinning />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/conta-paga"
            component={() => (
              <DocumentTitle title="Seguro Conta Paga">
                <AccountPaidInsurance />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/seguro-acidentes-pessoais"
            component={() => (
              <DocumentTitle title="Seguro Acidentes Pessoais">
                <PersonalAccidentInsurance />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/limite-garantido"
            component={() => (
              <DocumentTitle title="Limite Garantido">
                <GuaranteedLimitLandingPage />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/educacao-financeira"
            component={() => (
              <DocumentTitle title="Campanha Empréstimo FGTS">
                <LendingFinancialEducation />
              </DocumentTitle>
            )}
          />
          <Route
            exact
            path="/test-cms"
            component={() => (
              <DocumentTitle title="Test CMS">
                <TestCms />
              </DocumentTitle>
            )}
          />
          {toogles.isEnabledPaymentChannel && (
            <Route
              exact
              path="/pagamentos-riachuelo"
              component={() => (
                <DocumentTitle title="Pagamentos Riachuelo">
                  <PaymentChannel />
                </DocumentTitle>
              )}
            />
          )}
          <Route component={() => <Error404 />} />
        </Switch>
      </Body>
    </Main>
  );
}
