import { useQuery } from '@tanstack/react-query';
import PaymentChannelApi from '~/services/PaymentChannelApi';
import { usePaymentChannelContext } from '~/context/PaymentChannel';

const useCardInvoices = ({ document }) => {
  const { updateData } = usePaymentChannelContext();

  const queryFn = async ({ document }) => {
    const response = await PaymentChannelApi.getCardInvoices(document);

    if (!response || response.error) {
      throw new Error(response.message ?? response.error);
    }

    const totalOverdueInvoice = response?.invoiceResponseList?.filter(
      item => !item.payment
    )?.length;

    updateData('cardInvoices', response);
    updateData('totalOverdueInvoice', totalOverdueInvoice);

    return response;
  };

  return useQuery([document], {
    queryFn: () => queryFn({ document }),
    enabled: Boolean(document),
    refetchOnWindowFocus: false,
  });
};

export default useCardInvoices;
