import styled from 'styled-components';
import { ReactComponent as VectorRchlo } from '~/assets/img/vetor-rchlo.svg';

import * as Common from '../../../styles';

export const Wrapper = styled(Common.Wrapper)`
  background-color: #f5f5f5;
  padding: 0 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const Card = styled.div`
  display: flex;
  align-items: start;
  margin-top: 1rem;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #212121;
  margin-right: 6px;

  svg {
    font-size: 1em;
  }
`;

export const IconRchlo = styled(VectorRchlo)`
  fill: #fff;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 4px;
`;

export const Description = styled.span`
  font-size: 14px;
  color: #333;
`;

export const Link = styled.a`
  color: #007bff;
  text-decoration: underline;
`;

export const Phone = styled.a`
  color: #007bff;
  text-decoration: underline;
  font-size: 14px;
`;
