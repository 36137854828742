import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { keyboard } from '~/constants';
import history from '~/services/history';

import * as S from './styles';

const AccessibleLink = ({
  route,
  text,
  styleDefault,
  hasUnderline,
  isFooter,
  customAction,
  blank,
  ...rest
}) => {
  const { toggleContractRoutes } = useSelector(state => state.tools);

  const isAbsoluteRoute = route?.includes('http');
  const hideContratRoute =
    route?.includes('/contrato/') && !toggleContractRoutes;

  const clickAction = () => {
    if (customAction) customAction();
    else history.push(route);
  };

  return (
    <S.Link
      data-testid="accessible-link"
      isFooter={isFooter}
      href={isAbsoluteRoute ? route : 'javascript:'}
      target={blank && '_blank'}
      className={`${styleDefault ? 'styleDefault' : ''}
          ${hasUnderline ? 'underline' : ''}
          ${hideContratRoute ? '--toggle-contract-routes' : ''}
        `}
      onClick={!isAbsoluteRoute && clickAction}
      onKeyPress={
        !isAbsoluteRoute &&
        (event => {
          event.preventDefault();
          const { charCode } = event;
          if (charCode === keyboard.ENTER || charCode === keyboard.SPACE) {
            clickAction();
          }
        })
      }
      {...rest}
    >
      {text}
    </S.Link>
  );
};

AccessibleLink.propTypes = {
  route: PropTypes.string,
  text: PropTypes.string,
  styleDefault: PropTypes.bool,
  hasUnderline: PropTypes.bool,
  isFooter: PropTypes.bool,
  blank: PropTypes.bool,
  customAction: PropTypes.func,
};

AccessibleLink.defaultProps = {
  route: '',
  text: 'saiba mais',
  styleDefault: true,
  hasUnderline: false,
  isFooter: false,
  blank: false,
  customAction: false,
};

export default AccessibleLink;
