import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 0.625rem;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  position: absolute;
  top: 0.625rem;
  left: 0;
  color: #9e9e9e;
  font-size: 1rem;
  transition: 0.2s ease all;
  pointer-events: none;
  font-family: inherit;
  line-height: 1;

  &.shrink {
    top: -0.625rem;
    font-size: 0.75rem;
    color: #9e9e9e;
  }

  .hasError & {
    color: #c3223d;
  }
`;

export const InputField = styled.input`
  width: 100%;
  padding: 0.625rem 0;
  font-size: 1rem;
  line-height: 1;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  color: #221f20;
  font-family: inherit;
  box-sizing: content-box;
  height: 1rem;

  &:focus {
    border-color: #221f20;
  }

  &:focus ~ ${Label} {
    top: -0.625rem;
    font-size: 0.75rem;
    color: #221f20;
  }

  .hasError & {
    border-color: #c3223d;
  }
`;

export const Description = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 4px;
  color: #9e9e9e;
  font-family: inherit;

  .hasError & {
    color: #c3223d;
  }
`;
