import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './config/ReactotronConfig';

import Routes from './routes';
import history from './services/history';
import Fallback from './components/Fallback';
import EqualWeb from 'src/hooks/useEqualWeb.js';
import GlobalStyle from './styles/global';
import FirebaseProvider from './providers/Firebase';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from './store';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
  },
});

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Fallback />}>
        <FirebaseProvider>
          <QueryClientProvider client={queryClient}>
            <Router history={history}>
              <EqualWeb />
              <Routes />
              <GlobalStyle />
              <ToastContainer
                position="bottom-right"
                pauseOnFocusLoss={false}
                theme="dark"
              />
            </Router>
          </QueryClientProvider>
        </FirebaseProvider>
      </Suspense>
    </Provider>
  );
};

export default App;
