import React, { useEffect } from 'react';
import { Button } from '@midway/web-ui-component';
import { useSelector } from 'react-redux';
import { lowerCase } from 'lodash';
import { useNegotiation } from '~/context/negotiationV2';
import useIsMobile from '~/hooks/useIsMobile';
import useInsiderQueue from '~/hooks/useInsiderQueue';
import format from '~/services/format';
import { tagMiAjudaButton, tagMiAjudaView } from '~/services/tagging';
import useCorrectText from '~/hooks/useCorrectText';
import { gtagViewContent, gtagSelectContent } from '~/utils/TaggingGA4';
import Proposals from './Proposals';
import ContainerFadeIn from '../../ContainerFadeIn';
import {
  ContainerDetails,
  TitleElegibility,
  TitleDetail,
  ContainerRedirect,
} from './styles';

const GTAG_CONTENT_PAGE = 'visualizacao-de-proposta';
const GTAG_FLOW = 'miajuda';

const Elegibility = () => {
  const { clientData, negotiationData, type, system } = useNegotiation();
  const { toogles } = useSelector(state => state.featuretoggle);
  const isMobile = useIsMobile();
  const { delayIn, overdueTotal, delayedDays } = useCorrectText();

  const { insiderQueueCustom } = useInsiderQueue();

  useEffect(() => {
    if (system === 'SIPF' && toogles.isEnabledRedirectSIPF)
      tagMiAjudaView('redirect-sipf');

    gtagViewContent(GTAG_CONTENT_PAGE, GTAG_FLOW, {
      sistema: lowerCase(system),
      proposta: negotiationData?.gaParamProposta,
      contrato: negotiationData?.selectedContract?.contract,
    });

    insiderQueueCustom('carregou_proposta', {
      sistema: lowerCase(system),
      contrato: negotiationData?.selectedContract?.contract,
      dias_atraso: negotiationData?.overDueDays,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system, toogles.isEnabledRedirectSIPF]);

  const balanceFuture = negotiationData?.balance?.overcome?.main;
  const maxInstallmentInvoicement =
    negotiationData?.eligibility?.invoicement?.maxInstallmentNumber;
  const onlyInvoicement = negotiationData?.onlyInvoicement;

  return (
    <ContainerFadeIn>
      {onlyInvoicement ? (
        <TitleElegibility>
          <strong>{clientData?.name}</strong>, parcele a sua fatura em até{' '}
          <span>{maxInstallmentInvoicement}x</span> e ganhe mais prazo nas suas
          contas.
        </TitleElegibility>
      ) : (
        <TitleElegibility>
          <strong>{clientData?.name}</strong>, aproveite as{' '}
          <span>melhores condições</span> para negociar de maneira rápida e
          segura.
        </TitleElegibility>
      )}
      <TitleDetail>
        {!onlyInvoicement && delayIn()}
        <span>{negotiationData?.productName}</span>:
      </TitleDetail>
      <ContainerDetails isMobile={isMobile}>
        <div>
          <span className="bold">
            {onlyInvoicement ? 'Total a pagar' : overdueTotal()}
          </span>
          <span>
            {format.currency(negotiationData?.balance?.balance?.total)}
          </span>
        </div>
        {balanceFuture > 0 && (
          <div>
            <span className="bold">
              Próximas {type === 'cards' ? 'faturas' : 'parcelas'}{' '}
            </span>
            <span>{format.currency(balanceFuture)}</span>
          </div>
        )}
        <div>
          <span className="bold">
            {onlyInvoicement ? 'Vencimento' : delayedDays()}
          </span>
          <span>{negotiationData?.overdueDaysText}</span>
        </div>
      </ContainerDetails>
      <Proposals />
      {system === 'SIPF' && toogles.isEnabledRedirectSIPF && (
        <>
          <ContainerRedirect>
            <span className="bold">Outras propostas?</span>
            <span>
              Entre em contato com nossa central de atendimento via WhatsApp
            </span>
            <Button
              title="Acessar"
              type="submit"
              onClick={() => {
                gtagSelectContent(
                  GTAG_CONTENT_PAGE,
                  GTAG_FLOW,
                  'acessar-redirect-sipf',
                  {
                    contrato: negotiationData?.selectedContract?.contract,
                  }
                );
                tagMiAjudaButton('acessar-redirect-sipf');
                window.open(
                  'https://www.midway.com.br/campanha-quitacao/whatsapp?utm_source=MiAjuda&utm_medium=whatsapp&utm_campaign=quitacao-de-divida&utm_term=EG1&utm_content=engajaD0D4',
                  '_blank'
                );
              }}
            />
          </ContainerRedirect>
        </>
      )}
    </ContainerFadeIn>
  );
};

export default Elegibility;
