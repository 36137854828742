import React from 'react';
import { getIsFullPayment } from '~/pages/PaymentChannel/helpers';
import { Container } from './styles';
import { usePaymentChannelContext } from '~/context/PaymentChannel';

function Important() {
  const { paymentChannelData } = usePaymentChannelContext();
  const { typeSelected, system, methodSelected } = paymentChannelData;

  const isFullPayment = getIsFullPayment(paymentChannelData);

  return methodSelected !== 'PIX' ? (
    <Container>
      <strong>Importante:</strong>
      {system === 'TOPAZ' ? (
        <>
          <span>
            1. O prazo para compensação de boletos é de até três dias úteis.
          </span>
          <span>
            2. Caso não seja possível pagar o boleto até o vencimento, ligue
            para 11 3004-5417 ou 0800 727 4417 ou chat pelo WhatsApp 11
            3003-0950.
          </span>

          {typeSelected === 'agreement' && (
            <span>
              3. Os boletos das demais parcelas, serão enviados por e-mail até o
              vencimento.
            </span>
          )}
        </>
      ) : (
        <>
          <span>
            1. Pagamentos por boleto podem levar até 3 dias úteis para serem
            processados
          </span>
          {!isFullPayment && (
            <span>
              {system !== 'SIPF'
                ? '2. As demais parcelas serão cobradas diretamente na fatura do seu cartão nos dias de vencimento'
                : '2. Você receberá o boleto para pagamento das demais parcelas diretamente em seu e-mail'}
            </span>
          )}

          {(isFullPayment ||
            (system === 'SIPF' && typeSelected === 'agreement')) && (
            <span>
              {isFullPayment ? '2.' : '4.'} Caso você não receba o boleto por
              SMS e precise da 2ª via, ligue para 3004 5417 ou 0800 727 4417.
            </span>
          )}
        </>
      )}

      {(system === 'SIPF' || system === 'SICC') &&
        typeSelected === 'agreement' && (
          <span>
            3. Após a contratação, só será possível uma nova negociação, após o
            vencimento do seu boleto da entrada
          </span>
        )}

      {system === 'AGREEMENTS' ? (
        <span>3. O valor do IOF foi somado ao valor negociado</span>
      ) : (
        ''
      )}
      <strong>Instruções para pagamento:</strong>
      <span>
        1. O boleto pode ser pago na instituição financeira que você possui
        conta, basta copiar o código acima e realizar o pagamento diretamente no
        seu banco, via Internet Banking ou Aplicativo
      </span>
      <span>
        2. Você também consegue realizar o pagamento em uma Agência Bancária ou
        Lotérica, basta informar o código acima diretamente no caixa
      </span>
    </Container>
  ) : (
    <Container>
      {!isFullPayment && (
        <>
          <strong>Importante:</strong>
          <span>
            1. As demais parcelas serão lançadas na fatura do seu cartão nos
            dias de vencimento
          </span>
          <span>
            2. O não pagamento das parcelas cancela o acordo e as condições
            negociadas
          </span>
        </>
      )}

      <strong>Instruções para pagamento:</strong>
      <span>
        1. Abra o app do banco de sua preferência e escolha a opção pagar com
        Código Pix.
      </span>
      <span>
        2. Copie e cole o código lá, confira todas as informações e confirme o
        pagamento
      </span>
    </Container>
  );
}

export default Important;
