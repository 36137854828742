import React from 'react';
import { Image } from '@midway/web-ui-component';
import BannerImg from '~/assets/img/ethic-governance/banner.svg';
import CartoesImg from '~/assets/img/ethic-governance/cartoes.svg';
import * as S from './styles';

const Governance = () => {
  return (
    <S.ContainerPage
      id="ready-page-for-screen-reader"
      aria-label="Página Governança e Ética carregada"
    >
      <S.Section className="container-fluid">
        <S.Banner>
          <S.Container className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-5">
                <Image
                  src={BannerImg}
                  alt="Imagem com uma mulher sorrindo olhando diretamente para câmera"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-7 mx-auto">
                <div className="contentBanner">
                  <h2>Ética levada a sério</h2>
                  <p>
                    A Midway Financeira oferece um canal para que pessoas
                    relacionadas ao negócio possam reportar violações ao Código
                    de Ética e Conduta da empresa, bem como situações
                    consideradas ilícitas envolvendo qualquer indivíduo ligado à
                    instituição financeira. As denúncias podem ser identificadas
                    ou anônimas.
                  </p>
                </div>
              </div>
            </div>
          </S.Container>
        </S.Banner>
      </S.Section>
      <S.Section className="container-fluid portability">
        <S.Container className="container">
          <p className="title">Como podemos te ajudar?</p>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.Card>
                <p>
                  <strong>Enviar denúncia</strong>
                  <br />A denúncia poderá ser realizada através dos canais:
                </p>
                <p>
                  <strong>Telefone:</strong> 0800 055 7611 <br />
                  <strong>E-mail:</strong> canaldedenuncias@riachuelo.com.br
                </p>
              </S.Card>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.Card>
                <p>
                  Para consultas ou reclamações envolvendo nossos cartões ou
                  Conta Digital Midway.
                </p>
                <p>
                  <strong>Acesse:</strong>
                  <br />
                  <S.ServiceCenterLink
                    data-testid="service-center-link "
                    href="/atendimento"
                  >
                    Central de atendimento
                  </S.ServiceCenterLink>
                </p>
              </S.Card>
            </div>
          </div>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container className="container">
          <p className="title">Sistema de informação de crédito</p>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p>
                O Sistema de Informações de Crédito (SCR) é uma ferramenta
                administrada pelo Banco Central que registra informações de
                crédito no Brasil. Ele auxilia na transparência e gestão do
                crédito para pessoas físicas e jurídicas.
              </p>

              <p>
                As instituições financeiras são responsáveis por enviar
                mensalmente os dados sobre as operações de crédito de seus
                clientes ao SCR. Elas também têm a obrigação de corrigir ou
                excluir informações imprecisas. Caso haja algum questionamento
                judicial, é necessário entrar em contato diretamente com a
                instituição financeira que forneceu os dados da operação em
                questão.
              </p>

              <p>
                Se surgirem dúvidas, recomenda-se consultar os Canais de
                Atendimento disponíveis. É possível ter acesso às informações
                relacionadas ao seu nome no SCR entrando em contato com o Banco
                Central.{' '}
              </p>

              <p>
                <a
                  data-testid="central-ban-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://bcb.gov.br"
                >
                  Acesse o site do Banco Central para obter mais informações.
                </a>
              </p>
            </div>
          </div>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container className="container">
          <div className="row">
            <div className="col-sm-12  col-md-12 col-lg-7 col-xl-7">
              <p className="title">Portabilidade de crédito</p>
              <p>
                A Portabilidade de Crédito é a transferência de operações de
                crédito de uma instituição financeira para outra, mediante
                liquidação antecipada da operação original.{' '}
              </p>

              <p>
                As condições da nova operação devem ser negociadas entre a
                pessoa e a instituição que concederá o novo crédito.{' '}
              </p>

              <p>
                Para realizar a Portabilidade de Crédito, procure nossos
                colaboradores no atendimento ao Cliente em loja ou entre em
                contato pela nossa central de atendimento:
              </p>

              <S.ServiceCenterLink
                data-testid="call-center-link"
                href="/atendimento"
              >
                Central de atendimento
              </S.ServiceCenterLink>
            </div>
            <div className="col-sm-12  col-md-12 col-lg-5 col-xl-5">
              <Image
                className="cardsImage"
                src={CartoesImg}
                alt="Imagem com uma mulher sorrindo olhando diretamente para câmera"
              />

              <p className="text-center">
                Acesso nosso Portal de Privacidade e <br />
                saiba mais sobre nossos esforços de <br />
                <a
                  data-testid="personal-data-protection-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://privacidade.grupoguararapes.com.br/politicas-de-privacidade/midway"
                >
                  Proteção de Dados Pessoais
                </a>
              </p>
            </div>
          </div>
        </S.Container>
      </S.Section>
    </S.ContainerPage>
  );
};

export default Governance;
