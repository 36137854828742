export const banner = [
  {
    name: 'BannerProdutos',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/mobile_todos_os_produtos.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/web_todos_os_produtos.png',
    title:
      'Tranquilidade é ter um seguro que cuida de você em todos os momentos',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/produtos-financeiros',
  },
];
