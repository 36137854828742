import { isEmpty, join, lowerCase, tail, upperFirst, words } from 'lodash';
import {
  agreementSimulationParametersMapper,
  invoicementSimulationParametersMapper,
} from '~/utils/negotiationHelpers';

export const getSelectedContract = (data, selected) => {
  return data?.contracts?.find(
    c => c.contract === (selected || data?.selected)
  );
};

export const getSelectedElegibility = data => {
  const elegibility = data?.elegibility;
  const typeSelected = data?.typeSelected;
  if (!elegibility || !typeSelected) return null;
  return typeSelected === 'agreement'
    ? elegibility[typeSelected][0]
    : elegibility[typeSelected];
};

export const getSimulationArguments = data => {
  const typeSelected = data?.typeSelected;
  const selectedEligibility = getSelectedElegibility(data);
  const selectedContract = getSelectedContract(data);
  if (!typeSelected || !selectedEligibility || !selectedContract) return null;

  if (typeSelected === 'agreement')
    return agreementSimulationParametersMapper(
      selectedEligibility,
      typeSelected,
      selectedContract?.type
    );
  if (typeSelected === 'invoicement')
    return invoicementSimulationParametersMapper(
      selectedEligibility,
      selectedContract?.type
    );
  return null;
};

export const getSelectedInstallmentData = data => {
  const { simulation, selectedInstallment } = data;

  return simulation.installments.find(
    i => i.installmentQuantity === selectedInstallment
  );
};

export const getInstallmentTitle = ({ typeSelected }) =>
  typeSelected === 'invoicement' ? 'Parcelar fatura' : 'Parcelar dívida';

export const trackingOptionsOverdue = ({ cardInfo }) => {
  if (!cardInfo?.isOverDue) return 'em-dia';
  if (cardInfo?.daysOverdue <= 77) return 'em-atraso-1-a-77';
  if (cardInfo?.daysOverdue <= 179) return 'em-atraso-78-a-179';
  return 'em-atraso-180';
};

export const getIsFullPayment = ({ typeSelected }) => {
  const isDebit = typeSelected === 'debit';
  const isAcquittance = typeSelected === 'acquittance';
  return isDebit || isAcquittance;
};

export const getUserName = fullName => {
  let partsOfName = {
    first: '',
    surname: '',
  };
  if (isEmpty(fullName)) return partsOfName;

  const nameParts = words(fullName);

  partsOfName = {
    firstName: upperFirst(lowerCase(nameParts[0] || '')),
    surname: upperFirst(lowerCase(join(tail(nameParts), ' '))),
  };

  return partsOfName;
};
