import React from 'react';
import { first, last, lowerCase } from 'lodash';
import useIsMobile from '~/hooks/useIsMobile';
import { tagMiAjudaButton } from '~/services/tagging';
import { useNegotiation } from '~/context/negotiationV2';
import { gtagSelectContent } from '~/utils/TaggingGA4';
import { HelpContainer, WhatsAppIcon } from './styles';

const GTAG_FLOW = 'miajuda';

function Help() {
  const { historyPages, system, negotiationData, duplicateData } =
    useNegotiation();
  const { isMobile } = useIsMobile();

  const contentPage = () => {
    if (last(historyPages) === 'contracts')
      return 'selecione-o-contrato-que-deseja-negociar';
    if (last(historyPages) === 'duplicate')
      return 'pegue-aqui-a-segunda-via-do-boleto-do-seu-acordo';
  };

  const gaContract = () => {
    if (last(historyPages) === 'duplicate') {
      const isTopaz = system === 'TOPAZ';
      const duplicate =
        (!isTopaz &&
          first(
            duplicateData?.filter(
              d => d.contract === negotiationData?.selectedContract?.contract
            )
          )) ||
        first(duplicateData);
      return duplicate?.contract;
    }
    return negotiationData?.selectedContract?.contract;
  };

  return (
    <HelpContainer
      isMobile={isMobile}
      onClick={() => {
        const contentTypeGA = 'precisa-de-ajuda:monest';
        const urlWPRedirect = 'https://wa.me/message/JGWS5SVRWVLZG1';

        gtagSelectContent(contentPage(), GTAG_FLOW, contentTypeGA, {
          sistema: lowerCase(system),
          proposta: negotiationData?.gaParamProposta,
          contract: gaContract(),
        });
        tagMiAjudaButton('ajuda');
        window.open(urlWPRedirect, '_blank');
      }}
    >
      <WhatsAppIcon /> Precisa de ajuda?
    </HelpContainer>
  );
}
export default Help;
