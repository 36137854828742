/* eslint-disable import/no-named-as-default */
import React, { useCallback, useEffect, useState } from 'react';

import Accordion from '~/components/Accordion';
import useIsMobile from '~/hooks/useIsMobile';
import { trackingSelectContent } from '~/analytics';
import { banner } from './data';
import RenderBannerImage from '../../components/RenderBannerImage';
import cmsService from '~/services/cms/pages';
import ImageVideo from '~/assets/img/Financial/fgts-anniversary-advance/video.png';
import Anchor from './components/Anchor';
import CardFgts from './components/CardFgts';
import * as S from './styles';
import ListStepNumber from './components/ListStepNumber';

const STEP_LIST = [
  {
    value: '01',
    text: 'Habilite a modalidade Saque-Aniversário no app FGTS. No aplicativo do FGTS, você deve aderir ao Saque-Aniversário do FGTS. Ler e aceitar os termos e condições.',
  },
  {
    value: '02',
    text: 'Libere a Midway para consulta do seu saldo. Ainda no app do FGTS, clique em "Autorizar bancos a consultarem seu FGTS”, selecione a opção "Empréstimo Saque-Aniversário" e então a Midway.',
  },
  {
    value: '03',
    text: 'Contrate o adiantamento e receba em instantes Antecipe seu Saque-Aniversário com a Midway acessando o menu "Empréstimo" no App Midway.',
  },
];

const FGTSAnniversaryAdvance = () => {
  const isMobile = useIsMobile();

  const [showYoutubeVideo, setShowYoutubeVideo] = useState(false);
  const [showCompleteListFAQ, setShowCompleteListFAQ] = useState(false);
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const [bannerData, setBannerData] = useState([]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.bannerfgts(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banners = bannerData[0] || banner;

  const toggleAccordion = () => {
    setIsOpenAccordion(!isOpenAccordion);
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BackgroundContainer>
          <S.BannerContainer>
            <RenderBannerImage {...banners} />
          </S.BannerContainer>
        </S.BackgroundContainer>
        <S.Main>
          <S.CentralizedContent data-testid="centralized-content">
            <S.CustomParagraph data-testid="custom-paragraph">
              Aqui na <S.ColorText color="#704810">Midway</S.ColorText> você
              conta com facilidade e agilidade no processo de contratação, que é{' '}
              <S.ColorText color="#704810">100% digital</S.ColorText>. Além
              disso, não possui restrição de crédito e não tem prestação mensal.
            </S.CustomParagraph>
            <S.StyledTitle data-testid="styled-title">
              Principais vantagens
            </S.StyledTitle>
          </S.CentralizedContent>

          <S.CentralizedContentCards>
            <S.CardsContainer>
              <S.CardContainer>
                <CardFgts
                  title="Taxa de juros a partir de 1,79% ao mês"
                  description="Antecipe até 10 parcelas do seu saque aniversário com a taxa de juros a partir de 1,79% a.m."
                />
                <CardFgts
                  title="Não compromete sua renda mensal"
                  description="O pagamento das parcelas do empréstimo é realizado mediante o débito nas(s) contas(s) vinculada(s) do FGTS, anualmente, no mês do seu aniversário."
                />
                <CardFgts
                  title="Dinheiro rápido mesmo para negativados"
                  description="Na Midway os clientes negativados podem contratar o Empréstimo com garantia do Saque Aniversário FGTS."
                />
                <CardFgts
                  title="Receba em sua Conta Digital Midway"
                  description="Receba em poucos minutos em sua conta digital Midway. É fácil, é rápido."
                />
              </S.CardContainer>
            </S.CardsContainer>
          </S.CentralizedContentCards>

          <S.MySection>
            <S.CentralizedContent>
              <S.TitleSection data-testid="list-step-contratar">
                Como Contratar
              </S.TitleSection>
              <S.BlockSection>
                <ListStepNumber data={STEP_LIST} />
              </S.BlockSection>
            </S.CentralizedContent>
          </S.MySection>

          <S.Centralized>
            <S.BlockStyler>
              <S.LeftContainer>
                <S.BlockParagraph data-testid="block-paragraph">
                  Confira nosso vídeo de como simular e contratar o empréstimo
                  saque aniversário FGTS no app Midway e contrate.
                </S.BlockParagraph>
              </S.LeftContainer>
              <S.RigthContainer>
                {showYoutubeVideo ? (
                  <S.IframeYoutube
                    data-testid="iframe-youtube"
                    title="Youtube video player"
                    src="https://www.youtube.com/embed/1o9vxoOUWUE?autoplay=1&rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="0"
                    showinfo="0"
                  />
                ) : (
                  <S.ButtonImage
                    data-testid="button-video"
                    onClick={() => {
                      setShowYoutubeVideo(true);
                      trackingSelectContent(
                        'abrir-video',
                        'antecipacao-saque-fgts'
                      );
                    }}
                  >
                    <S.ImageVideo
                      data-testid="image-video"
                      alt="Imagem do botão para abrir o video"
                      src={ImageVideo}
                    />
                  </S.ButtonImage>
                )}
              </S.RigthContainer>
            </S.BlockStyler>
            <S.AccordionTitle data-testid="accordion-title">
              Perguntas frequentes
            </S.AccordionTitle>
            <S.HandleShow
              data-testid="accordion-item"
              onClick={() => {
                trackingSelectContent(
                  isOpenAccordion
                    ? 'faq:fechar:o-que-seria-a-antecipacao-do-fgts-e-suas-vantagens'
                    : 'faq:abrir:o-que-seria-a-antecipacao-do-fgts-e-suas-vantagens',
                  'antecipacao-saque-fgts'
                );
                toggleAccordion();
              }}
            >
              <Accordion
                data-testid="accordion-content-1"
                key="1"
                title="O que seria a antecipação do FGTS e suas vantagens?"
                content="O empréstimo com garantia no FGTS permite a antecipação do saldo do FGTS, sem precisar esperar o mês do seu aniversário pra resgatar. Você consegue antecipar seus próximos saque-aniversários e recebe todo o dinheiro na hora.Aqui na Midway você conta com facilidade e agilidade no processo de contratação, que é 100% digital. Além disso, não possui restrição de crédito e não tem prestação mensal."
              />
            </S.HandleShow>
            <S.HandleShow
              onClick={() => {
                trackingSelectContent(
                  isOpenAccordion
                    ? 'faq:fechar:como-eu-autorizo-a-midway-a-consultar-o-saldo-do-meu-fgts'
                    : 'faq:abrir:como-eu-autorizo-a-midway-a-consultar-o-saldo-do-meu-fgts',
                  'antecipacao-saque-fgts'
                );
                toggleAccordion();
              }}
            >
              <Accordion
                key="2"
                title="Como eu autorizo a Midway a consultar o saldo do meu FGTS?"
                content="O cliente deve acessar o APP FGTS, optar pela modalidade Saque Aniversário, e depois incluir a Midway como bancos autorizados a consultar o seu FGTS."
              />
            </S.HandleShow>
            <S.HandleShow
              onClick={() => {
                trackingSelectContent(
                  isOpenAccordion
                    ? 'faq:fechar:quem-pode-realizar-a-antecipacao-do-fgts-na-midway'
                    : 'faq:quem-pode-realizar-a-antecipacao-do-fgts-na-midway',
                  'antecipacao-saque-fgts'
                );
                toggleAccordion();
              }}
            >
              <Accordion
                key="3"
                title="Quem pode realizar a antecipação do FGTS na Midway?"
                content="Clientes correntistas Midway, que possuem conta saldo no FGTS na modalidade saque aniversário e que tenha autorizado a Midway no APP do FGTS."
              />
            </S.HandleShow>
            <S.HandleShow
              onClick={() => {
                trackingSelectContent(
                  isOpenAccordion
                    ? 'faq:fechar:os-valores-das-parcelas-sao-iguais'
                    : 'faq:abrir:os-valores-das-parcelas-sao-iguais',
                  'antecipacao-saque-fgts'
                );
                toggleAccordion();
              }}
            >
              <Accordion
                key="4"
                title="Os valores das parcelas são iguais?"
                content="Não, como trata-se de uma antecipação, o valor de cada parcela se altera de acordo com o disponível pela Caixa Econômica Federal no seu saldo do FGTS, por isso você irá observar que cada parcela tem um valor diferente da outra."
              />
            </S.HandleShow>
            {!showCompleteListFAQ && isMobile ? (
              <S.CentralizedButton>
                <S.SeeMore
                  data-testid="see-more-button"
                  onClick={() => {
                    setShowCompleteListFAQ(true);
                  }}
                >
                  Ver Mais
                  <S.ArrowIcon />
                </S.SeeMore>
              </S.CentralizedButton>
            ) : (
              <>
                <S.HandleShow
                  onClick={() => {
                    trackingSelectContent(
                      isOpenAccordion
                        ? 'faq:fechar:qual-o-valor-minimo-de-contratacao'
                        : 'faq:abrir:qual-o-valor-minimo-de-contratacao',
                      'antecipacao-saque-fgts'
                    );
                    toggleAccordion();
                  }}
                >
                  <Accordion
                    key="5"
                    title="Qual o valor mínimo de contratação?"
                    content="O valor total mínimo do empréstimo é de R$ 200,00. É necessário também que o valor de cada saque a ser antecipado seja igual ou maior que R$200,00."
                  />
                </S.HandleShow>
                <S.HandleShow
                  onClick={() => {
                    trackingSelectContent(
                      isOpenAccordion
                        ? 'faq:fechar:em-quanto-tempo-o-valor-cai-na-minha-conta-midway'
                        : 'faq:abrir:em-quanto-tempo-o-valor-cai-na-minha-conta-midway',
                      'antecipacao-saque-fgts'
                    );
                    toggleAccordion();
                  }}
                >
                  <Accordion
                    key="6"
                    title="Em quanto tempo o valor cai na minha conta Midway?"
                    content="Em até 24 horas úteis o valor entre em sua conta Midway."
                  />
                </S.HandleShow>
                <S.HandleShow
                  onClick={() => {
                    trackingSelectContent(
                      isOpenAccordion
                        ? 'faq:fechar:como-consulto-o-valor-disponivel-para-realizar-a-antecipacao'
                        : 'faq:abrir:como-consulto-o-valor-disponivel-para-realizar-a-antecipacao',
                      'antecipacao-saque-fgts'
                    );
                    toggleAccordion();
                  }}
                >
                  <Accordion
                    key="7"
                    title="Como consulto o valor disponível para realizar a antecipação?"
                    content="Você pode realizar a consulta no próprio APP Midway, realizando a simulação do produto Antecipação do FGTS."
                  />
                </S.HandleShow>
                <S.HandleShow
                  onClick={() => {
                    trackingSelectContent(
                      isOpenAccordion
                        ? 'faq:fechar:como-e-realizado-o-pagamento-das-parcelas'
                        : 'faq:abrir:como-e-realizado-o-pagamento-das-parcelas',
                      'antecipacao-saque-fgts'
                    );
                    toggleAccordion();
                  }}
                >
                  <Accordion
                    key="8"
                    title="Como é realizado o pagamento das parcelas?"
                    content="Ao fazer a antecipação do saque-aniversário, você autoriza a Midway a bloquear o valor dos saques antecipados. Por isso, você não precisa se preocupar com o pagamento da parcela, ele será feito automaticamente pela Caixa no primeiro dia útil do mês do seu saque-aniversário."
                  />
                </S.HandleShow>
                <S.HandleShow
                  onClick={() => {
                    trackingSelectContent(
                      isOpenAccordion
                        ? 'faq:fechar:como-cancelar-o-emprestimo-da-antecipacao-do-fgts'
                        : 'faq:abrir:como-cancelar-o-emprestimo-da-antecipacao-do-fgts',
                      'antecipacao-saque-fgts'
                    );
                    toggleAccordion();
                  }}
                >
                  <Accordion
                    key="9"
                    title="Como cancelar o empréstimo da antecipação do FGTS?"
                    content="Você pode solicitar o cancelamento da operação em até 7 dias corridos após a contratação. Para isso, é necessário ter o saldo disponível em sua conta Midway, onde o comando de Cancelamento pode ser feito direto no APP Midway. Após 7 dias não é mais possível realizar o cancelamento."
                  />
                </S.HandleShow>
              </>
            )}
            <S.FinalText>Ainda com dúvidas?</S.FinalText>
            <S.AnchorText data-testid="faq-link">
              Confira mais informações em
              <Anchor
                onClick={() =>
                  trackingSelectContent(
                    'perguntas-frequentes',
                    'antecipacao-saque-fgts'
                  )
                }
                target="_blank"
                href="/emprestimos/faq"
              >
                perguntas frequentes
              </Anchor>
            </S.AnchorText>
          </S.Centralized>
        </S.Main>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default FGTSAnniversaryAdvance;
