import { Icon } from '@midway/web-ui-component';
import React from 'react';
import useIsMobile from '~/hooks/useIsMobile';
import { Container, ContainerTitle, Title } from './styles';
import DescriptionTag from '../../../DescriptionTag';

const CardContractsItem = ({
  title,
  description,
  onClick,
  days,
  id,
  customer,
}) => {
  const isMobile = useIsMobile();
  return (
    <Container
      isMobile={isMobile}
      onClick={onClick}
      aria-labelledby={`card-title-${id} card-description-${id}`}
    >
      <div>
        <ContainerTitle>
          <Title id={`card-title-${id}`}>{title}</Title>
          <span>{customer}</span>
        </ContainerTitle>
        <DescriptionTag
          id={`card-description-${id}`}
          type={days <= 0 ? 'inDay' : ''}
          text={description}
        />
      </div>
      <Icon icon="angle-right" size="2x" />
    </Container>
  );
};

export default CardContractsItem;
