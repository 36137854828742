import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  background-color: #000000;
  padding: 16px;
  margin-bottom: 2rem;
`;

export const Content = styled.div`
  @media (min-width: 768px) {
    width: 60%;
    margin: auto;
  }
`;

export const Title = styled.h1`
  font-size: 1.25rem !important;
  font-weight: 500;
  color: #ffffff;
  margin: 1rem 0 1rem 0;
`;

export const Description = styled.span`
  color: #959d9d;
`;
