import styled from 'styled-components';
import IconSearch from '~/assets/img/icons/IconSearch.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const BackgroundContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`;

export const CentralizeContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1445px;

  div:last-child {
    margin-bottom: 166px;
  }
  @media (max-width: 1000px) {
    padding: 0px 32px 0px 32px;

    div:last-child {
      margin-bottom: 277px;
      border-bottom: 0px;
    }
  }
`;

export const ContainerTop = styled.div`
  display: flex;
  witdh: 100%;
  margin-bottom: 44px;
  margin-top: 105px;
  justify-content: space-between;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 36px;
    margin-bottom: 22px;
    flex-direction: column;
    gap: 30px;
  }
`;
export const Title = styled.h2`
  font-family: Montserrat;
  font-size: 36px !important;
  font-weight: 700;
  line-height: 44px;

  @media (max-width: 1000px) {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 19px;
  }
`;

export const Input = styled.input`
  ${props => (props.isMobile ? 'width: 100%;' : 'width: 412px;')};
  ${props => (props.isMobile ? 'margin-bottom: 10px;' : 'margin-bottom: 0px;')};
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #c8c8c8;
  border-radius: 30px;
  background-image: url(${IconSearch});
  background-position: 15px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;

  @media (max-width: 1000px) {
    height: 44px;
  }
`;
export const ContentItem = styled.div`
  display: flex;
  witdh: 100%;
  height: 38px;
  margin-bottom: 22px;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  @media (max-width: 1000px) {
    width: 100%;
    height: auto;
    max-height: 220px;
    align-items: center;
    padding: 22px 0px 22px 0px;
    margin-bottom: 0px;
  }
`;
export const TitleItem = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #3b4443;
  @media (max-width: 1000px) {
    width: 50%;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    word-break: break-word;
  }
`;
export const LinkItem = styled.a`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #a06d2c;
  cursor: pointer;
  text-decoration: underline;
  a:hover {
    color: #a06d2c;
  }
  a:active {
    color: #a06d2c;
  }

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;
