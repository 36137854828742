import styled, { keyframes } from 'styled-components';
import { IconLoader2 as _IconLoader } from '@tabler/icons-react';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const IconLoader = styled(_IconLoader)`
  animation: ${spin} 2s linear infinite;
  height: 1rem;
`;

export const Button = styled.button`
  all: unset;
  outline: revert;
  padding: 16px;
  width: 100%;
  border-radius: 100px;
  background-color: #000000;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  box-sizing: border-box;
  display: block;

  &[disabled],
  &:disabled {
    background-color: #9e9e9e;
  }

  @media (min-width: 768px) {
    width: 80%;
    margin: auto;
  }
`;
