import React from 'react';
import * as S from './styles';
import QRCode from '../../../NewNegotiationCampaign/components/QRCode';

const Button = ({ value }) => {
  return (
    <S.QRCodeContainer>
      <QRCode value={value} />
      <span>Atenção: Este código poderá ser utilizado uma única vez.</span>
    </S.QRCodeContainer>
  );
};

export default Button;
