import { useQuery } from '@tanstack/react-query';
import CollectionApi from '~/services/CollectionApi';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import { messageErrors } from '~/pages/PaymentChannel/constants';
import NoEligibilityError from '~/pages/PaymentChannel/errors/NoEligibilityError';

const useElegibility = ({ date, documentNumber, contract, type }) => {
  const { updateData } = usePaymentChannelContext();

  const queryFn = async ({ date, documentNumber, contract, type }) => {
    const response = await CollectionApi.getEligibility(
      date,
      documentNumber?.replace(/[.-]/g, ''),
      contract,
      type
    );

    if (!response?.detail || response.error) {
      if (response.message === messageErrors.no_elegibility) {
        throw new NoEligibilityError(response.message);
      }
      throw new Error(response.message ?? response.error);
    }

    updateData('elegibility', response.detail);

    return response.detail;
  };

  return useQuery([date, documentNumber, contract, type], {
    queryFn: () => queryFn({ date, documentNumber, contract, type }),
    enabled: Boolean(date && documentNumber && contract && type),
    refetchOnWindowFocus: false,
  });
};

export default useElegibility;
