import styled, { css } from 'styled-components';

export const Container = styled.button`
  cursor: pointer !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: max-content;
  min-width: 390px;
  box-shadow: 2px 2px 4px rgba(37, 37, 37, 0.14902);
  padding: 18px;
  margin-top: 24px;
  border-radius: 8px;
  border: none;
  background: #f7f7f7;
  text-align: left;

  ${({ isMobile }) =>
    isMobile &&
    css`
      min-width: 95%;
    `} div {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;

  span {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }
`;
