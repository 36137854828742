export const banner = [
  {
    name: 'BannerFAQEmprestimo ',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-mobile-faq-ep-site.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-faq-emprestimo-site-midway.png',
    title: 'Perguntas frequentes',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];
