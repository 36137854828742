import React, { useState } from 'react';
import * as S from './styles';

const MaterialInput = ({
  label,
  onChange,
  maskFn = v => v,
  description = '',
  errorMessage,
  id,
  ...props
}) => {
  const [value, setValue] = useState('');

  let errorOrDescription = errorMessage || description;
  if (errorOrDescription.length === 0) errorOrDescription = null;

  const handleChange = e => {
    e.target.value = maskFn(e.target.value);
    setValue(e.target.value);
    onChange(e);
  };

  return (
    <S.Container className={errorMessage ? 'hasError' : ''}>
      <S.InputContainer>
        <S.InputField
          {...props}
          value={value}
          onChange={handleChange}
          id={id}
        />
        <S.Label
          className={value.length > 0 ? 'shrink' : undefined}
          htmlFor={id}
        >
          {label}
        </S.Label>
      </S.InputContainer>
      {errorOrDescription && (
        <S.Description>{errorOrDescription}</S.Description>
      )}
    </S.Container>
  );
};

export default MaterialInput;
