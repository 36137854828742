import { useQuery } from '@tanstack/react-query';
import CollectionApi from '~/services/CollectionApi';
import { usePaymentChannelContext } from '../../../context/PaymentChannel';

const usePaymentMethod = ({ token }) => {
  const { updateData } = usePaymentChannelContext();

  const queryFn = async ({ token }) => {
    const response = await CollectionApi.getPaymentMethod({
      system: 'cards',
      token,
    });

    if (!response?.detail || response.error) {
      throw new Error(response?.message ?? response?.error);
    }

    updateData('paymentMethods', response?.detail?.paymentMethods);

    return response?.detail?.paymentMethods;
  };

  return useQuery([token], {
    queryFn: () => queryFn({ token }),
    enabled: Boolean(token),
    refetchOnWindowFocus: false,
  });
};

export default usePaymentMethod;
