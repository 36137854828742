import React from 'react';
import { Switch } from 'react-router-dom';
import Route from '~/routes/Route';
import Home from '~/pages/PaymentChannel/screens/Home';
import Contracts from '~/pages/PaymentChannel/screens/Contracts';
import CardsHome from '~/pages/PaymentChannel/screens/CardsHome';
import ResumeTypes from '~/pages/PaymentChannel/screens/ResumeTypes';
import Duplicate from '~/pages/PaymentChannel/screens/Duplicate';
import Installments from '~/pages/PaymentChannel/screens/Installments';
import ResumePayment from '~/pages/PaymentChannel/screens/ResumePayment';
import GeneratedPayment from '~/pages/PaymentChannel/screens/GeneratedPayment';
import Header from '~/pages/PaymentChannel/components/Header';
import ErrorScreen from '~/pages/PaymentChannel/screens/ErrorScreen';

export const screens = {
  HOME: '/',
  CONTRACTS: '/contracts',
  DUPLICATE: '/duplicate',
  CARDS_HOME: '/cards',
  RESUME_TYPES: '/resume-types',
  INSTALLMENTS: '/installments',
  RESUME_PAYMENT: '/resume',
  GENERATED_PAYMENT: '/generated',
  ERROR: '/error',
};

const PaymentChannelRoutes = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path={screens.HOME} component={Home} />
        <Route exact path={screens.CONTRACTS} component={Contracts} />
        <Route exact path={screens.DUPLICATE} component={Duplicate} />
        <Route exact path={screens.CARDS_HOME} component={CardsHome} />
        <Route exact path={screens.RESUME_TYPES} component={ResumeTypes} />
        <Route exact path={screens.INSTALLMENTS} component={Installments} />
        <Route exact path={screens.RESUME_PAYMENT} component={ResumePayment} />
        <Route
          exact
          path={screens.GENERATED_PAYMENT}
          component={GeneratedPayment}
        />
        <Route exact path={screens.ERROR} component={ErrorScreen} />
      </Switch>
    </>
  );
};

export default PaymentChannelRoutes;
