import React, { useCallback } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import {
  IconInfoCircle,
  IconReceiptOff,
  IconReceipt2,
  IconCircleCheck,
  IconCircleX,
} from '@tabler/icons-react';
import CardImg from '~/assets/img/CardBenefits/cards.png';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const ComparisonInfo = () => {
  const IconCheckGreen = () => {
    return <IconCircleCheck color="#4da145" aria-label="Sim" />;
  };

  const IconXRed = () => {
    return <IconCircleX color="#c3223d" aria-label="Não" />;
  };

  const addTooltip = useCallback(
    value => (
      <S.TooltipWrapper id="AddPopover">
        <S.Tooltip>
          <S.TooltipText>{value}</S.TooltipText>
        </S.Tooltip>
        <S.TooltipArrow />
      </S.TooltipWrapper>
    ),
    []
  );

  const tableDataObject = [
    {
      label: 'Ganhe 10% de desconto na primeira compra!',
      riachuelo: true,
      mastercard: false,
      contentType: 'informacoes:ganhe-10%-de-desconto-na-primeira-compra',
      popover:
        'Desconto válido para cartões da loja e em compras feitas no app, site ou lojas físicas. Exceto eletrônicos',
    },
    { label: 'Até 2 cartões adicionais', riachuelo: true, mastercard: true },
    {
      label:
        'Descontos em Parceiros Midway em diversos segmentos como Farmácia e Viagens',
      riachuelo: true,
      mastercard: true,
    },
    {
      label: 'Parcelamento em até 5x sem juros',
      riachuelo: true,
      mastercard: true,
    },
    {
      label: 'Pagamentos em até 120 dias em 8x com juros',
      riachuelo: true,
      mastercard: true,
    },
    {
      label:
        'Compras em qualquer local que aceite cartões com bandeira mastercard',
      riachuelo: false,
      mastercard: true,
    },
    {
      label: 'Cashback de 5% em compras fora da Riachuelo',
      riachuelo: false,
      mastercard: true,
      contentType: 'informacoes:cashback-de-5%-em-compras-fora-da-riachuelo',
      popover:
        'Exclusivo para clientes selecionados. Desconto disponível para uso nas Lojas Riachuelo.',
    },
    {
      label: 'Programa Mastercard Surpreenda',
      riachuelo: false,
      mastercard: true,
    },
  ];

  const renderTableData = () => {
    return tableDataObject.map((item, index) => (
      <S.TableRow key={index}>
        <S.TableData>
          <S.Text>{item.label}</S.Text>
          <S.TableIcon>
            {item.popover && (
              <OverlayTrigger overlay={addTooltip(item.popover)}>
                <IconInfoCircle
                  onMouseEnter={() => {
                    trackingSelectContent(item.contentType, 'beneficios');
                  }}
                />
              </OverlayTrigger>
            )}
          </S.TableIcon>
        </S.TableData>
        <S.TableData>
          {item.riachuelo ? <IconCheckGreen /> : <IconXRed />}
        </S.TableData>
        <S.TableData>
          {item.mastercard ? <IconCheckGreen /> : <IconXRed />}
        </S.TableData>
      </S.TableRow>
    ));
  };

  const RiachueloInfo = () => {
    return (
      <S.Info>
        <S.InfoTextContainer>
          <S.SubTitle>
            Cartão <br /> Riachuelo
          </S.SubTitle>
          <S.SmallText>
            O cartão de uso exclusivo em nossas lojas Riachuelo, Fanlab,
            Carter’s e Riachuelo Casa
          </S.SmallText>
        </S.InfoTextContainer>
        <S.GrayboxWithIcon>
          <IconReceiptOff />
          <S.SmallText bold>Não possui anuidade</S.SmallText>
        </S.GrayboxWithIcon>
      </S.Info>
    );
  };

  const MastercardInfo = () => {
    return (
      <S.Info>
        <S.InfoTextContainer>
          <S.SubTitle>
            Cartão Riachuelo <br /> Mastercard
          </S.SubTitle>
          <S.SmallText>
            O Cartão Mastercard para você usar em qualquer lugar!
          </S.SmallText>
        </S.InfoTextContainer>
        <S.GrayboxWithIcon>
          <IconReceipt2 />
          <S.SmallText bold>Anuidade apenas R$14,80</S.SmallText>
        </S.GrayboxWithIcon>
      </S.Info>
    );
  };

  return (
    <S.Container>
      <S.IntroContainer>
        <div>
          <S.Title>Cartões Riachuelo</S.Title>
          <S.Text>Confira agora todas as vantagens dos nossos Cartões!</S.Text>
        </div>
        <S.MobileOnlyWrapper>
          <S.Image src={CardImg} alt="" role="presentation" />
        </S.MobileOnlyWrapper>
      </S.IntroContainer>
      <S.MobileOnlyWrapper>
        <S.InfoContainer>
          <RiachueloInfo />
          <MastercardInfo />
        </S.InfoContainer>
      </S.MobileOnlyWrapper>
      <S.Table>
        <S.TableRow>
          <S.TableHeader>
            <S.DesktopOnlyWrapper>
              <S.Image src={CardImg} alt="" role="presentation" />
            </S.DesktopOnlyWrapper>
          </S.TableHeader>
          <S.TableHeader>
            <S.MobileOnlyWrapper>
              <S.TableHeaderText>Riachuelo</S.TableHeaderText>
            </S.MobileOnlyWrapper>
            <S.DesktopOnlyWrapper>
              <RiachueloInfo />
            </S.DesktopOnlyWrapper>
          </S.TableHeader>
          <S.TableHeader>
            <S.MobileOnlyWrapper>
              <S.TableHeaderText orange>Mastercard</S.TableHeaderText>
            </S.MobileOnlyWrapper>
            <S.DesktopOnlyWrapper>
              <MastercardInfo />
            </S.DesktopOnlyWrapper>
          </S.TableHeader>
        </S.TableRow>
        {renderTableData()}
      </S.Table>
    </S.Container>
  );
};

export default ComparisonInfo;
