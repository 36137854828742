import { useEffect } from 'react';
import { Button } from '@midway/web-ui-component';
import { trackingException } from '~/analytics';
import { flow as analyticsFlow } from '~/analytics/PaymentChannel';

import {
  Overlay,
  ModalContainer,
  ImageContainer,
  Title,
  Message,
  ButtonContainer,
  ContentWrapper,
} from './styles';
import iconModalError from '~/assets/img/PaymentChannel/iconModalError.png';
import iconModalWarning from '~/assets/img/PaymentChannel/iconModalWarning.png';
import { useHistory, useLocation } from 'react-router-dom';
import { errorTypes } from '~/pages/PaymentChannel/constants';

const errorConfig = {
  [errorTypes.no_contract]: {
    type: 'warning',
    title: 'Não encontramos nenhum contrato',
    message:
      'Não encontramos nenhum cartão ou empréstimo em sua conta. Se precisar de ajuda, entre em contato com nossa central de atendimento.',
    fatal: false,
  },
  [errorTypes.no_elegibility]: {
    type: 'warning',
    title: 'Cartão não disponível para pagamento.',
    message:
      'Se precisar de ajuda entre em contato com a nossa central de atendimento.',
    fatal: false,
  },
  [errorTypes.generic]: {
    title: 'Não foi possível prosseguir com sua solicitação',
    message:
      'Estamos com alguns problemas sistêmicos. Por favor, tente novamente mais tarde.',
    fatal: true,
  },
  [errorTypes.timeout]: {
    title: 'Seu tempo de consulta expirou',
    message: 'Sua sessão expirou. Por favor, retorne à tela inicial.',
    fatal: false,
  },
  [errorTypes.register_error]: {
    title: 'Não foi possível seguir com o meio de pagamento escolhido',
    message:
      'Estamos com alguns problemas sistêmicos. Por favor, tente novamente mais tarde.',
    fatal: true,
  },
};

const ErrorScreen = () => {
  const navigate = useHistory();
  const location = useLocation();
  const {
    errorType = errorTypes.generic,
    customMessage,
    errorObject,
    contentPage,
    flow = analyticsFlow.default,
  } = location?.state || {};

  const currentError = errorConfig[errorType];

  const closeHandler = () => {
    if (errorType === errorTypes.no_elegibility) return navigate.go(-2);
    navigate.go(-Infinity);
  };

  const previouslyAccessedPage = () => {
    return navigate?.entries?.at(-2)?.pathname;
  };

  useEffect(() => {
    let description;
    if (errorType === errorTypes.generic) {
      description = errorObject?.message || currentError.message;
    } else {
      description = customMessage || currentError.message;
    }

    trackingException(
      description,
      flow,
      currentError.fatal,
      contentPage || previouslyAccessedPage()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Overlay>
      <ModalContainer>
        <ContentWrapper>
          <ImageContainer>
            <img
              src={currentError?.type ? iconModalWarning : iconModalError}
              alt="Erro"
            />
          </ImageContainer>

          <Title>{currentError.title}</Title>

          <Message>{customMessage || currentError.message}</Message>

          <ButtonContainer>
            <Button
              block
              state="black"
              title="Voltar ao início"
              aria-label="Voltar ao início"
              size="lg"
              onClick={closeHandler}
            />
          </ButtonContainer>
        </ContentWrapper>
      </ModalContainer>
    </Overlay>
  );
};

export default ErrorScreen;
