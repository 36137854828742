import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ImageConhecaNossasFuncionalidades from '~/assets/img/digital-account/conhecao-nossas-funcionalidades.png';
import ImageLimiteLiberado from '~/assets/img/digital-account/limite-liberado.png';
import ImageParcelamentoDeFatura from '~/assets/img/digital-account/parcelamento-de-fatura.png';
import ImagePixNoCredito from '~/assets/img/digital-account/pix-no-credito.png';
import ImageSacarDinheiro from '~/assets/img/digital-account/sacar-dinheiro.png';
import ImagePassoAPasso from '~/assets/img/digital-account/passo-a-passo.png';
import { trackingViewPage } from '~/analytics';
import { PageWrapper, Sections, SectionsItems, SectionsTitle } from './styles';
import HowToOpenAccount from './components/HowToOpenAccount';
import Section from './components/Section';
import Header from './components/Header';

const DigitalAccount = () => {
  const history = useHistory();

  useEffect(() => {
    const page = {
      pageLocation: 'http://www.midway.com.br/conta-digital',
      pageTitle: 'Conta Digital Midway',
      pageReferrer: 'http://www.midway.com.br/conta-digital',
      pagePath: '/conta-digital',
    };

    trackingViewPage(
      page.pageLocation,
      page.pageTitle,
      page.pageReferrer,
      page.pagePath
    );
  }, []);

  const data = useMemo(
    () => ({
      sections: [
        {
          title: 'Conta digital grátis com rendimento automático',
          description: `Tudo que você precisa reunido em um só app e <strong>o seu dinheiro rende 103% do CDI automaticamente</strong> a partir de 30 dias, mais do que a poupança e sem IOF.
        *consulte os termos`,
          action: () => history.push('/app/'),
          actionText: 'Abrir conta grátis',
          visible: true,
          image: ImageConhecaNossasFuncionalidades,
        },
        {
          title: 'Limite do Cartão Riachuelo liberado em instantes',
          description: `Pague sua fatura pela Conta Midway e tenha o limite liberado em instantes.`,
          visible: true,
          image: ImageLimiteLiberado,
        },
        {
          title: 'Parcelamento de fatura em até 15 vezes',
          description: `Pague o valor total ou se precisar parcele sua fatura em até 15 vezes`,
          visible: true,
          image: ImageParcelamentoDeFatura,
        },
        {
          title: 'Pix no crédito',
          description:
            'Parcele transações instantâneas e pague direto na fatura em até 12 vezes',
          link: '/pix',
          badge: 'novidade',
          actionText: 'Mais informações sobre o Pix',
          action: () => history.push('/pix'),
          visible: false,
          image: ImagePixNoCredito,
        },
        {
          title: 'Saque e Depósito nas Lojas Riachuelo',
          description: `Saque e deposite dinheiro em qualquer Loja Riachuelo.`,
          link: '/buscar-lojas',
          badge: 'novidade',
          visible: true,
          image: ImageSacarDinheiro,
          actionText: 'Encontrar Lojas Riachuelo',
          action: () => history.push('/buscar-lojas'),
        },
      ],
      howToOpenAccount: {
        title: 'Como abrir uma Conta digital Midway',
        subtitle: 'Siga o passo a passo:',
        image: ImagePassoAPasso,
        items: [
          {
            key: 1,
            title: 'Baixe o app Midway',
            description:
              'Acesse a loja de aplicativos do seu celular e baixe o app para abrir sua conta digital gratuita.',
          },
          {
            key: 2,
            title: 'Faça o cadastro',
            description:
              'Preencha seus dados e cadastre uma chave Pix para receber dinheiro em instantes.',
          },
          {
            key: 3,
            title: 'Aproveite',
            description:
              'O seu dinheiro já começa a render. Aproveite esse e outros benefícios da sua nova conta! ',
          },
        ],
      },
    }),
    [history]
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//l.getsitecontrol.com/d7o612v7.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Sections>
        <SectionsTitle>Conheça nossas funcionalidades</SectionsTitle>
        <SectionsItems>
          {data.sections
            .filter(item => item.visible)
            .map((section, index) => (
              <Section {...section} index={index} key={index} />
            ))}
        </SectionsItems>
      </Sections>
      <HowToOpenAccount />
    </PageWrapper>
  );
};

export default DigitalAccount;
