import styled from 'styled-components';
import { IconChevronRight as _IconChevronRight } from '@tabler/icons-react';
import { ReactComponent as _MasterCardIcon } from '~/assets/img/vetor-mastercard-color.svg';
import OriginalBadge from '~/pages/PaymentChannel/components/Badge';
import { ContentContainer } from '~/pages/PaymentChannel/styles';

export const Wrapper = styled.div`
  background-color: #f9f9f9;
  margin-bottom: 6rem;
`;

export const Container = styled(ContentContainer)``;

export const ItemContract = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  margin: 8px auto 0 auto;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-basis: 100%;
`;

export const ItemTitle = styled.h4`
  font-size: 1.125rem !important;
  font-weight: 500;
  color: #221f20;
  margin-bottom: 0;
`;

export const ItemText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #383838;
  margin-bottom: 0;
`;

export const Badge = styled(OriginalBadge)`
  margin-left: auto;
`;

export const IconChevronRight = styled(_IconChevronRight)`
  width: 1.5rem;
  flex-basis: 1.5rem;
  height: 1.5rem;
`;

export const MasterCardIcon = styled(_MasterCardIcon)`
  width: 16px;
  flex-basis: 16px;
  height: 19px;
`;
