import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import format from '~/services/format';
import InstallmentOptions from './InstallmentOptions';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import useSimulation from '../../hooks/useSimulation';
import {
  getInstallmentTitle,
  getSelectedElegibility,
  getSimulationArguments,
  trackingOptionsOverdue,
} from '../../helpers';
import EntryInput from '../../components/EntryInput';
import { trackingSelectContent, trackingViewContent } from '~/analytics';
import { contentPage, contentType, flow } from '~/analytics/PaymentChannel';
import { screens } from '~/pages/PaymentChannel/routes';

const Installments = () => {
  const navigate = useHistory();
  const [simulationData, setSimulationData] = useState();
  const { paymentChannelData, updateData } = usePaymentChannelContext();

  const selectedEligibility = getSelectedElegibility(paymentChannelData);

  const simulationArguments = getSimulationArguments(paymentChannelData);

  const [entryValue, setEntryValue] = useState(
    paymentChannelData?.simulation?.entryAmount ||
      selectedEligibility?.minEntryValue
  );

  const [installmentNumberSelected, setInstallmentNumberSelected] = useState(
    paymentChannelData?.selectedInstallment
  );

  const [isInstallmentOptionsOpen, setIsInstallmentOptionsOpen] =
    useState(false);

  const getEntryValue = () =>
    paymentChannelData.typeSelected === 'agreement'
      ? selectedEligibility?.minEntryValueApplyAllDiscounts
      : selectedEligibility?.minEntryValue;

  const debouncedUpdateEntryValue = debounce(value => {
    setEntryValue(parseFloat(value.replace('.', '').replace(',', '.')));
  }, 2000);

  const {
    data: currentSimulationData,
    isLoading: isSimulationLoading,
    error,
  } = useSimulation({
    ...simulationArguments,
    entry: entryValue,
  });

  const trackingSelectContentWithDefaults = ct => {
    trackingSelectContent(
      ct,
      flow.cards,
      `${contentPage.installments}:${trackingOptionsOverdue(paymentChannelData)}`,
      {
        contrato: paymentChannelData?.selected,
      }
    );
  };

  useEffect(() => {
    if (currentSimulationData) setSimulationData(currentSimulationData);
  }, [currentSimulationData]);

  useEffect(() => {
    const ct = isInstallmentOptionsOpen
      ? contentPage.installmentsMoreOptions
      : contentPage.installments;
    trackingViewContent(
      `${ct}:${trackingOptionsOverdue(paymentChannelData)}`,
      flow.cards,
      {
        contrato: paymentChannelData?.selected,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstallmentOptionsOpen]);

  const maxInstallmentNumber = simulationData?.installments
    ?.map(i => i.installmentQuantity)
    .reduce((acc, curr) => Math.max(acc, curr));

  const selectedInstallment = simulationData?.installments.find(
    i => i.installmentQuantity === installmentNumberSelected
  );

  useEffect(() => {
    if (
      !installmentNumberSelected ||
      installmentNumberSelected > maxInstallmentNumber
    ) {
      setInstallmentNumberSelected(maxInstallmentNumber);
    }
  }, [installmentNumberSelected, maxInstallmentNumber]);

  const handleMinusButton = () => {
    setInstallmentNumberSelected(value => value - 1);
    trackingSelectContentWithDefaults(contentType.decreaseInstallments);
  };

  const handlePlusButton = () => {
    setInstallmentNumberSelected(value => value + 1);
    trackingSelectContentWithDefaults(contentType.increaseInstallments);
  };

  const plusButtonDisabled = installmentNumberSelected >= maxInstallmentNumber;
  const minusButtonDisabled = installmentNumberSelected <= 1;

  const calculateTotal = installment => {
    return (
      (installment.installmentQuantity - 1) * installment.installmentAmount +
      installment.lastInstallmentAmount +
      simulationData.entryAmount
    );
  };

  const handleContinue = async () => {
    const installmentPromise = updateData(
      'selectedInstallment',
      installmentNumberSelected
    );
    const simulationPromise = updateData('simulation', currentSimulationData);
    trackingSelectContentWithDefaults(contentType.installmentsContinue);

    await Promise.all([installmentPromise, simulationPromise]);
    navigate.push(screens.RESUME_PAYMENT);
  };

  const getDebtValue = () => {
    return selectedEligibility.mainValue + selectedEligibility.interestValue;
  };

  const getIntroText = () => {
    if (paymentChannelData.typeSelected === 'invoicement') {
      return (
        <>
          {' '}
          Escolha a opção de parcelamento ideal para sua(s) fatura(s) de{' '}
          {format.currency(getDebtValue())}
        </>
      );
    }
    return (
      <>
        Escolha a opção de parcelamento ideal para sua dívida de{' '}
        {format.currency(getDebtValue())}
      </>
    );
  };

  const getErrorMessage = () => {
    if (error?.message && error.message !== 'Internal server error')
      return `Mínimo de ${format.currency(getEntryValue())}`;
    return null;
  };

  const isLoading = isSimulationLoading || !selectedInstallment;
  if (isInstallmentOptionsOpen)
    return (
      <InstallmentOptions
        setIsInstallmentOptionsOpen={setIsInstallmentOptionsOpen}
        setInstallmentSelected={num => {
          setInstallmentNumberSelected(num);
        }}
        installments={simulationData.installments}
        calculateTotalFn={calculateTotal}
      />
    );
  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.Title>{getInstallmentTitle(paymentChannelData)}</S.Title>
          <S.Text>{getIntroText()}</S.Text>
          <S.ContainerWithMarginTop>
            <S.Text>Valor de entrada</S.Text>
            <EntryInput
              value={entryValue}
              min={getEntryValue()}
              onChange={v => {
                debouncedUpdateEntryValue(v);
              }}
            />
            <S.SmallText>{getErrorMessage()}</S.SmallText>
          </S.ContainerWithMarginTop>
          <S.ContainerWithMarginTop>
            <S.SubTitle>Parcelamento</S.SubTitle>
            <S.Text>
              Entrada em{' '}
              {format.date(
                simulationData?.entryDate ?? simulationData?.firstReleaseDate
              )}
            </S.Text>
          </S.ContainerWithMarginTop>
          <S.ContainerWithMarginTop>
            <S.ButtonsContainer>
              <S.RoundButton
                disabled={minusButtonDisabled}
                onClick={handleMinusButton}
                aria-label="Diminuir número de parcelas"
                data-testid="diminuir-parcelas"
              >
                <S.IconMinus />
              </S.RoundButton>
              <div>
                <S.ButtonContainerInstallmentsNumber blur={isLoading}>
                  {isLoading ? 12 : installmentNumberSelected}x de
                </S.ButtonContainerInstallmentsNumber>
                <S.ButtonContainerInstallmentsValue blur={isLoading}>
                  {format.currency(
                    isLoading ? 1234 : selectedInstallment?.installmentAmount
                  )}
                </S.ButtonContainerInstallmentsValue>
              </div>
              <S.RoundButton
                disabled={plusButtonDisabled}
                onClick={handlePlusButton}
                aria-label="Aumentar número de parcelas"
                data-testid="aumentar-parcelas"
              >
                <S.IconPlus />
              </S.RoundButton>
            </S.ButtonsContainer>
            {simulationData?.installments.length > 1 && (
              <S.MoreOptionsButton
                onClick={() => setIsInstallmentOptionsOpen(true)}
              >
                Exibir todas as parcelas
              </S.MoreOptionsButton>
            )}
          </S.ContainerWithMarginTop>
        </S.Container>
      </S.Wrapper>
      <S.TotalAndContinueWrapper>
        <S.TotalAndContinueContainer>
          <div>
            <S.TextDark>Total</S.TextDark>
            <S.TotalValue blur={isLoading}>
              {format.currency(
                isLoading ? 1234 : calculateTotal(selectedInstallment)
              )}
            </S.TotalValue>
            <S.SmallTextDark blur={isLoading}>
              Juros{' '}
              {format.percentage(
                isLoading
                  ? 1234
                  : selectedInstallment.composition.calculationBasis
                      .percentageRateMonth
              )}
              % a.m
            </S.SmallTextDark>
          </div>
          <S.ContinueButton
            onClick={handleContinue}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Continuar
          </S.ContinueButton>
        </S.TotalAndContinueContainer>
      </S.TotalAndContinueWrapper>
    </>
  );
};

export default Installments;
