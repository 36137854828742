import React from 'react';
import * as S from './styles';
import { IconCheck } from '@tabler/icons-react';

const Badge = ({ type, extras = [], children, className }) => {
  const textByType = {
    open: 'Fatura aberta',
    closed: 'Fatura fechada',
    dueToday: 'Vence hoje',
    dueTomorow: 'Vence amanha',
    openWithMonth: `Fatura aberta - ${extras[0]}`,
    paidWithMonth: `Fatura paga - ${extras[0]}`,
    currentOpen: 'Fatura atual aberta',
    closedWithMonth: `Fatura fechada - ${extras[0]}`,
    dueTodayWithMonth: `Vence hoje - ${extras[0]}`,
    lateWithDays: `${extras[0]} dia${extras[0]?.toString() === '1' ? '' : 's'} em atraso`,
  };

  const classByType = {
    open: 'open',
    openWithMonth: 'open',
    paidWithMonth: 'paid',
    currentOpen: 'open',
    closed: 'closed',
    closedWithMonth: 'closed',
    dueToday: 'due-today',
    dueTodayWithMonth: 'due-today',
    late: 'late',
    lateWithDays: 'late',
    dueTomorow: 'due-tomorow',
    big: 'big',
  };

  const iconByType = {
    paidWithMonth: <IconCheck />,
  };

  return (
    <S.Wrapper className={`${className} ${classByType[type]}`}>
      <S.Text>
        {iconByType[type]}
        {children || textByType[type]}
      </S.Text>
    </S.Wrapper>
  );
};

export default Badge;
