import React from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import DOMPurify from 'dompurify';
import NotFoundImg from '~/assets/img/CardBenefits/notfound.svg';
import { trackingSelectContent } from '~/analytics';
import { slugify } from '~/utils/utils';
import * as S from './styles';

const FilteredPartners = ({ partners }) => {
  const navigateClickHandler = data => {
    window.open(data.link, '_blank');
    const contentType = `${slugify(data.title)}:ir-para-site`;
    trackingSelectContent(contentType, 'beneficios');
  };

  const Partner = ({ data }) => {
    return (
      <S.PartnerContainer
        key={data.id}
        onClick={() => navigateClickHandler(data)}
      >
        <S.LogoWrapper>
          <S.LogoImage alt={data.title} src={data.icon} />
        </S.LogoWrapper>
        <S.PartnerContent>
          <S.TitleRow>
            <S.Title>{data.title}</S.Title>
            <S.NavigateButton>
              <IconChevronRight />
            </S.NavigateButton>
          </S.TitleRow>
          <S.Text
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.description),
            }}
          />
          {data.rules.map((rule, index) => (
            <S.Rule key={index}>{rule}</S.Rule>
          ))}
        </S.PartnerContent>
      </S.PartnerContainer>
    );
  };

  const NotFound = () => {
    return (
      <>
        <S.NotFoundImg src={NotFoundImg} role="presentation" alt="" />
        <S.NotFoundText>
          Não encontramos resultados para a busca realizada. Tente novamente.
        </S.NotFoundText>
      </>
    );
  };

  return (
    <S.Container>
      {partners.length === 0 ? (
        <NotFound />
      ) : (
        partners.map(partner => <Partner key={partner.id} data={partner} />)
      )}
    </S.Container>
  );
};

export default FilteredPartners;
