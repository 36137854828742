import React from 'react';

import {
  IconCoin,
  IconGift,
  IconPercentage,
  IconFileCheck,
  IconShoppingCart,
} from '@tabler/icons-react';
import Container from '~/components/NewUi/Container';
import Button from '~/components/NewUi/Button';
import Desktop from '~/components/Responsive/Desktop';
import Mobile from '~/components/Responsive/Mobile';
import { trackingSelectContent } from '~/analytics';

import MasterCardIcon from '~/assets/img/icons/master-card-icon.svg';

import Typography from '~/components/NewUi/Typography';
import RchloCard from '~/assets/img/home/cartao_rchl_novo.png';
import {
  Content,
  CreditCardContainer,
  CreditCardImage,
  Feature,
  FeatureIcon,
  FeaturesContainer,
  FeatureText,
  FeatureList,
  ActionButton,
} from './style';

const RiachueloCard = () => {
  return (
    <Container>
      <Content>
        <FeaturesContainer aria-label="Vantagens de ter um cartão Riachuelo">
          <Typography variant="h2" styleType="title">
            Cartão Riachuelo
          </Typography>
          <Typography variant="p" color="#616B6A" fontSize="18px">
            Aproveite todas as vantagens de ter um cartão Riachuelo
          </Typography>
          <FeatureList aria-label="Lista de vantagens">
            <Feature>
              <FeatureIcon>
                <IconGift />
              </FeatureIcon>
              <FeatureText>
                10% de desconto na primeira compra para Cartões da Loja. Exceto
                em eletrônicos.
              </FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <IconCoin />
              </FeatureIcon>
              <FeatureText>
                Condições especiais de parcelamento no site e nas lojas físicas
              </FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <IconPercentage />
              </FeatureIcon>
              <FeatureText>
                Desconto de até 35% em farmácias parceiras
              </FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <IconFileCheck />
              </FeatureIcon>
              <FeatureText>
                Acesse e pague sua fatura nos apps Midway ou Riachuelo
              </FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <img alt="" src={MasterCardIcon} />
              </FeatureIcon>
              <FeatureText>Programa Mastercard Surpreenda</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <IconShoppingCart />
              </FeatureIcon>
              <FeatureText>Compra on-line com retirada em loja</FeatureText>
            </Feature>
          </FeatureList>

          <ActionButton
            onClick={() =>
              trackingSelectContent(
                'cartao-riachuelo:pedir-cartao-riachuelo',
                'home'
              )
            }
          >
            <Button
              to="/cartoes#rchlo-pl"
              role="button"
              aria-label="Clique para pedir um cartão Riachuelo"
              tabIndex={0}
            >
              <Desktop>Pedir cartão Riachuelo</Desktop>
              <Mobile>Pedir cartão</Mobile>
            </Button>
          </ActionButton>
        </FeaturesContainer>
        <CreditCardContainer>
          <CreditCardImage
            role="img"
            title="Novo cartão Riachuelo"
            src={RchloCard}
            alt="Novos cartões Riachuelo"
          />
        </CreditCardContainer>
      </Content>
    </Container>
  );
};

export default RiachueloCard;
