import React from 'react';
import StepNumber from '../StepNumber';
import * as S from './styles';

const ListStepNumber = ({ data }) => {
  return (
    <div data-testid={'list-step-number-container'}>
      {data.map((value, index) => (
        <S.Container key={index}>
          <S.StepNumberContainer data-testid={`list-step-number-${index}`}>
            <S.VerticalLine visible={index === 0} />
            <StepNumber size={70} value={value.value} color="#242424" />
            <S.VerticalLine visible={index === data.length - 1} />
          </S.StepNumberContainer>
          <S.TextContainer>
            <S.ParagraphSection>{value.text}</S.ParagraphSection>
          </S.TextContainer>
        </S.Container>
      ))}
    </div>
  );
};

export default ListStepNumber;
