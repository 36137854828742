import React from 'react';
import * as S from './styles';

const DownloadStore = ({ children, iconSrc, ...rest }) => {
  return (
    <S.StyledButton {...rest}>
      {iconSrc && (
        <S.IconDownload
          data-testid="link-download"
          src={iconSrc}
          alt="Icone da loja para fazer o download"
        />
      )}
      {children}
    </S.StyledButton>
  );
};

export default React.memo(DownloadStore);
