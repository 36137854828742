import styled from 'styled-components';
import { IconChevronRight as _IconChevronRight } from '@tabler/icons-react';

export const Wrapper = styled.div`
  width: 100%;
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
`;
export const Container = styled.div`
  padding: 32px 16px;
`;

export const Title = styled.h2`
  font-size: 1.5rem !important;
  font-weight: 500;
  color: #221f20;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: #646464;
`;

export const InstallmentsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 1rem;
`;

export const Button = styled.button`
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: none;
  padding: 12px 12px 12px 16px;
  width: 100%;

  svg {
    height: 16px;
    width: 16px;
    stroke: #221f20;
  }
`;

export const ButtonText = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: #221f20;
  text-align: left;
`;

export const ButtonSubtext = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  color: #959d9d;
  text-align: left;
`;

export const IconChevronRight = styled(_IconChevronRight)``;
