import styled from 'styled-components';
import * as Common from '../../../styles';

export const Container = styled(Common.Container)`
  padding-top: 64px;
  padding-bottom: 64px;
`;

export const GrayWrapper = styled(Common.Wrapper)`
  background-color: #f5f5f5;
`;

export const Title = styled(Common.Title)`
  margin-bottom: 32px;
`;

export const List = styled.ol`
  all: unset;
  display: flex;
  gap: 40px;
  flex-direction: column;
  margin-bottom: 48px;
`;

export const Counter = styled.span`
  flex: 0 0 auto;
  background-color: #000000;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  color: #fff;
  z-index: 11;
  font-size: 1rem;
  line-height: 1;

  svg {
    stroke: #fff;
    stroke-width: 1.5px;
    width: 24px;
    height: 24px;
  }
`;

export const VerticalLine = styled.hr`
  position: absolute;
  display: block;
  bottom: 40px;
  left: 19px;
  width: 2px;
  height: 60px;
  background-color: #000000;
  margin: auto;
  z-index: 1;
`;

export const ListItem = styled.li`
  all: unset;
  color: #646464;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &:last-of-type {
    ${Counter} {
      background-color: #e4602f;
    }

    ${VerticalLine} {
      background-color: #e4602f;
    }
  }
`;
