import React, { useEffect } from 'react';
import moment from 'moment';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import format from '~/services/format';
import useUnifiedBillet from '../../hooks/useUnifiedBillet';
import useInsiderQueue from '~/hooks/useInsiderQueue';
import QRCode from '../../components/QRCode';
import ButtonCopy from '../../components/ButtonCopy';
import Important from '../../components/Important';
import {
  getIsFullPayment,
  trackingOptionsOverdue,
  getSelectedInstallmentData,
} from '~/pages/PaymentChannel/helpers';
import { trackingSelectContent, trackingViewContent } from '~/analytics';
import { contentPage, contentType, flow } from '~/analytics/PaymentChannel';
import * as S from './styles';
import { isEmpty } from 'lodash';

const GeneratedPayment = () => {
  const { paymentChannelData } = usePaymentChannelContext();
  const { uuid, methodSelected } = paymentChannelData;
  const { insiderQueueCustom } = useInsiderQueue();

  const { data: billet } = useUnifiedBillet({ uuid });

  const isFullPayment = getIsFullPayment(paymentChannelData);
  const isPix = methodSelected === 'PIX';

  const getTrackingContentPage = () => {
    let cp;
    if (isFullPayment && !isPix) cp = contentPage.generatedPaymentFullBillet;
    if (isFullPayment && isPix) cp = contentPage.generatedPaymentFullPix;
    if (!isFullPayment && !isPix)
      cp = contentPage.generatedInstallmentPaymentBillet;
    if (!isFullPayment && isPix)
      cp = contentPage.generatedInstallmentPaymentPix;

    return `${cp}:${trackingOptionsOverdue(paymentChannelData)}`;
  };

  const handleTracking = () => {
    trackingSelectContent(
      isPix ? contentType.copyPix : contentType.copyBillet,
      flow.cards,
      getTrackingContentPage(),
      {
        contrato: paymentChannelData?.selected,
      }
    );
  };

  useEffect(() => {
    trackingViewContent(getTrackingContentPage(), flow.cards, {
      contrato: paymentChannelData?.selected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(billet))
      insiderQueueCustom('finalizou_pgr', {
        vencimento: moment(billet?.dueDate).format('DD/MM/YYYY'),
        valor_a_pagar: format.currency(billet?.amount),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billet]);

  let selectedInstallmentData = {};
  if (!isFullPayment)
    selectedInstallmentData = getSelectedInstallmentData(paymentChannelData);

  const getTitle = () => {
    const prefix = isFullPayment
      ? isPix
        ? 'Acordo'
        : 'Boleto'
      : 'Parcelamento';
    return `${prefix} gerado com sucesso!`;
  };

  return (
    <S.Wrapper>
      <S.ContainerContent>
        <S.Title>{getTitle()}</S.Title>

        <S.ContainerResume>
          <S.Flex direction="row">
            <S.Flex alignItems="flex-start">
              <span>Vencimento</span>
              <S.Span size={20} weight="bold">
                {moment(billet?.dueDate).format('DD/MM/YYYY')}
              </S.Span>
            </S.Flex>
            <S.Flex alignItems="flex-end">
              <span>Valor a pagar</span>
              <S.Span size={20} weight="bold">
                {format.currency(billet?.amount)}
              </S.Span>
            </S.Flex>
          </S.Flex>
          {!isFullPayment && (
            <S.Flex>
              <span>Proximas parcelas</span>
              <S.Span weight="bold">
                {selectedInstallmentData?.installmentQuantity}x de{' '}
                {format.currency(selectedInstallmentData?.installmentAmount)}
              </S.Span>
            </S.Flex>
          )}
        </S.ContainerResume>

        {isPix ? (
          <QRCode value={billet?.pixCopyAndPaste} />
        ) : (
          <S.BilletContainer>
            <S.Flex alignItems="center">
              <S.Span weight="500" size={18}>
                Utilize o código abaixo para realizar o pagamento:
              </S.Span>
              <S.Span color="#252525" space weight="400" size={18} top={16}>
                {billet?.formatTypeableLine}
              </S.Span>
            </S.Flex>
          </S.BilletContainer>
        )}

        {isPix && (
          <S.MessagePix>
            O acordo só é efetivado após o pagamento do valor negociado
          </S.MessagePix>
        )}

        <S.ContainerBottom alignItems="center">
          <ButtonCopy
            isPix={isPix}
            toCopy={isPix ? billet?.pixCopyAndPaste : billet?.typeableLine}
            onClick={handleTracking}
          />
        </S.ContainerBottom>

        <Important />
      </S.ContainerContent>
    </S.Wrapper>
  );
};

export default GeneratedPayment;
