import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const EqualWeb = () => {
  const { toogles } = useSelector(state => state.featuretoggle);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    if (toogles?.isEnableEqualWeb) {
      window.interdeal = {
        sitekey: '68583cd9ac370d18472f8b0e0403ff27',
        Position: 'right',
        domains: {
          js: 'https://cdn.equalweb.com/',
          acc: 'https://access.equalweb.com/',
        },
        Menulang: 'PT',
        btnStyle: {
          vPosition: isMobile ? ['80%', '20%'] : ['20%', '80%'],
          scale: ['0.5', '0.5'],
          color: {
            main: '#1f2b2a',
            second: '#ffffff',
          },
          icon: {
            outline: false,
            type: 1,
            shape: 'circle',
          },
        },
      };
      (function (doc, head, body) {
        var coreCall = doc.createElement('script');
        coreCall.src =
          window.interdeal.domains.js + 'core/4.6.3/accessibility.js';
        coreCall.defer = true;
        coreCall.integrity =
          'sha512-+5lbZsIsOqyfEswqMIHyOrR4jrrBUQ0aVv0KYYoZ6/jTkKsTIqAHwkHgFEoRDM3NbjZ0lOxR0qSslbA3NIXrfw==';
        coreCall.crossOrigin = 'anonymous';
        coreCall.setAttribute('data-cfasync', true);
        body ? body.appendChild(coreCall) : head.appendChild(coreCall);
      })(document, document.head, document.body);
    }
  }, [toogles?.isEnableEqualWeb]);
  return null;
};

export default EqualWeb;
