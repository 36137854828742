import styled from 'styled-components';
import * as Common from '../../../styles';

export const Wrapper = styled(Common.Wrapper)`
  background-color: #f5f5f5;
`;

export const Title = styled(Common.Title)`
  font-size: 2rem !important;
`;

export const Container = styled(Common.Container)`
  padding-top: 64px;
  padding-bottom: 64px;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const CategoryContainer = styled.div``;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const CategoryTitle = styled.h3`
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 1.5;
  color: #646464;
`;
