import styled from 'styled-components';
import * as Common from '../styles';

export const Container = styled(Common.Container)`
  @media screen and (min-width: 900px) {
    padding: 64px 132px;
  }
  @media screen and (min-width: 1176px) {
    padding: 64px 0;
  }
`;

export const Title = styled(Common.Title)``;

export const Subtitle = styled.h4`
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  line-height: 1.25 !important;
  color: #221f20;
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-area: subtitle;

  svg {
    flex: 0 0 auto;
    height: 24px;
    width: 24px;
    stroke: #221f20;
    margin-right: 24px;
  }

  @media screen and (min-width: 1176px) {
    svg {
      margin-right: 16px;
    }
  }
`;

export const Text = styled(Common.Text)``;

export const IntroContainer = styled.div``;

export const GrayContainer = styled(Common.Container)`
  background-color: #f7f7f7;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 260px);
  grid-template-rows: auto;
  grid-template-areas:
    'image image'
    'subtitle subtitle'
    'list list';

  &:last-of-type {
    margin-bottom: 72px;
  }

  @media screen and (min-width: 1176px) {
    padding: 32px;
    grid-template-areas:
      'subtitle image'
      'list image';
    margin-bottom: 32px;
    border-radius: 8px;

    &:last-of-type {
      margin-bottom: 144px;
    }
  }
`;

export const ImageContainer = styled.div`
  height: 405px;
  position: relative;
  margin-bottom: 40px;
  grid-area: image;
  margin-right: -16px;

  @media screen and (min-width: 480px) {
    margin-right: -32px;
  }
  @media screen and (min-width: 1176px) {
    margin-top: -32px;
    margin-bottom: -32px;
    margin-right: -32px;
    height: 464px;
  }
`;

export const OrangeDetail = styled.div`
  position: absolute;
  width: 154px;
  height: 405px;
  right: 0;
  top: 0;
  background: #e4602f;
  border-radius: 102.663px 7.93529px 7.93529px 102.663px;
  z-index: 3;

  @media screen and (min-width: 1176px) {
    left: auto;
    width: 207px;
    height: 464px;
    border-radius: 103.5px 8px 8px 103.5px;
  }
`;

export const HowToAskImage = styled.img`
  position: absolute;
  width: 198px;
  object-fit: contain;
  top: 12px;
  right: 72px;
  border-radius: 10px 10px;
  z-index: 4;

  @media screen and (min-width: 1176px) {
    left: 4px;
    width: 202px;
    top: 40px;
    border-radius: 16px;
  }
`;

export const Button = styled(Common.Button)`
  color: #fff;
  background-color: #221f20;
  font-weight: 500 !important;
`;

export const ListContainer = styled.div`
  grid-area: list;
`;

export const List = styled.ol`
  all: unset;
  display: flex;
  gap: 40px;
  flex-direction: column;

  @media screen and (min-width: 1176px) {
    margin-bottom: 0;
  }
`;

export const Counter = styled.span`
  flex: 0 0 auto;
  background-color: #221f20;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
  color: white;
  z-index: 11;

  svg {
    stroke: #fff;
    width: 24px;
    height: 24px;
    stroke-width: 1.5px;
  }
`;

export const VerticalLine = styled.hr`
  position: absolute;
  display: block;
  bottom: 50px;
  left: 19px;
  width: 2px;
  height: 80px;
  background-color: #221f20;
  margin: auto;
  z-index: 1;

  @media screen and (min-width: 1176px) {
    bottom: 30px;
  }
`;

export const ListItem = styled.li`
  all: unset;
  color: #1f2b2a;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-height: 60px;

  &:first-of-type {
    align-items: flex-start;
    min-height: 130px;

    ${Button} {
      margin-top: 32px;
    }
  }

  &:nth-of-type(2) {
    ${VerticalLine} {
      height: 170px;
    }
  }

  &:last-of-type {
    align-items: flex-start;

    ${Counter} {
      background-color: #e4602f;
    }

    ${VerticalLine} {
      background-color: #e4602f;
    }
  }

  @media screen and (min-width: 1176px) {
    min-height: 40px;

    &:first-of-type {
      align-items: flex-start;
      min-height: 96px;

      div {
        padding-top: 4px;
      }
    }

    &:nth-of-type(2) {
      ${VerticalLine} {
        height: 120px;
      }
    }

    &:last-of-type {
      align-items: center;
    }
  }
`;
