import * as S from './styles';
import { Icon } from '@midway/web-ui-component';
import { gtagSelectContent } from '~/utils/TaggingGA4';
import { flow, contentPage, contentType } from '~/analytics/PaymentChannel';

const tagGa4 = (content_type, params) => {
  gtagSelectContent(contentPage.home, flow.default, content_type, params);
};

export default function SectionOthersChannels() {
  const negotiationChannels = [
    {
      title: 'WhatsApp Riachuelo',
      description: 'Clique aqui e negocie direto pelo nosso WhatsApp.',
      link: { text: 'aqui', url: 'https://wa.me/message/SPKGTORDWBOAH1' },
      icon: ['mid', 'smartphone'],
      tag: () => tagGa4(contentType.whatsappRchlo),
    },
    {
      title: 'Central de Negociação',
      description: 'Capitais e regiões metropolitanas',
      phones: [
        { number: '3004-5417', href: 'tel:+3004-5417' },
        {
          number: '0800-727-4417',
          href: 'tel:+0800-727-4417',
          extra: 'demais localidades',
        },
      ],
      icon: ['fas', 'phone-volume'],
      tag: () => tagGa4(contentType.centralPhone),
    },
    {
      title: 'Lojas Riachuelo',
      description:
        'Você também pode negociar presencialmente em qualquer loja Riachuelo.',
    },
    {
      title: 'Assessorias de cobrança',
      description: 'Clique aqui e conheça nossas assessorias parceiras',
      link: {
        text: 'aqui',
        url: 'https://blog.riachuelo.com.br/promocoes-e-regulamentos',
      },
      icon: ['fa', 'headset'],
      tag: () => tagGa4(contentType.blog),
    },
  ];

  return (
    <S.Wrapper>
      <S.Title>Outros canais de negociação</S.Title>
      <S.Container>
        {negotiationChannels.map(
          ({ title, description, link, phones, icon, tag }, index) => (
            <S.Card key={index}>
              <S.IconContainer>
                {icon ? <Icon icon={icon} color="white" /> : <S.IconRchlo />}
              </S.IconContainer>
              <S.Content>
                <S.Title>{title}</S.Title>
                <S.Description>
                  {description}{' '}
                  {link && (
                    <S.Link
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => tag && tag()}
                    >
                      {link.text}
                    </S.Link>
                  )}
                </S.Description>
                {phones &&
                  phones.map(({ number, href, extra }, i) => (
                    <S.Description key={i}>
                      <S.Phone onClick={() => tag && tag()} href={href}>
                        {number}
                      </S.Phone>{' '}
                      {extra}
                    </S.Description>
                  ))}
              </S.Content>
            </S.Card>
          )
        )}
      </S.Container>
    </S.Wrapper>
  );
}
