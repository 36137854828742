import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  z-index: 100000;

  .loader {
    color: #000 !important;
  }
`;

export const Message = styled.span`
  font-weight: bold;
  font-size: 16px;
`;
