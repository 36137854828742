export const mockData = [
  [
    {
      id: 1,
      children: [
        {
          description: 'Condições Gerais Seguro Desemprego PL',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/1-condicoes-gerais-seguro-desemprego-rsa-sura',
        },
        {
          description:
            'Resumo de Condições Gerais Seguro Desemprego (Comercializado até 12/2014)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/2-resumo-cg-desemprego-premiavel-comercializado-ate-dez-2014',
        },
        {
          description:
            'Resumo de Condições Gerais Seguro Desemprego (Comercializado até 11/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/3-resumo-cg-seguro-desemprego-sura-ate-05-11-2017',
        },
        {
          description:
            'Resumo de Condições Gerais Seguro Desemprego (Comercializado até 09/2019)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/4-resumo-cg-desemprego-premiavel-ate-2019',
        },
        {
          description: 'Condições Gerais Seguro Desemprego Bandeira ACE',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/5-condicoes-gerais-integra-desemprego-ace-bandeira',
        },
        {
          description: 'Condições Gerais Seguro Desemprego Bandeira Zurich',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/6-condicoes-gerais-na-integra-seguro-desemprego-zurich-cartoes-visa-e-mastercard',
        },
        {
          description:
            'Resumo de Condições Gerais Seguro Desemprego Cartões Visa e Master',
          date: '(Comercializado até 04/2015)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/7-resumo-condicoes-gerais-seguro-desemprego-bandeira-2015',
        },
        {
          description:
            'Resumo de Condições Gerais Seguro Desemprego Cartões Visa e Master',
          date: '(Comercializado até 10/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/8-resumo-condicoes-gerais-seguro-desemprego-bandeira-ate-2017',
        },
        {
          description:
            'Resumo de Condições Gerais Seguro Desemprego Cartões Visa e Master',
          date: '(Comercializado até 09/2019)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/9-resumo-do-seguro-desemprego-premiavel-visa-e-master-ate-2019',
        },
        {
          description: 'Condições Gerais Seguro Perda e Roubo',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/10-condicoes-gerais-na-integra-perda-roubo-ou-furto-de-cartao-zurich',
        },
        {
          description:
            'Resumo de Condições Gerais Proteção Perda e Roubo ou Furto dos Cartões',
          date: 'Riachuelo Visa e Mastercard (Comercializados até 10/2013)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/11-resumo-cg-protecao-perda-e-roubo-ate-2013',
        },
        {
          description:
            'Resumo de Condições Gerais Proteção Perda e Roubo ou Furto dos Cartões Riachuelo',
          date: 'Visa e Mastercard (Comercializado até 10/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/resumo-cg-protecao-perda-e-roubo-ate-2017',
        },
        {
          description: 'Condições gerais do Seguro Conta Paga',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/12-15414.900031.2019.96',
        },
        {
          description:
            'Resumo do Seguro Conta Paga - Plano 1 (comercializados até 01/2021)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/13-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-1',
        },
        {
          description:
            'Resumo do Seguro Conta Paga - Plano 2 (comercializados até 01/2021)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/14-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-2',
        },
        {
          description:
            'Resumo do Seguro Conta Paga - Plano 3 (comercializados até 01/2021)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/15-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-3',
        },
        {
          description:
            'Resumo de Condições Gerais Bolsa Protegida (comercializada a partir de 11/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/CONDICOES-GERAIS-PROTECAO-DE-PORTATEIS%E2%80%93COLETIVO',
        },
        {
          description:
            'Resumo de Condições Gerais Bolsa Protegida – Assistência à vitimas de crimes',
          date: '(comercializada até 11/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Resumo-Condicoes-Gerais-BolsaProtegida-AssistenciaVitimasDeCrimes-ate-11-2017',
        },
        {
          description:
            'Resumo de Condições Gerais Bolsa Protegida – Assistência à vitimas de crimes',
          date: '(comercializada a partir de 11/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Resumo-Condicoes-Gerais-BolsaProtegida-AssistenciaVitimasDeCrimes-atual',
        },
        {
          description:
            'Resumo de Condições Gerais Bolsa Protegida vitimas de crimes',
          date: '(comercializada a partir de 11/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Resumo-Condicoes-Gerais-Bolsa_Protegida',
        },
        {
          description:
            'Resumo de Condições Gerais Perda e Roubo (não mais comercializado)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Resumo-Condicoes-Gerais-Perda-e-Roubo',
        },
        {
          description: 'Condições Gerais na Íntegra Bolsa Protegida',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Condicoes-Gerais-na-Integra-Bolsa-Protegida',
        },
        {
          description:
            'Condições Gerais na Íntegra Perda e Roubo (não mais comercializado)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Condicoes-Gerais-na-Integra-Perda-e-Roubo',
        },
        {
          description: 'Seguro Residencial Condições Gerais ',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Condicoes-gerais-residencial-integra',
        },
        {
          description: 'Resumo de Condições Gerais Seguro Residencial',
          date: '(Comercializado até 11/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Resumo-seguro-residencial-ate-2017',
        },
        {
          description: 'Resumo de Condições Gerais Seguro Residencial',
          date: '(Comercializado até 10/2021)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Resumo-seguro-residencial-atual-2020',
        },
        {
          description:
            'Assistência Residencial - Resumo de Condições Assistênci',
          date: '(comercializado até 08/2019)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Resumo-CondicoesGerais-Assistencia-Residencial',
        },
        {
          description:
            'Assistência Residencial - Seguro de Pessoas Coletivo – Acidentes Pessoais',
          date: '(comercializado até 08/2019)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/RESUMO-CONDICOES-ESPECIFICAS-DO-SEGURO-DE-PESSOAS-COLETIVOS-ASS.24HS-RESIDENCIA3',
        },
        {
          description:
            'Assistência Veículo 24h - Condições gerais Assistência Veículo 24H',
          date: '(comercializado até 08/2019)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Auto_Resumo-Condicoes-Gerais-Assistencia-Veiculo',
        },
        {
          description:
            'Assistência Veículo 24h - Seguro de Pessoas Coletivos – Acidentes Pessoais',
          date: '(comercializado até 08/2019)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Auto_A_RESUMO-CONDICOES-24HS-VEICULOS1',
        },
        {
          description: 'Resumo das condições gerais do Seguro Celular AXA',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Resumo-das-condicoes-gerais-seguro-celular',
        },
        {
          description: 'Condições gerais na íntegra do Seguro Celular AXA',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Condicoes-Integras-Seguro-Celular',
        },
        {
          description: 'Termos e condições do plano Topázio G2 - Odontoprev',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/static/documents/tags/contratos/condicoes-gerais-assistencia-odontologica_plano-topazio.pdf',
        },
        {
          description: 'Termos e condições do plano Diamond G2 - Odontoprev',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/static/documents/tags/contratos/condicoes-gerais-assistencia-odontologica_plano-diamond.pdf',
        },
        {
          description: 'Resumo das condições gerais do Seguro Conta Paga',
          date: '(comercializado até 09/2023)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/resumo-das-condicoes-gerais-novo-seguro-conta-paga.pdf',
        },
        {
          description:
            'Condições gerais na íntegra Seguro Acidentes Pessoais - Mapfre',
          date: '(comercializado até 10/2024)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/CONDICOES-GERAIS-NA-INTEGRA_Seguro-Acidentes-PessoaisColetivo',
        },
        {
          description:
            'Resumo das condições gerais do Seguro Acidentes Pessoais',
          date: '(comercializado até 10/2017)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/CGS-RESUMO-SEGURO-ACIDENTES-PESSOAIS-ATE-2017',
        },
        {
          description:
            'Resumo das condições gerais do Seguro Acidentes Pessoais',
          date: '(comercializado até 10/2017 - renovação após 11/22)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/CGS-RESUMO-SEGURO-ACIDENTES-PESSOAIS-RENOVACOES',
        },
        {
          description:
            'Resumo das condições gerais do Seguro Acidentes Pessoais',
          date: '(comercializado de 11/2017 até 10/2024)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/CGS-RESUMO-SEGURO-ACIDENTES-PESSOAIS-APOS-OUTUBRO-2017',
        },
        {
          description:
            'Condições gerais na íntegra do Seguro Residencial Casa Protegida',
          date: '(comercializado até 10/2024)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/condi%C3%A7%C3%A3o-geral-Resid%C3%AAncial',
        },
        {
          description:
            'Resumo das condições gerais do Seguro Residencial Casa Protegida',
          date: '(comercializado até 10/2024)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/resumo-de-condi%C3%A7%C3%A3o-geral',
        },
        {
          description:
            'Condições gerais da assistência descarte ecológico - Casa Protegida',
          date: '(comercializado até 10/2024)',
          view: 'Visualizar',
          link: 'https://www.midway.com.br/contrato/Condi%C3%A7%C3%B5es-Gerais-da-Assist%C3%AAncia-descarte-Ecol%C3%B3gico',
        },
      ],
    },
  ],
];
