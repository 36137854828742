import React from 'react';
import { Link } from 'react-router-dom';
import { trackingSelectContent } from '~/analytics';

export const MenuCartoes = () => {
  return (
    <ul className="submenu" data-header="Cartões" tabIndex={0}>
      <li>
        <Link
          onClick={() =>
            trackingSelectContent(
              'header:cartoes:conheca-nossos-cartoes',
              'home'
            )
          }
          id="ico-cards"
          className="MenuLinkMobile"
          to="/cartoes"
          tabIndex={0}
        >
          Conheça nossos cartões
        </Link>
      </li>
      <li>
        <Link
          onClick={() =>
            trackingSelectContent(
              'header:cartoes:cartao-richuelo-mastercard',
              'home'
            )
          }
          id="ico-master"
          className="MenuLinkMobile"
          to="/cartao-de-credito"
        >
          Cartão Riachuelo Mastercard
        </Link>
      </li>
      <li>
        <Link
          onClick={() =>
            trackingSelectContent(
              'header:cartoes:cartao-da-loja-riachuelo',
              'home'
            )
          }
          id="ico-rchlo"
          className="MenuLinkMobile"
          to="/cartao-rchlo"
        >
          Cartão da loja Riachuelo
        </Link>
      </li>
      <li>
        <Link
          onClick={() =>
            trackingSelectContent('header:cartoes:limite-garantido', 'home')
          }
          id="ico-single-card"
          className="MenuLinkMobile"
          to="/limite-garantido"
        >
          Limite Garantido
        </Link>
      </li>
      <li>
        <Link
          onClick={() =>
            trackingSelectContent('header:cartoes:parcerias', 'home')
          }
          id="ico-ticket"
          className="MenuLinkMobile"
          to="/beneficios"
        >
          Benefícios
        </Link>
      </li>
    </ul>
  );
};

export const MenuEmprestimos = () => (
  <ul className="submenu" data-header="Empréstimos" tabIndex={0}>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent('header:emprestimos:emprestimo-pessoal', 'home')
        }
        id="ico-cash"
        className="MenuLinkMobile"
        to="emprestimos"
      >
        Empréstimo pessoal
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:emprestimos:antecipacao-saque-aniversario-fgtsl',
            'home'
          )
        }
        id="ico-money"
        className="MenuLinkMobile"
        to="/antecipacao-saque-fgts"
      >
        Antecipação Saque-Aniversário FGTS
      </Link>
    </li>
  </ul>
);

export const MenuContaDigital = () => {
  const tabIndex = 0;
  return (
    <ul className="submenu" data-header="Conta Digital">
      <li tabIndex={tabIndex}>
        <Link
          id="ico-trending-up"
          className="MenuLinkMobile"
          to="/conta-digital"
        >
          Conta digital Midway
        </Link>
      </li>
    </ul>
  );
};

export const MenuNegociacaoDeDividas = () => {
  return (
    <ul className="submenu" data-header="Conta Digital">
      <li tabIndex={0}>
        <Link
          id="ico-trending-up"
          className="MenuLinkMobile"
          to="/conta-digital"
        >
          Conta digital Midway
        </Link>
      </li>
    </ul>
  );
};

export const MenuSeguros = () => (
  <ul className="submenu" data-header="Seguros e assistências" tabIndex={0}>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:segredos-e-assistencias:todos-os-produtos',
            'home'
          )
        }
        id="ico-plus"
        className="MenuLinkMobile"
        to="/produtos-financeiros"
      >
        Todos os produtos
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:segredos-e-assistencias:seguros',
            'home'
          )
        }
        id="ico-shield"
        className="MenuLinkMobile"
        to="/seguros"
      >
        Seguros
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:segredos-e-assistencias:plano-odontologico',
            'home'
          )
        }
        id="ico-dental"
        className="MenuLinkMobile"
        to="/plano-odontologico"
      >
        Plano Odontológico
      </Link>
    </li>
  </ul>
);
