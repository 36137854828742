import React from 'react';
import * as S from './styles';

const SectionPayOffDebts = ({ onClickButton }) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>Quite débitos com até 90% de desconto e em até 21x</S.Title>
        <S.Text>
          Limpe seu o nome já no pagamento da 1ª parcela. Tudo on-line, sem sair
          de casa!
        </S.Text>
        <S.Button onClick={onClickButton}>Negociar dívida</S.Button>
      </S.Container>
    </S.Wrapper>
  );
};

export default SectionPayOffDebts;
