import styled from 'styled-components';
import { ContentContainer, ContentTitle } from '~/pages/PaymentChannel/styles';

export const Wrapper = styled.div`
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  background-color: #f5f5f5;
`;

export const ContainerContent = styled(ContentContainer)`
  position: relative;
  padding-top: 32px;
`;

export const Title = styled(ContentTitle)`
  margin-bottom: 32px;
`;

export const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 2rem;
  }
`;

export const MessagePix = styled.div`
  color: #646464;
  margin: 1rem auto;
  text-align: center;
`;

export const BilletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  text-align: center;
`;

export const ContainerBottom = styled.div`
  width: 90%;
  bottom: 1rem;
  margin: 0 auto;
  text-align: center;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  align-items: ${({ alignItems }) => alignItems};
  text-align: ${({ textAlign }) => textAlign};
  justify-content: space-between;
  margin-top: ${({ top }) => top}px;

  svg {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
  }
`;

export const ContainerResume = styled.div`
  background: #ffffff;
  padding: 1rem;
  margin: 2rem 0;
  border-radius: 8px;
`;

export const Span = styled.span`
  font-size: ${({ size }) => (size ? size : 16)}px;
  font-weight: ${({ weight }) => weight};
  text-decoration: ${({ line }) => line && 'line-through'};
  color: ${({ color }) => color};
  margin-top: ${({ top }) => top}px;
  letter-spacing: ${({ space }) => space && 1}px;
`;
