import IconFatura from '~/assets/img/Footer/icon-credit-card.svg';
import IconCentralDeAtendimento from '~/assets/img/Footer/icon-headset.svg';
import IconTrabalheConosco from '~/assets/img/Footer/icon-users-group.svg';
import { trackingSelectContent } from '~/analytics';
import IconLojas from '../../assets/img/Footer/mids-store.svg';
import Icons from '../SocialIcons/icons';
import { contentType, flow } from '../../analytics/home';

const menuItems = [
  {
    id: 'midway',
    title: 'Midway',
    items: [
      {
        id: 1,
        title: 'Fatura </br>e Extrato',
        icon: IconFatura,
        ariaLabel: 'Fatura e Extrato',
        link: '/consulta-boleto',
        target: '_self',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerInvoiceAndStatement,
            flow.home
          );
        },
      },
      {
        id: 3,
        title: 'Trabalhe </br>conosco',
        icon: IconTrabalheConosco,
        ariaLabel: 'Trabalhe conosco',
        link: '/faca-parte#work-with-us',
        target: '_self',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(contentType.footerWorkWithUs, flow.home);
        },
      },
      {
        id: 2,
        title: 'Central de atendimento',
        icon: IconCentralDeAtendimento,
        ariaLabel: 'Central de atendimento',
        link: '/atendimento',
        target: '_self',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(contentType.footerCallCenter, flow.home);
        },
      },

      {
        id: 4,
        title: 'Encontre a loja mais próxima',
        icon: IconLojas,
        ariaLabel: 'Encontre a loja mais próxima',
        link: '/buscar-lojas',
        target: '_self',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerFindTheNearestStore,
            flow.home
          );
        },
      },
    ],
  },
  {
    id: 'siga-a-midway',
    title: 'Siga a Midway',
    items: [
      {
        id: 1,
        title: 'Instagram',
        link: 'https://www.instagram.com/midway.digital/',
        Icon: Icons.Instagram,
        ariaLabel: 'Instagram',
        target: '_blank',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(contentType.footerInstagram, flow.home);
        },
      },
      {
        id: 2,
        title: 'Facebook',
        link: 'https://www.facebook.com/midwayfinanceira/',
        Icon: Icons.Facebook,
        ariaLabel: 'Facebook',
        target: '_blank',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(contentType.footerFacebook, flow.home);
        },
      },

      {
        id: 3,
        title: 'LinkedIn',
        link: 'https://www.linkedin.com/company/midwaydigital/',
        Icon: Icons.Linkedin,
        ariaLabel: 'LinkedIn',
        target: '_blank',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(contentType.footerLinkedin, flow.home);
        },
      },
    ],
  },
  {
    id: 'confira-tambem',
    title: 'Confira também',
    items: [
      {
        id: 1,
        title: 'Taxas e tarifas Cartão Private Label',
        link: '/tarifas-rchlo',
        ariaLabel: 'Taxas e tarifas Cartão Private Label',
        lang: 'en',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerPrivateLabelCardRatesAndFees,
            flow.home
          );
        },
      },
      {
        id: 2,
        title: 'Taxas e tarifas Visa e Mastercard',
        link: '/tarifas-cartoes',
        ariaLabel: 'Taxas e tarifas Visa e Mastercard',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerVisaAndMasterCardFeesAndCharges,
            flow.home
          );
        },
      },
      {
        id: 3,
        title: 'Tabela de serviços da Conta Digital Midway',
        link: '/tabela-de-tarifas-conta',
        ariaLabel: 'Tabela de serviços da Conta Digital Midway',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerMidwayDigitalAccountServiceTable,
            flow.home
          );
        },
      },
      {
        id: 4,
        title: 'Política Corporativa de Segurança Cibernética',
        link: '/contrato/POLITICA_DE_SEGURANCA_DA_INFORMACAO',
        ariaLabel: 'Politica Corporativa de Segurança Cibernética',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerCorporateCyberSecurityPolicy,
            flow.home
          );
        },
      },
      {
        id: 5,
        title: 'Política de Responsabilidade Socioambiental',
        link: '/contrato/Responsabilidade_Socioambiental_Midway',
        ariaLabel: 'Política de Responsabilidade Socioambiental',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerSocioEnvironmentalResponsibilityPolicy,
            flow.home
          );
        },
      },
      {
        id: 6,
        title: 'Demonstrações financeiras',
        link: '/demonstracoes-financeiras',
        ariaLabel: 'Demonstrações Financeiras',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerFinancialStatements,
            flow.home
          );
        },
      },
      {
        id: 7,
        title: 'Preferências de cookies',
        link: 'javascript:;',
        ariaLabel: 'Preferências de cookies',
        target: '_self',
        rel: '',
        onClickFn: () => {
          window.showConsentPreferencesPopup();
          trackingSelectContent(contentType.footerDocuments, flow.home);
        },
      },
    ],
  },
  {
    id: 'sobre-a-midway',
    title: 'Sobre a Midway',
    items: [
      {
        id: 1,
        title: 'A empresa',
        link: '/midway',
        ariaLabel: 'A empresa',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(contentType.footerAboutMidway, flow.home);
        },
      },
      {
        id: 2,
        title: 'Documentos',
        link: '/documentos',
        ariaLabel: 'Documentos',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(contentType.footerDocuments, flow.home);
        },
      },
      {
        id: 3,
        title: 'Governança e Ética',
        link: '/governanca-e-etica',
        ariaLabel: 'Governança e ética',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerGovernanceAndEthics,
            flow.home
          );
        },
      },

      {
        id: 4,
        title: 'Gerenciamento de risco',
        link: '/gerenciamento-de-risco',
        ariaLabel: 'Gerenciamento de riscos',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(contentType.footerRiskManagement, flow.home);
        },
      },
      {
        id: 5,
        title: 'Portal de privacidade Guararapes',
        link: 'https://privacidade.grupoguararapes.com.br',
        ariaLabel: 'Portal de privacidade Guararapes',
        target: '_blank',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerGuararapesGroupPrivacyPortal,
            flow.home
          );
        },
      },
      {
        id: 6,
        title: 'Portal do desenvolvedor',
        link: 'https://developers.midway.com.br/',
        ariaLabel: 'Portal do desenvolvedor',
        target: '_blank',
        rel: 'noopener noreferrer',
        onClickFn: () => {
          trackingSelectContent(contentType.footerDeveloperPortal, flow.home);
        },
      },
      {
        id: 7,
        title: 'Educação Financeira',
        link: '/educacao-financeira',
        ariaLabel: 'Educação Financeira',
        target: '_self',
        rel: '',
        onClickFn: () => {
          trackingSelectContent(
            contentType.footerFinancialeducation,
            flow.home
          );
        },
      },
    ],
  },
];

const mobileItems = {
  id: 'veja-tambem',
  title: 'VEJA TAMBÉM',
  items: [
    {
      id: 1,
      title: 'Preferências de cookies',
      link: 'javascript:;',
      ariaLabel: 'Preferências de cookies',
      target: '_self',
      rel: '',
      onClickFn: () => {
        window.showConsentPreferencesPopup();
        trackingSelectContent(contentType.footerDocuments, flow.home);
      },
    },
    {
      id: 2,
      title: 'Sobre a Midway',
      link: '/midway',
      ariaLabel: 'Sobre a Midway',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(contentType.footerAboutMidway, flow.home);
      },
    },
    {
      id: 3,
      title: 'Governança e Ética',
      link: '/governanca-e-etica',
      ariaLabel: 'Governança e ética',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(contentType.footerGovernanceAndEthics, flow.home);
      },
    },
    {
      id: 4,
      title: 'Gerenciamento de riscos',
      link: '/gerenciamento-de-risco',
      ariaLabel: 'Gerenciamento de riscos',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(contentType.footerRiskManagement, flow.home);
      },
    },
    {
      id: 5,
      title: 'Portal de privacidade',
      link: 'https://privacidade.grupoguararapes.com.br',
      ariaLabel: 'Portal de privacidade Guararapes',
      target: '_blank',
      rel: 'noopener noreferrer',
      onClickFn: () => {
        trackingSelectContent(
          contentType.footerGuararapesGroupPrivacyPortal,
          flow.home
        );
      },
    },
    {
      id: 6,
      title: 'Portal do desenvolvedor',
      link: 'https://developers.midway.com.br/',
      ariaLabel: 'Portal do desenvolvedor',
      target: '_blank',
      rel: 'noopener noreferrer',
      onClickFn: () => {
        trackingSelectContent(contentType.footerDeveloperPortal, flow.home);
      },
    },
    {
      id: 7,
      title: 'Cartilha Financeira',
      link: '/contrato/cartilha_educacao_financeira',
      ariaLabel: 'Cartilha Financeira',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(contentType.footerFinancialeducation, flow.home);
      },
    },
    {
      id: 8,
      title: 'Taxas e tarifas Cartão Private Label',
      link: '/tarifas-rchlo',
      ariaLabel: 'Taxas e tarifas Cartão Private Label',
      lang: 'en',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(
          contentType.footerPrivateLabelCardRatesAndFees,
          flow.home
        );
      },
    },
    {
      id: 9,
      title: 'Taxas e tarifas Visa e Mastercard',
      link: '/tarifas-cartoes',
      ariaLabel: 'Taxas e tarifas Visa e Mastercard',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(
          contentType.footerVisaAndMasterCardFeesAndCharges,
          flow.home
        );
      },
    },
    {
      id: 10,
      title: 'Tabela de serviços da Conta Digital Midway',
      link: '/tabela-de-tarifas-conta',
      ariaLabel: 'Tabela de serviços da Conta Digital Midway',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(
          contentType.footerMidwayDigitalAccountServiceTable,
          flow.home
        );
      },
    },
    {
      id: 11,
      title: 'Politica Corporativa de Segurança Cibernética',
      link: '/contrato/POLITICA_DE_SEGURANCA_DA_INFORMACAO',
      ariaLabel: 'Politica Corportativa de Segurança Cibernética',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(
          contentType.footerCorporateCyberSecurityPolicy,
          flow.home
        );
      },
    },
    {
      id: 12,
      title: 'Política de Responsabilidade Socioambiental',
      link: '/contrato/Responsabilidade_Socioambiental_Midway',
      ariaLabel: 'Política de Responsabilidade Socioambiental',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(
          contentType.footerSocioEnvironmentalResponsibilityPolicy,
          flow.home
        );
      },
    },
    {
      id: 13,
      title: 'Demonstrações Financeiras',
      link: '/demonstracoes-financeiras',
      ariaLabel: 'Demonstrações Financeiras',
      target: '_self',
      rel: '',
      onClickFn: () => {
        trackingSelectContent(contentType.footerFinancialStatements, flow.home);
      },
    },
  ],
};

export default {
  col1: menuItems[0],
  col2: menuItems[1],
  col3: menuItems[2],
  col4: menuItems[3],
  mobileItems,
};
