import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import Accordion from '~/components/Accordion';
import { faqData } from './data';
import useIsMobile from '~/hooks/useIsMobile';
import * as S from './styles';

const Faq = () => {
  const [selectedSection, setSelectedSection] = useState('all');
  const [inputValue, setInputValue] = useState('');
  const isMobile = useIsMobile();
  const [data, setData] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const isSearching = inputValue.trim().length > 0;

  const handleGetData = useCallback(async () => {
    try {
      const response = await cmsService.getFaqLoan(
        process.env.REACT_APP_SITE_CMS_ENV
      );
      if (Array.isArray(response) && response.length > 0) {
        setData(response);
        setFilteredDocuments(response);
      } else {
        setData(faqData);
        setFilteredDocuments(faqData);
      }
    } catch (error) {
      console.error('Erro ao buscar FAQ:', error);
      setData(faqData);
      setFilteredDocuments(faqData);
    }
  }, []);

  const handleFilterDocuments = useCallback(() => {
    if (!Array.isArray(data)) return;

    const filtered = data
      .map(section => ({
        ...section,
        questions: section.questions.filter(
          question =>
            question.title.toLowerCase().includes(inputValue.toLowerCase()) ||
            question.content.toLowerCase().includes(inputValue.toLowerCase())
        ),
      }))
      .filter(section => section.questions.length > 0);

    setFilteredDocuments(filtered);
  }, [data, inputValue]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    handleFilterDocuments();
  }, [handleFilterDocuments]);

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.FilterContainer>
          <S.Input
            type="text"
            id="search"
            isMobile={isMobile}
            placeholder="Qual é a sua dúvida?"
            aria-label="Barra de pesquisa:"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />

          {!isSearching && (
            <S.FilterAccordion>
              {data.map(section => (
                <S.FilterContent key={section.id}>
                  <S.ButtonFilter
                    data-testid={section.title}
                    onClick={() =>
                      setSelectedSection(
                        selectedSection === section.title
                          ? 'all'
                          : section.title
                      )
                    }
                    style={{
                      cursor: 'pointer',
                      fontWeight:
                        selectedSection === section.title ? 'bold' : 'normal',
                    }}
                  >
                    {section.title}
                  </S.ButtonFilter>
                </S.FilterContent>
              ))}
              <S.ButtonFilter
                data-testid="Todos"
                onClick={() => setSelectedSection('all')}
              >
                Todos
              </S.ButtonFilter>
            </S.FilterAccordion>
          )}
        </S.FilterContainer>

        <S.AccordionContainer>
          {filteredDocuments.length === 0 ? (
            <S.AccordionNoResultsMessage>
              <S.NoResultsMessage>
                Desculpe, nenhuma FAQ encontrada para sua pesquisa.
              </S.NoResultsMessage>
            </S.AccordionNoResultsMessage>
          ) : (
            filteredDocuments
              .filter(
                section =>
                  selectedSection === 'all' || selectedSection === section.title
              )
              .map(section => (
                <S.Accordion key={section.id}>
                  {!isSearching && (
                    <S.TitleAccordion>{section.title}</S.TitleAccordion>
                  )}
                  <S.AccordionContent>
                    {section.questions.map(question => (
                      <S.AccordionWrapper key={question.id}>
                        <Accordion
                          title={question.title}
                          content={question.content}
                        />
                      </S.AccordionWrapper>
                    ))}
                  </S.AccordionContent>
                </S.Accordion>
              ))
          )}
        </S.AccordionContainer>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default Faq;
