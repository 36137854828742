import React from 'react';
import * as S from './styles';

const Modal = ({ children, onClose }) => {
  const handleOnClose = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <S.Container onClick={handleOnClose} data-testid="modal-container">
        <S.Box
          onClick={event => event.stopPropagation()}
          data-testid="modal-box"
        >
          <S.ButtonClose data-testid="close-modal-button" onClick={onClose}>
            x
          </S.ButtonClose>
          {children}
        </S.Box>
      </S.Container>
    </>
  );
};

export default React.memo(Modal);
