import React, { useEffect } from 'react';
import { keys } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button } from '@midway/web-ui-component';
import { IconBarcode, IconEdit } from '@tabler/icons-react';
import { Pix } from 'styled-icons/fa-brands';
import format from '~/services/format';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import usePaymentMethod from '../../hooks/usePaymentMethods';
import useRegister from '../../hooks/useRegister';
import {
  getSelectedElegibility,
  getSelectedInstallmentData,
  trackingOptionsOverdue,
} from '../../helpers';
import { trackingSelectContent, trackingViewContent } from '~/analytics';
import { contentPage, contentType, flow } from '~/analytics/PaymentChannel';
import useInsiderQueue from '~/hooks/useInsiderQueue';
import * as S from './styles';
import { screens } from '~/pages/PaymentChannel/routes';
import { errorTypes } from '~/pages/PaymentChannel/constants';

const ResumePayment = () => {
  const navigate = useHistory();
  const { paymentChannelData, updateData } = usePaymentChannelContext();
  const { typeSelected, elegibility, cardInfo } = paymentChannelData;
  const { insiderQueueCustom } = useInsiderQueue();

  const selected = elegibility[typeSelected];
  const { mutate, isSuccess: isRegisterSuccess } = useRegister();
  const isDebit = typeSelected === 'debit';
  const isAcquittance = typeSelected === 'acquittance';
  const isFullPayment = isDebit || isAcquittance;

  const isAgreement = typeSelected === 'agreement';
  const isInvoicement = typeSelected === 'invoicement';
  const isInstallmentPayment = isAgreement || isInvoicement;

  const trackingContentPage = isFullPayment
    ? contentPage.resumePaymentFull
    : contentPage.resumePaymentInstallments;

  const fullPaymentTotal =
    selected?.discount?.maxTotal > 0
      ? selected?.totalValue - selected?.discount?.maxTotal
      : selected?.totalValue;

  useEffect(() => {
    trackingViewContent(
      `${trackingContentPage}:${trackingOptionsOverdue(paymentChannelData)}`,
      flow.cards,
      {
        contrato: paymentChannelData?.selected,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRegisterSuccess) navigate.push(screens.GENERATED_PAYMENT);
  }, [isRegisterSuccess, navigate]);

  const elegibilityToken = type => {
    switch (type) {
      case 'debit':
        return elegibility[typeSelected]?.debitToken;
      case 'acquittance':
        return elegibility[typeSelected]?.acquittanceToken;
      case 'invoicement':
      case 'agreement':
        return paymentChannelData.simulation.simulationToken;
      default:
        return false;
    }
  };
  const { data: methods } = usePaymentMethod({
    token: elegibilityToken(typeSelected),
  });

  const editHandler = trackingContentType => {
    trackingSelectContent(
      trackingContentType,
      flow.cards,
      `${trackingContentPage}:${trackingOptionsOverdue(paymentChannelData)}`,
      {
        contrato: paymentChannelData?.selected,
      }
    );
    navigate.go(-1);
  };

  const entryEditHandler = () => {
    editHandler(contentType.resumePaymentEditEntry);
  };

  const installmentsEditHandler = () => {
    editHandler(contentType.resumePaymentEditInstallments);
  };

  const renderInstallmentPaymentDetails = () => {
    const selectedInstallmentData =
      getSelectedInstallmentData(paymentChannelData);
    const { simulation, selectedInstallment } = paymentChannelData;

    return (
      <>
        <S.ContainerResume>
          <S.TitleRow>
            <S.TitleMethod size={20}>Entrada</S.TitleMethod>
            <S.EditButton
              onClick={entryEditHandler}
              data-testid="editar-entrada"
              aria-label="Editar entrada"
            >
              <IconEdit />
            </S.EditButton>
          </S.TitleRow>
          <S.Row>
            <S.TextKey>Valor de entrada</S.TextKey>
            <S.TextValue>{format.currency(simulation.entryAmount)}</S.TextValue>
          </S.Row>
          <S.Row>
            <S.TextKey>Vencimento</S.TextKey>
            <S.TextValue>{format.date(simulation.entryDate)}</S.TextValue>
          </S.Row>
        </S.ContainerResume>
        <S.ContainerResume>
          <S.TitleRow>
            <S.TitleMethod size={20}>Parcelas</S.TitleMethod>
            <S.EditButton
              onClick={installmentsEditHandler}
              data-testid="editar-parcelas"
              aria-label="Editar parcelas"
            >
              <IconEdit />
            </S.EditButton>
          </S.TitleRow>
          <S.Row>
            <S.TextKey>Total</S.TextKey>
            <S.TextValue>
              {selectedInstallment}x de{' '}
              {format.currency(selectedInstallmentData.installmentAmount)}
            </S.TextValue>
          </S.Row>
          <S.Row>
            <S.TextKey>1º pagamento</S.TextKey>
            <S.TextValue>
              {format.date(selectedInstallmentData.firstReleaseDate)}
            </S.TextValue>
          </S.Row>

          <S.Separator />

          <S.TitleRow>
            <S.TitleMethod>Detalhamento</S.TitleMethod>
          </S.TitleRow>
          <S.Row>
            <S.TextKey>Juros por parcelamento</S.TextKey>
            <S.TextValue>
              {format.currency(
                selectedInstallmentData.composition.financialExpensesAmount
              )}
            </S.TextValue>
            <S.TextDescription>
              {format.percentage(
                selectedInstallmentData.composition.calculationBasis
                  .percentageRateMonth
              )}
              % ao mês
            </S.TextDescription>
          </S.Row>
          <S.Row>
            <S.TextKey>IOF</S.TextKey>
            <S.TextValue>
              {format.currency(selectedInstallmentData.composition.iofAmount)}
            </S.TextValue>
            <S.TextDescription>
              Imposto sobre operações financeiras
            </S.TextDescription>
          </S.Row>
        </S.ContainerResume>
      </>
    );
  };

  const paymentMethodsContent = {
    BILLET: {
      icon: <IconBarcode />,
      title: 'Boleto',
      description: 'Confirmado até 3 dias úteis',
      show: true,
      button: {
        title: 'Gerar Boleto',
        outline: true,
      },
      trackingContentType: 'gerar-boleto',
    },
    PIX: {
      icon: <Pix name="pix" />,
      title: 'Pix',
      description: 'Confirmado em instantes',
      show: true,
      button: {
        title: 'Gerar PIX',
      },
      trackingContentType: 'gerar-pix',
    },
    BILLET_AND_PIX: {
      icon: 'debit_account',
      title: 'Pagar com débito em conta',
      description:
        'O vencimento é no mesmo dia, e o pagamento é reconhecido em instantes',
      show: false,
      trackingContentType: 'gerar-debito-em-conta',
    },
  };

  const getInstallmentNumber = () => {
    if (isDebit) return 0;
    return paymentChannelData.selectedInstallment;
  };

  const handleConfirm = methodSelected => {
    const { simulation, selectedInstallment } = paymentChannelData;

    updateData('methodSelected', methodSelected);
    const discount = getSelectedElegibility(paymentChannelData)?.discount;

    trackingSelectContent(
      paymentMethodsContent[methodSelected]?.trackingContentType,
      flow.cards,
      `${trackingContentPage}:${trackingOptionsOverdue(paymentChannelData)}`,
      {
        contrato: paymentChannelData?.selected,
      }
    );

    let insiderParams = {
      tipo_pgto: methodSelected,
    };

    if (isInstallmentPayment) {
      const selectedInstallmentData =
        getSelectedInstallmentData(paymentChannelData);

      insiderParams = {
        ...insiderParams,
        valor_entrada: format.currency(simulation.entryAmount),
        qtd_parcelas: selectedInstallment,
        valor_parcela: format.currency(
          selectedInstallmentData.installmentAmount
        ),
      };
    } else
      insiderParams = { ...insiderParams, valor_a_pagar: fullPaymentTotal };

    insiderQueueCustom('resumo_pgr', insiderParams);

    mutate(
      {
        token: methods[methodSelected],
        discount,
        installmentsNumber: getInstallmentNumber(),
      },
      {
        onError: error => {
          navigate.push(screens.ERROR, {
            errotType: errorTypes.register_error,
            contentPage: trackingContentPage,
            errorObject: error,
            flow: flow.cards,
          });
        },
      }
    );
  };

  const renderMethods = () => {
    return keys(methods)?.map(item => {
      const method = paymentMethodsContent[item];

      if (!method?.show) return null;

      return (
        <S.ContainerMethod key={item}>
          <S.Flex>
            <S.Flex direction="row" justify="start">
              {method.icon}
              <S.TitleMethod>{method.title}</S.TitleMethod>
            </S.Flex>
            <S.DescriptionMethod>{method.description}</S.DescriptionMethod>
          </S.Flex>
          <Button
            outline={method?.button?.outline}
            state="black"
            title={method.button.title}
            aria-label={method.button.title}
            size="sm"
            onClick={() => handleConfirm(item)}
          />
        </S.ContainerMethod>
      );
    });
  };

  const renderFullPaymentDetails = () => {
    const summaryData = [
      {
        title: 'Valor em atraso',
        value: selected?.mainValue,
        show: cardInfo?.isOverDue,
      },
      {
        title: 'Encargos',
        value: selected?.interestValue,
        show: cardInfo?.isOverDue,
      },
      {
        title: 'Valor a vencer',
        value: selected?.balanceFuture,
        show: cardInfo?.daysOverdue >= 78,
      },
      {
        title: 'Total sem desconto',
        value: selected.totalValue,
        show: selected?.discount?.maxTotal > 0,
        showDivider: true,
        line: true,
      },
      {
        title: 'Desconto',
        value: selected?.discount?.maxTotal,
        show: selected?.discount?.maxTotal > 0,
        color: '#12A656',
      },
      {
        title:
          selected?.discount?.maxTotal > 0
            ? 'Total com desconto'
            : 'Total a pagar',
        value: fullPaymentTotal,
        show: true,
        showDivider: true,
      },
    ];

    return (
      <S.ContainerResume>
        <S.TitleMethod size={20}>Resumo</S.TitleMethod>
        {summaryData.map(
          (data, i) =>
            data.show && (
              <React.Fragment key={i}>
                {data.showDivider && <hr />}
                <S.Flex direction="row" top={4}>
                  <span>{data.title}</span>
                  <S.Span
                    size={i === summaryData.length - 1 ? 18 : undefined}
                    weight="500"
                    line={data.line}
                    color={data.color || undefined}
                  >
                    {data.color && '- '}
                    {format.currency(data.value)}
                  </S.Span>
                </S.Flex>
              </React.Fragment>
            )
        )}
      </S.ContainerResume>
    );
  };

  return (
    <S.Wrapper>
      <S.ContainerContent>
        <S.Title>
          {!cardInfo.isOverDue ? 'Pagar fatura(s)' : 'Quitar dívida'}
        </S.Title>
        <S.SubTitleDescription>
          Pague sua(s) fatura(s) via boleto ou Pix
        </S.SubTitleDescription>
        {isInstallmentPayment && renderInstallmentPaymentDetails()}

        {renderMethods()}

        {isFullPayment && renderFullPaymentDetails()}
      </S.ContainerContent>
    </S.Wrapper>
  );
};

export default ResumePayment;
