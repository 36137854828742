export const mockData = [
  {
    name: 'BannerSeguroProtecaoFinanceira',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-seguro-protecao-financeira-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-seguro-protecao-financeira-desktop.png',
    title:
      'Proteja seus sonhos, proteja suas finanças, contrate o seguro proteção financeira',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/protecao-financeira',
  },
  {
    name: 'BannerContaPaga',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-seguro-conta-paga-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-seguro-conta-paga.png',
    title:
      'Desfrute suas compras com total segurança. conte Com o Seguro Conta Paga',
    subtitle: '',
    icon: '',
    enabled: true,
  },
  {
    name: 'BannerSeguroCelularPortateis',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner_seguro_mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner_seguro_web.png',
    title:
      'Seguro celular, proteção para aparelhos novos e usados mantenha seus dispositivos protegidos',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/seguro-celular-e-portateis',
  },
  {
    name: 'BannerSeguroMaisProtecao',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-bolsa-pix-e-cartao-protegido-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-bolsa-pix-e-cartao-protegido-desktop.png',
    title:
      'segurança em cada movimento. Descubra como nosso seguro de bolsa, PIX e cartão protegido',
    subtitle: '',
    icon: '',
    enabled: true,
  },
  {
    name: 'BannerSeguroAcidentesPessoais',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-acidente-pessoal-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-ap-desktop.png',
    title:
      'Cuide de si mesmo e de quem você ama. Conte com o Seguro de Acidentes Pessoais',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];
