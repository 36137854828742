import styled, { css } from 'styled-components';

export const contentWidthAndHeightStyles = css`
  @media (min-width: 768px) {
    width: 60%;
    margin: auto;
  }
  min-height: 432px;
`;

export const Container = styled.div`
  padding: 0 24px;
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;

  & * {
    font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
  }
`;

export const ContentContainer = styled.div`
  padding: 24px 16px;
  ${contentWidthAndHeightStyles};
`;

export const ContentTitle = styled.h2`
  font-size: 1.5rem !important;
  font-weight: 500;
  margin-bottom: 8px;
  color: #221f20;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
`;

export const Content = styled.div`
  @media (min-width: 768px) {
    width: 80%;
    margin: auto;
  }
`;

export const Title = styled.h2`
  font-size: 1.75rem !important;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
`;
