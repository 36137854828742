import styled from 'styled-components';
import * as Common from '../../../styles';

export const Container = styled(Common.Container)`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const BlackWrapper = styled(Common.Wrapper)`
  background-color: #000000;
`;

export const Title = styled(Common.Title)`
  font-size: 2rem !important;
  font-weight: 500;
  margin-bottom: 32px;
  color: #fff;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 32px;
`;

export const Card = styled.div`
  border-radius: 8px;
  border: 1px solid #ffffff3d;
  background-color: #ffffff0f;
  padding: 16px;
  text-align: center;
  flex: 1;
`;

export const CardTitle = styled.h4`
  font-size: 1.5rem !important;
  color: #fff;
  margin-bottom: 8px;
  font-weight: 700;
`;

export const Text = styled.p`
  color: #9e9e9e;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
`;
