import styled, { css } from 'styled-components';
import OriginalBadge from '~/pages/PaymentChannel/components/Badge';

export const Wrapper = styled.div`
  border-radius: 8px;
  padding: 24px 16px;
  background-color: #fff;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  width: 50%;
  min-width: 126px;
`;

export const Badge = styled(OriginalBadge)`
  margin-left: auto;
`;

export const Title = styled.h3`
  font-size: 1rem !important;
  font-weight: 500;
  color: #221f20;
`;

export const LastNumbers = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: #646464;
  margin-top: 9px;

  svg {
    margin-right: 4px;
  }
`;

export const LimitContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const LimitText = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  color: #646464;
  margin-top: 4px;

  svg {
    width: 0.438rem;
    height: 0.438rem;
    margin-right: 0.375rem;
    color: #e0e0e0;
  }
`;

export const LimitValue = styled.span`
  font-size: 0.75rem;
  font-weight: bold;
  color: ${props => props.color};
`;

export const ProgressBar = styled.div`
  height: 6px;
  width: 100%;
  border-radius: 44px;
  background-color: #e0e0e0;

  &::before {
    content: '';
    display: block;
    width: ${props => `${props.percent}%`};
    height: 6px;
    border-radius: 44px;
    background-color: ${props => `${props.barColor}`};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  justify-content: space-between;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &:last-of-type {
    align-items: flex-end;
  }
`;

export const InfoDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: #646464;
`;

export const InfoValue = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #221f20;
`;

export const ButtonsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const Button = styled.button`
  border-radius: 25px;
  padding: 10px 14px;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  background: transparent;
  border: 1px solid #221f20;
  color: #221f20;
  flex: 1;

  ${props => {
    if (props.$mode === 'filled') {
      return css`
        background: #221f20;
        color: #ffffff;
      `;
    }
    if (props.$mode === 'red') {
      return css`
        background: transparent;
        border: 1px solid #9c162d;
        color: #9c162d;
      `;
    }
    if (props.$mode === 'red-filled') {
      return css`
        background: #9c162d;
        border: 1px solid #9c162d;
        color: #ffffff;
      `;
    }
  }}

  &.disabled, &:disabled {
    background: transparent;
    border: 1px solid #959d9d;
    color: #959d9d;

    ${props => {
      if (props.$mode === 'filled' || props.$mode === 'red-filled') {
        return css`
          background: #959d9d;
          color: #ffffff;
        `;
      }
    }}
  }
`;
