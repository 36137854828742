export const mockBannerData = [
  {
    name: 'BannerProdutosDescontinuados ',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-produtos-descontinuados-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-produtos-descontinuados.png',
    title: 'Produtos descontinuados',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];
