import styled from 'styled-components';
import { IconBrandWhatsapp, IconBuildingStore } from '@tabler/icons-react';
import { ReactComponent as VectorRchlo } from '~/assets/img/vetor-rchlo.svg';
import { ReactComponent as VectorMidway } from '~/assets/img/vetor-midway.svg';
import * as Common from '../../../styles';

export const Container = styled(Common.Container)`
  padding-top: 64px;
  padding-bottom: 64px;
`;

export const Wrapper = styled(Common.Wrapper)``;

export const Title = styled(Common.Title)`
  margin-bottom: 32px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  gap: 8px;

  svg {
    object-fit: contain;
    width: 40px;
    flex: 1 0 40px;
  }
`;

export const Text = styled.span`
  font-size: 1.125rem;
  color: #646464;
  font-weight: 400;

  a {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
`;

export const IconWhatsapp = styled(IconBrandWhatsapp)`
  stroke: #39ae42;
`;

export const IconRchlo = styled(VectorRchlo)`
  fill: #252525;
`;

export const IconMidway = styled(VectorMidway)`
  fill: #1f2b2a;
`;

export const IconStore = styled(IconBuildingStore)`
  stroke: #1d1d1b;
`;
