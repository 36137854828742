import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MidwayLogo from '~/assets/img/BrandsMenu/midway.svg';
import RiachueloLogo from '~/assets/img/BrandsMenu/riachuelo.svg';
import CasaLogo from '~/assets/img/BrandsMenu/casa-rchlo.svg';
import CartersLogo from '~/assets/img/BrandsMenu/carters.svg';
import FanLogo from '~/assets/img/BrandsMenu/fan.svg';
import BlogLogo from '~/assets/img/BrandsMenu/blog.svg';

export const Wrapper = styled.div`
  background-color: #221f20;
`;

export const Nav = styled.nav`
  overflow: scroll;
  position: relative;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 901px) {
    overflow: auto;
  }

  @media (min-width: 1300px) {
    max-width: 1270px;
  }
`;

export const List = styled.ul`
  display: flex;
  height: 44px;
  margin-bottom: 0em;
`;

export const ListItem = styled.li`
  display: flex;
  padding: 5px 20px;

  &.header-logo {
    background-color: #ffffff;
    &:hover,
    &:focus {
      background-color: #eee;
    }
  }

  &:hover,
  &:focus {
    background-color: #424242;
  }
`;

export const StyledLink = styled(Link)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -999px;
  overflow: hidden;
  transition: 0.2s ease;

  margin-right: 15px;
  margin-left: 15px;
  padding: 0;

  &#midway-logo {
    background-size: 100% 90%;
    background-position: center;
    background-image: url(${MidwayLogo});
    width: 55px;
    padding: 0;
  }
  &#rchlo-logo {
    background-image: url(${RiachueloLogo});
    width: 75px;
  }
  &#casa-logo {
    background-image: url(${CasaLogo});
    width: 50px;
  }
  &#carters-logo {
    background-image: url(${CartersLogo});
    width: 60px;
  }
  &#fan-logo {
    background-image: url(${FanLogo});
    width: 30px;
  }
  &#blog-logo {
    background-image: url(${BlogLogo});
    width: 40px;
  }

  @media (min-width: 901px) {
    margin-right: 0;
    margin-left: 0;

    &#midway-logo {
      width: 150px;
    }
    &#rchlo-logo {
      width: 140px;
    }
    &#casa-logo {
      width: 60px;
    }
    &#carters-logo {
      width: 80px;
    }
    &#fan-logo {
      width: 60px;
    }
    &#blog-logo {
      width: 54px;
    }
  }
`;
