export const mockData = [
  {
    id: 1,
    name: 'Seguros',
    children: [
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/seguro_residencial.png',
        title: 'Seguro Residencial',
        description: 'Sua casa, sua conquista. Deixe ela sempre protegida.',
        link: '/lar-protegido',
        linkTitle: 'Mais sobre o Seguro Residencial',
      },
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/seguro_celular.png',
        title: 'Seguro Celular',
        description:
          'No seu celular estão seus dados, suas memórias, sua vida. Cuide bem dele.',
        link: '/seguro-celular-e-portateis',
        linkTitle: 'Mais sobre o Seguro Celular',
      },
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/seguro_conta_paga.png',
        title: 'Seguro Conta Paga',
        description:
          'A tranquilidade que você precisa ter com o seu Cartão Riachuelo.',
        link: '/conta-paga',
        linkTitle: 'Mais sobre o Seguro Conta Paga',
      },
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/seguro_pix_cartao_protegido.png',
        title: 'Seguro Bolsa Pix e Cartão Protegido - Mais Proteção',
        description: 'Proteção é o item que não pode faltar na sua bolsa!',
        link: '/mais-protecao',
        linkTitle: 'Mais sobre o Seguro Mais Proteção',
      },
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/seguro_acidentes_pessoais.png',
        title: 'Seguro Acidentes Pessoais',
        description:
          'Nas horas em que mais precisar, o nosso seguro estará com você.',
        link: '/seguro-acidentes-pessoais',
        linkTitle: 'Mais sobre o Seguro Acidentes Pessoais',
      },
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/seguro_protecao_financeira.png',
        title: 'Seguro Proteção Financeira',
        description: 'Segurança e proteção para seu empréstimo e parcelamento.',
        link: '/protecao-financeira',
        linkTitle: 'Mais sobre o Seguro Proteção Financeira',
      },
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/seguro-conta-paga.png',
        title: 'Seguro Compra Premiada',
        description: 'Tranquilidade e segurança em caso de perda de renda.',
        link: '/seguro-compra-premiada',
        linkTitle: 'Mais sobre o Seguro Compra Premiada',
      },
    ],
  },
  {
    id: 2,
    name: 'Serviços para saúde',
    children: [
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/plano_odontologico.png',
        title: 'Plano Odontológico',
        description:
          'Um sorriso bonito é sinal de saúde, mas também pode ser de economia.',
        link: '/plano-odontologico',
        linkTitle: 'Conhecer Plano Odontológico',
      },
    ],
  },
];
