import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #f3ebdb;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Banner = styled.div`
  width: 100%;
  max-width: 1442px;
  position: relative;
`;
