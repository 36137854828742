import React, { useState } from 'react';
import { ReactComponent as VetorRiachuelo } from '~/assets/img/vetor-riachuelo.svg';
import { ReactComponent as SandwichIcon } from '~/assets/img/vetor-menu-sanduiche.svg';
import { IconChevronLeft } from '@tabler/icons-react';
import * as S from './styles';
import MobileMenu from '~/components/Header/MobileMenu';

const HeaderMain = ({ title = '' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickHandler = () => {
    setIsOpen(_isOpen => !_isOpen);
  };

  return (
    <>
      <S.Header>
        <S.Container>
          {!isOpen && (
            <S.LogoWrapper>
              <VetorRiachuelo />
            </S.LogoWrapper>
          )}
          <S.ButtonsWrapper>
            <S.MenuButton
              onClick={onClickHandler}
              aria-label={isOpen ? 'Fechar' : 'Menu'}
            >
              {isOpen ? <IconChevronLeft /> : <SandwichIcon />}
            </S.MenuButton>
            {title && <S.Title>{title}</S.Title>}
          </S.ButtonsWrapper>
        </S.Container>
      </S.Header>
      {isOpen && (
        <S.MenuContainer>
          <MobileMenu reduceHeightBy="56px" />
        </S.MenuContainer>
      )}
    </>
  );
};

export default HeaderMain;
