import styled from 'styled-components';
import * as Common from '../../../styles';

export const Container = styled(Common.Container)`
  padding-top: 64px;
  padding-bottom: 64px;
`;

export const GrayWrapper = styled(Common.Wrapper)`
  background-color: #f5f5f5;
`;

export const Title = styled(Common.Title)``;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  width: 312px;
  margin: 32px auto;
  box-shadow: 0 4px 6px -1px rgba(31, 43, 42, 0.12);
`;

export const CardIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

export const CardContent = styled.div`
  display: flex;
  gap: 16px;
`;

export const CardText = styled.h3`
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  color: #221f20;
  flex-basis: 120px;
  line-height: 1.2;
`;

export const List = styled.ul`
  list-style: none;
  flex-basis: 120px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ListItem = styled.li`
  font-size: 0.85rem;
  color: #646464;
  display: flex;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f5f5;
  width: 48px;
  height: 48px;

  svg {
    width: 32px;
    height: 32px;
    stroke-width: 1.75px;
  }
`;

export const CheckIconWrapper = styled(IconWrapper)`
  background-color: #e4602f;
  width: 16px;
  height: 16px;
  margin-right: 6px;

  svg {
    width: 12px;
    height: 12px;
    color: #fff;
  }
`;
