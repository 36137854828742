export const negotiable = {
  SICC: ['agreement', 'debit', 'acquittance', 'invoicement'],
};

export const lastRangeOverDue = 180;
export const midRangeOverDue = 77;

export const errorTypes = {
  no_contract: 'no_contract',
  no_elegibility: 'no_elegibility',
  generic: 'generic',
  timeout: 'timeout',
  register_error: 'register_error',
};

export const messageErrors = {
  [errorTypes.no_contract]: 'Não existe Cliente para o cpf informado.',
  [errorTypes.no_elegibility]:
    'Este contrato não está em situação de cobrança.',
  [errorTypes.generic]: '',
  [errorTypes.timeout]: '',
  [errorTypes.register_error]: '',
};
