import React, { useState, useRef, useEffect } from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import * as S from './styles';
import { ThemeProvider } from 'styled-components';

const Accordion = ({ title, theme = S.darkTheme, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <ThemeProvider theme={theme}>
      <S.Container className={isOpen ? 'open' : ''}>
        <S.ContentContainer>
          <S.Title onClick={toggleAccordion}>{title}</S.Title>
          <S.Content style={{ height }} ref={contentRef}>
            <S.InnerContent>{children}</S.InnerContent>
          </S.Content>
        </S.ContentContainer>
        <S.Button
          onClick={toggleAccordion}
          aria-label={isOpen ? 'esconder' : 'mostrar'}
        >
          <IconChevronDown />
        </S.Button>
      </S.Container>
    </ThemeProvider>
  );
};

export default Accordion;
