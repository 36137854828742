import React from 'react';
import * as S from './styles';
import format from '~/services/format';

const InstallmentOptions = ({
  setIsInstallmentOptionsOpen,
  setInstallmentSelected,
  installments,
  calculateTotalFn,
}) => {
  const closeHandler = () => {
    setIsInstallmentOptionsOpen(false);
  };

  const onClickHandler = installment => {
    setInstallmentSelected(installment.installmentQuantity);
    closeHandler();
  };

  const percentageRateMonth =
    installments[0].composition.calculationBasis.percentageRateMonth;

  const renderInstallmentButton = installment => {
    return (
      <S.Button onClick={() => onClickHandler(installment)}>
        <div>
          <S.ButtonText>
            {installment.installmentQuantity} parcelas de{' '}
            {format.currency(installment.installmentAmount)}
          </S.ButtonText>
          <S.ButtonSubtext>
            Total: {format.currency(calculateTotalFn(installment))}
          </S.ButtonSubtext>
        </div>
        <S.IconChevronRight />
      </S.Button>
    );
  };

  const renderMessageTotal = () => (
    <S.Text>O valor total é a soma da entrada com as parcelas</S.Text>
  );

  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.Title>Todas as opções de parcelas</S.Title>
          <S.Text>Aplicado juros de {percentageRateMonth}% a.m</S.Text>
          {renderMessageTotal()}
          <S.InstallmentsContainer>
            {installments.map((installment, index) => (
              <React.Fragment key={index}>
                {renderInstallmentButton(installment)}
              </React.Fragment>
            ))}
          </S.InstallmentsContainer>
          {renderMessageTotal()}
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default InstallmentOptions;
