import styled from 'styled-components';
// import * as Common from '../../styles';

export const darkTheme = {
  bg: `#000`,
  fg: `#fff`,
  text: `#d9d9d9`,
};

export const lightTheme = {
  bg: `#fff`,
  fg: `#000`,
  text: `#1F2B2A`,
};

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  padding: 12px 8px 12px 16px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  &.open {
    background-color: ${props => props.theme.bg};
  }
`;

export const ContentContainer = styled.div`
  flex: 1 0 calc(100% - 30px);
`;

export const Title = styled.h4`
  color: #221f20;
  cursor: pointer;
  font-size: 1rem !important;
  line-height: 1.5;
  font-weight: 500;
  transition: all 0.2s ease-in-out;

  .open & {
    color: ${props => props.theme.fg};
  }
`;

export const Content = styled.div`
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  color: #1f2b2a;
  height: 0;

  .open & {
    color: ${props => props.theme.text};
  }
`;

export const InnerContent = styled.div`
  margin-top: 16px;
`;

export const Button = styled.button`
  all: unset;
  outline: revert;
  flex: 1 0 30px;

  svg {
    transition: transform 0.2s ease-in-out;

    .open & {
      transform: rotate(-180deg);
      stroke: ${props => props.theme.fg};
    }
  }
`;
