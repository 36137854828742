import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import useIsMobile from '~/hooks/useIsMobile';
import { mockData } from './data';
import { mockBannerData } from './banner-data';
import cmsService from '~/services/cms/pages';

const DiscontinuedProducts = () => {
  const isMobile = useIsMobile();
  const [data, setData] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filter, setFilter] = useState({ term: '' });
  const handleGetData = useCallback(async () => {
    try {
      const response = await cmsService.getDiscontinuedProducts(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setData(response[0].children);
        setFilteredDocuments(response[0].children);

        return;
      }
      setData(mockData[0][0].children);
    } catch (error) {
      setData(mockData[0][0].children);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerDiscontinuedProducts(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(mockBannerData[0]);
        return;
      }
      setBannerData(mockBannerData);
    } catch (error) {
      setBannerData(mockBannerData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  // eslint-disable-next-line no-unused-vars
  const handleUpdateFilteredDocuments = useCallback(() => {
    const docs = data.filter(document => {
      const termMatch = document?.description
        ?.toLowerCase()
        .includes(filter.term.toLowerCase());
      return termMatch;
    });

    setFilteredDocuments(docs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    handleUpdateFilteredDocuments();
  }, [handleUpdateFilteredDocuments, filter]);

  useEffect(() => {
    handleGetData();
    handleGetBannerData();
  }, [handleGetData, handleGetBannerData]);

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BackgroundContainer>
          <img
            src={
              isMobile && bannerData.imgMobile
                ? bannerData.imgMobile
                : bannerData.img
            }
            alt={bannerData.name}
          />
        </S.BackgroundContainer>
        <S.CentralizeContent>
          <S.ContainerTop>
            <S.Title>Documentos</S.Title>

            <S.Input
              type="text"
              id="search"
              isMobile={isMobile}
              placeholder="Buscar documento"
              aria-label="Barra de pesquisa:"
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
                setFilter({
                  ...filter,
                  term: e.target.value,
                });
              }}
            />
          </S.ContainerTop>
          {filteredDocuments?.map(item => {
            return (
              <>
                <S.ContentItem>
                  <S.TitleItem>{item?.description}</S.TitleItem>
                  <S.LinkItem href={item?.link} target="_blank">
                    {item.view}
                  </S.LinkItem>
                </S.ContentItem>
              </>
            );
          })}
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default DiscontinuedProducts;
