import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  padding: 10px;
  position: relative;
  z-index: 1;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  &:hover {
    opacity: 0.8;
  }
`;

export const ProgressBarContainer = styled.div`
  flex: 1;
  height: 4px;
  background-color: #333;
  border-radius: 2px;
  margin: 0 10px;
  position: relative;
`;

export const ProgressBar = styled.div`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: #f97316; /* Laranja */
  border-radius: 2px;
  transition: width 0.3s ease;
`;
