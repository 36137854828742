import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Chevron from '~/assets/img/BrandsMenu/chevron.svg';
import IconCreditCard from '~/assets/img/BrandsMenu/ico/credit-card.svg';
import IconMaster from '~/assets/img/BrandsMenu/ico/mids-mastercard.svg';
import IconRchlo from '~/assets/img/BrandsMenu/ico/mids-rchlo.svg';
import IconStars from '~/assets/img/BrandsMenu/ico/stars.svg';
import IconCash from '~/assets/img/BrandsMenu/ico/cash.svg';
import IconMoney from '~/assets/img/BrandsMenu/ico/report-money.svg';
import IconTicket from '~/assets/img/BrandsMenu/ico/ticket.svg';
import IconSingleCard from '~/assets/img/BrandsMenu/ico/single-card.svg';

import IconShield from '~/assets/img/BrandsMenu/ico/shield.svg';
import IconHeart from '~/assets/img/BrandsMenu/ico/mids-heart.svg';
import IconBlock from '~/assets/img/BrandsMenu/ico/mids-custom-permanent-block.svg';
import IconDental from '~/assets/img/BrandsMenu/ico/mids-dental.svg';
import IconTrendinUp from '~/assets/img/BrandsMenu/ico/trending-up.svg';
import IconCirclePlus from '~/assets/img/BrandsMenu/ico/circle-plus.svg';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  #menuMobile {
    height: ${props => {
      if (props.reduceHeightBy) return `calc(100vh - ${props.reduceHeightBy})`;
      return 'calc(100vh - 160px)';
    }};
    overflow: scroll;
    padding-bottom: 20px;

    .submenu {
      visibility: visible;
      opacity: 1;
      position: relative;
      top: auto;
      box-shadow: none;
      width: auto;
      padding: 10px 15px;

      &:before {
        content: attr(data-header);
        color: #704810;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }

      li {
        display: flex;
        align-items: center;

        a {
          font-size: 12px !important;
          margin: 10px 0 !important;
          padding: 0 !important;
          color: #1f2b2a;

          &:hover,
          &:focus {
            margin: 10px 0 !important;
            &:before {
              margin-right: 10px !important;
              margin-left: 3px;
            }
          }

          &:before {
            content: '';
            background-image: url(${IconCreditCard});
            width: 24px;
            height: 24px;
            margin-right: 20px;
            transition: 0.2s ease;
            display: inline-flex;
            padding: 6px;
          }
          &:after {
            content: none !important;
          }
          &#ico-master {
            &:before {
              background-image: url(${IconMaster});
            }
          }
          &#ico-rchlo {
            &:before {
              background-image: url(${IconRchlo});
            }
          }
          &#ico-ticket {
            &:before {
              background-image: url(${IconTicket});
            }
          }
          &#ico-stars {
            &:before {
              background-image: url(${IconStars});
            }
          }
          &#ico-single-card {
            &:before {
              background-image: url(${IconSingleCard});
            }
          }
          &#ico-cash {
            &:before {
              background-image: url(${IconCash});
            }
          }
          &#ico-money {
            &:before {
              background-image: url(${IconMoney});
            }
          }
          &#ico-plus {
            &:before {
              background-image: url(${IconCirclePlus});
            }
          }
          &#ico-shield {
            &:before {
              background-image: url(${IconShield});
            }
          }
          &#ico-block {
            &:before {
              background-image: url(${IconBlock});
            }
          }
          &#ico-heart {
            &:before {
              background-image: url(${IconHeart});
            }
          }
          &#ico-dental {
            &:before {
              background-image: url(${IconDental});
            }
          }
          &#ico-trending-up {
            &:before {
              background-image: url(${IconTrendinUp});
            }
          }

          &.MenuLinkMobile {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding-bottom: 7px !important;
            width: 100%;
            &:after {
              background-image: url(${Chevron});
              content: '' !important;
              width: 14px;
              height: 8px;
              transform: rotate(270deg);
              right: 30px;
              position: absolute;
            }
          }
        }
      }
    }
  }
`;

export const StyledLink = styled(Link)``;
