import React, { useEffect } from 'react';
import { Button, Icon } from '@midway/web-ui-component';
import { first, isEmpty, lowerCase } from 'lodash';
import { useNegotiation } from '~/context/negotiationV2';
import { tagMiAjudaButton } from '~/services/tagging';
import { gtagSelectContent, gtagViewContent } from '~/utils/TaggingGA4';
import ContainerFadeIn from '../../ContainerFadeIn';
import {
  Container,
  Title,
  SubTitle,
  Authenticate,
  ContainerDetails,
  BoldDetails,
  ContainerContent,
  ContainerNewNegotiation,
  TitleContent,
  TitleNewNegotiation,
  ContainerContentWP,
  ContainerMessage,
  TitleBold,
} from './styles';
import ButtonCopy from '../../ButtonCopy';
import QRCode from '../../QRCode';
import moment from 'moment';
import format from '~/services/format';

const GTAG_CONTENT_PAGE = 'pegue-aqui-a-segunda-via-do-boleto-do-seu-acordo';
const GTAG_FLOW = 'miajuda';

const tagGa4Button = (content_type, params) => {
  gtagSelectContent(GTAG_CONTENT_PAGE, GTAG_FLOW, content_type, params);
};

function Duplicate() {
  const { system, duplicateData, negotiationData, setHistoryPages } =
    useNegotiation();

  const duplicate = first(duplicateData);

  const gtagParams = {
    sistema: lowerCase(system),
    proposta: negotiationData?.gaParamProposta,
    contrato: duplicate?.contract,
  };

  const isPix = !isEmpty(duplicate?.pixCopyAndPaste);
  const buttonCopyText = !isPix
    ? 'Copiar código do boleto'
    : 'Copiar código PIX';

  useEffect(() => {
    gtagViewContent(GTAG_CONTENT_PAGE, GTAG_FLOW, gtagParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRedirectWhatsTopaz = () => (
    <ContainerContentWP>
      <TitleBold>Precisa de ajuda?</TitleBold>
      <span>
        Você pode acionar a nossa Central de Atendimento através dos números
        3004 5417 (capitais e regiões metropolitanas) e 0800 727 4417 (demais
        localidades)
      </span>
      <Button
        title="WhatsApp Midway"
        type="submit"
        onClick={() => {
          tagGa4Button('possui-acordo-whatsapp', gtagParams);
          tagMiAjudaButton('possui-acordo-whatsapp');
          window.open(
            'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1!%20Quero%20renegociar%20minha%20d%C3%ADvida%20de%20empr%C3%A9stimo',
            '_blank'
          );
        }}
      />
    </ContainerContentWP>
  );

  if (system === 'TOPAZ' && !isEmpty(duplicate) && !duplicate?.typeableLine) {
    return (
      <>
        <TitleNewNegotiation>
          Você já possui um acordo vigente
        </TitleNewNegotiation>
        <ContainerMessage>
          Não esqueça de manter o valor das parcelas disponível no saldo da sua
          conta Midway nos dias de vencimento.
        </ContainerMessage>
        {renderRedirectWhatsTopaz()}
      </>
    );
  }

  return (
    <ContainerFadeIn>
      <Container>
        <Title>Você já possui um acordo vigente</Title>
        <SubTitle>
          Identificamos que você já realizou uma negociação em um dos nossos
          canais ou em uma das Assessorias de cobrança parceiras.
        </SubTitle>
        <Authenticate>
          <Icon icon="check" />
          Acordo autenticado pela Riachuelo:
        </Authenticate>
        <ContainerDetails>
          <div>
            <span>vencimento</span>
            <BoldDetails>
              {moment(duplicate?.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </BoldDetails>
          </div>
          <div>
            <span>Valor a pagar</span>
            <BoldDetails>{format.currency(duplicate.amount)}</BoldDetails>
          </div>
        </ContainerDetails>
        <ContainerContent>
          {isPix ? (
            <QRCode className="qrcode" value={duplicate?.pixCopyAndPaste} />
          ) : (
            <>
              <TitleContent>Código do boleto</TitleContent>
              <span>{duplicate?.formatTypeableLine}</span>
            </>
          )}
          <ButtonCopy
            type="billet"
            title={buttonCopyText}
            toCopy={
              isPix ? duplicate?.pixCopyAndPaste : duplicate?.typeableLine
            }
          />
        </ContainerContent>
        <hr />
        <ContainerNewNegotiation>
          <TitleNewNegotiation>
            Deseja simular um novo acordo?
          </TitleNewNegotiation>
          <span>
            Lembre-se que as condições negociadas anteriormente não são
            garantidas em uma nova negociação
          </span>
          <Button
            title="Simular novo acordo"
            type="submit"
            onClick={() => {
              tagGa4Button('novo-acordo', gtagParams);
              tagMiAjudaButton('novo-acordo');
              setHistoryPages(prevHistory => [...prevHistory, 'eligibility']);
            }}
          />
        </ContainerNewNegotiation>
      </Container>
    </ContainerFadeIn>
  );
}

export default Duplicate;
