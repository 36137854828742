import styled from 'styled-components';
import * as Common from '../../../styles';

export const Container = styled(Common.Container)`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const SmallTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    color: #9e9e9e;
    height: 1rem;
    width: 1rem;
  }
`;

export const SmallText = styled.p`
  font-size: 0.625rem;
  color: #9e9e9e;
  line-height: 1;
  margin-bottom: 0 !important;
`;

export const Wrapper = styled(Common.Wrapper)``;

export const Content = styled(Common.Content)``;

export const Title = styled(Common.Title)`
  margin-bottom: 32px;
`;

export const ContentWrapper = styled.div``;

export const Image = styled.img`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 160px;
`;

export const CpfInputWrapper = styled.div`
  margin-bottom: 32px;
`;

export const DateInputWrapper = styled.div`
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  height: 0;

  & > * {
    margin-bottom: 32px;
  }
`;
