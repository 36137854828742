import React, { useCallback, useEffect, useState } from 'react';
import RenderBannerImage from '~/components/RenderBannerImage';
import cmsService from '~/services/cms/pages';
import { banner } from './data';
import useHasMobileDownloadHeader from '~/hooks/useHasMobileDownloadHeader';

import * as S from './styles';

const Header = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerDigitalAccount(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banners = bannerData[0] || banner;
  const hasText = useHasMobileDownloadHeader();

  return (
    <S.HeaderWrapper hasMobile={hasText} data-testid="header">
      <S.BackgroundContainer>
        <S.BannerContainer>
          <RenderBannerImage {...banners} />
        </S.BannerContainer>
      </S.BackgroundContainer>
    </S.HeaderWrapper>
  );
};

export default Header;
