import React, { useState } from 'react';
import * as S from './styles';

const maskInput = input => {
  let currency = input.replace(/\D/g, '');
  currency = currency.replace(/(\d+)(\d{2})$/, '$1,$2');

  currency = currency.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return currency;
};

const MoneyInput = ({ value, onChange, onBlur }) => {
  const [inputValue, setInputValue] = useState(
    maskInput(value.toFixed(2).toString())
  );

  const handleChange = e => {
    const rawValue = e.target.value;

    const masked = maskInput(rawValue);

    setInputValue(masked);

    if (onChange) {
      onChange(masked);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(inputValue);
    }
  };

  return (
    <S.Input
      type="text"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default MoneyInput;
