import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { useDispatch } from 'react-redux';
import {
  getRemoteConfig,
  fetchAndActivate,
  getBoolean,
} from 'firebase/remote-config';
import { saveFeatureToggles } from '~/store/modules/featuretoggle/actions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const useRemoteConfig = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const firebase = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(firebase);

    remoteConfig.settings = {
      minimumFetchIntervalMillis: 60 * 1000,
    };

    fetchAndActivate(remoteConfig)
      .then(() => {
        dispatch(
          saveFeatureToggles({
            isEnabledUpsell: getBoolean(remoteConfig, 'web_enabled_upsell'),
            isEnabledContaOnline: getBoolean(
              remoteConfig,
              'web_enabled_conta_online'
            ),
            isEnabledContaOnlineServiceV2: getBoolean(
              remoteConfig,
              'web_enabled_conta_online_serice_v2'
            ),
            isEnabledInvoiceInstallment: getBoolean(
              remoteConfig,
              'web_enabled_invoice_installment'
            ),
            isEnabledCallToActionsNegotiation: getBoolean(
              remoteConfig,
              'web_enabled_call_to_actions_negotiation'
            ),
            isEnabledNewHome: getBoolean(remoteConfig, 'web_enabled_new_home'),
            isEnabledBenefitsTagsNegotiation: getBoolean(
              remoteConfig,
              'web_enabled_benefits_tags_negotiation'
            ),
            isEnabledMiajudaInvoiceInstallment: getBoolean(
              remoteConfig,
              'web_enabled_miajuda_invoice_installments'
            ),
            isEnabledSendToDynamics: getBoolean(
              remoteConfig,
              'web_enable_dynamics'
            ),
            isEnabledBannerFeiraoMiAjuda: getBoolean(
              remoteConfig,
              'web_enable_banner_feirao_miAjuda'
            ),
            isEnabledMiajudaBannerDesenrola: getBoolean(
              remoteConfig,
              'web_enable_banner_desenrola'
            ),
            isEnabledDataValidation: getBoolean(
              remoteConfig,
              'web_enable_data_validation_miajuda'
            ),
            isEnabledRedirectpf: getBoolean(
              remoteConfig,
              'web_enabled_redirect_pf'
            ),
            isEnableNonNegotiable: getBoolean(
              remoteConfig,
              'web_enable_non_negotiable'
            ),
            isEnabledCardRedirectWP: getBoolean(
              remoteConfig,
              'web_enabled_card_redirect_wp'
            ),
            isEnabledNegotiationSicc: getBoolean(
              remoteConfig,
              'web_enabled_negotiation_sicc'
            ),
            isEnabledNegotiationTopaz: getBoolean(
              remoteConfig,
              'web_enabled_negotiation_topaz'
            ),
            isEnabledNegotiationSipf: getBoolean(
              remoteConfig,
              'web_enabled_negotiation_sipf'
            ),
            isEnabledNegotiationAgreements: getBoolean(
              remoteConfig,
              'web_enabled_negotiation_agreements'
            ),
            isEnabledNegotiationTsys: getBoolean(
              remoteConfig,
              'web_enabled_negotiation_tsys'
            ),
            isEnabledNewHomeMiAjuda: getBoolean(
              remoteConfig,
              'web_enabled_new_home_miAjuda'
            ),
            isEnabledRedirectSIPF: getBoolean(
              remoteConfig,
              'web_enabled_redirect_sipf'
            ),
            isEnabledUseCorrectText: getBoolean(
              remoteConfig,
              'web_enabled_use_correct_text'
            ),
            isEnabledPreLoading: getBoolean(
              remoteConfig,
              'web_enabled_pre_loading'
            ),
            isEnabled412TOPAZ: getBoolean(
              remoteConfig,
              'web_enabled_412_topaz'
            ),
            isEnabledPaymentMethodMiAjuda: getBoolean(
              remoteConfig,
              'web_enabled_payment_method_negotiation'
            ),
            isDisablePaymentMethodPixSipf: getBoolean(
              remoteConfig,
              'web_disable_payment_method_pix_sipf'
            ),
            isEnabledOnly2Proposals: getBoolean(
              remoteConfig,
              'web_enable_only2_proposals'
            ),
            isEnabledIofInfo: getBoolean(remoteConfig, 'web_enable_iof_info'),
            isEnabledPaymentMethodDebit: getBoolean(
              remoteConfig,
              'web_enabled_payment_method_debit'
            ),
            isDisablePaymentMethodPixSicc: getBoolean(
              remoteConfig,
              'web_disable_payment_method_pix_sicc'
            ),
            isEnabledPaymentMethodTopaz: getBoolean(
              remoteConfig,
              'web_enabled_payment_method_topaz'
            ),
            isEnabledInvoiceInstallmentPixPaymentMethod: getBoolean(
              remoteConfig,
              'web_enabled_invoice_installment_pix_payment_method'
            ),
            isEnabledMtopPaymentMethod: getBoolean(
              remoteConfig,
              'web_enable_mtop_payment_method'
            ),
            isEnabledBiCardsIntegration: getBoolean(
              remoteConfig,
              'web_enabled_bi_cards_integration'
            ),
            isDisableHeaderButtonBilletInvoice: getBoolean(
              remoteConfig,
              'web_disable_header_button_billet_invoice'
            ),
            isEnablePaymentDate: getBoolean(
              remoteConfig,
              'web_enabled_payment_date'
            ),
            isEnablePaymentDebtAccount: getBoolean(
              remoteConfig,
              'web_enable_payment_debt_account'
            ),
            isEnableQRCodePayment: getBoolean(
              remoteConfig,
              'web_enabled_qrcode_payment'
            ),
            isEnabledMiajudaCaptchaKey: getBoolean(
              remoteConfig,
              'web_enable_miajuda_captcha_key'
            ),
            isEnabledMiajudaCaptcha: getBoolean(
              remoteConfig,
              'web_enable_miajuda_captcha'
            ),
            isEnableInsider: getBoolean(remoteConfig, 'web_enabled_insider'),
            isEnableTopazDebit: getBoolean(
              remoteConfig,
              'web_enabled_topaz_debit'
            ),
            isEnableTopazAcquittance: getBoolean(
              remoteConfig,
              'web_enabled_topaz_acquittance'
            ),
            isEnableTopazAgreements: getBoolean(
              remoteConfig,
              'web_enabled_topaz_agreements'
            ),
            isEnableMonestLink: getBoolean(
              remoteConfig,
              'web_enable_monest_link'
            ),
            isEnableEqualWeb: getBoolean(
              remoteConfig,
              'web_equalweb_a11y_tool_enabled'
            ),
            isEnabledPaymentChannel: getBoolean(
              remoteConfig,
              'web_enable_payment_channel'
            ),
          })
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return { loading };
};

export default useRemoteConfig;
