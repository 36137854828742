import styled from 'styled-components';
import * as Common from '../../../styles';
import OriginalButton from '../../../components/Button';
import ModelImg from '~/assets/img/PaymentChannel/model-looking-at-phone-mobile.png';

export const Container = styled(Common.Container)`
  padding-top: 51px;
  padding-bottom: 53px;
  width: 240px;
  margin-right: auto;
`;

export const Wrapper = styled(Common.Wrapper)`
  background-image: url(${ModelImg});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: min(70%, 275px);
`;

export const Title = styled(Common.Title)`
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-size: 0.875rem !important;
  color: #646464;
  margin-bottom: 32px !important;
`;

export const Button = styled(OriginalButton)`
  padding: 16px;
`;
