import styled from 'styled-components';
import * as Common from '../../styles';
import { ReactComponent as IconPersonalLoanSrc } from '~/assets/img/PaymentChannel/icon-personal-loan.svg';

export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0.5px 1px #7683934c;
  position: sticky;
  top: 56px;
  z-index: 1000;
`;

export const Container = styled(Common.Container)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const Item = styled.div`
  width: 80px;
`;

export const Text = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
`;

export const RoundButton = styled.button`
  all: unset;
  background-color: #d9d9d9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    stroke: #000;
    width: 16px;
  }
`;

export const IconPersonalLoan = styled(IconPersonalLoanSrc)``;
