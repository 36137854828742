import styled from 'styled-components';
import Button from '../../components/Button';
import {
  IconMinus as _IconMinus,
  IconPlus as _IconPlus,
} from '@tabler/icons-react';
import { ContentContainer, ContentTitle } from '~/pages/PaymentChannel/styles';

export const Wrapper = styled.div`
  width: 100%;
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
`;

export const Container = styled(ContentContainer)``;

export const Title = styled(ContentTitle)``;

export const SubTitle = styled(Title)`
  font-size: 1.25rem !important;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: #646464;
`;

export const TextDark = styled(Text)`
  color: #221f20;
`;

export const SmallText = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: #646464;
`;

export const SmallTextDark = styled(SmallText)`
  color: #221f20;
  filter: ${({ blur }) => (blur ? 'blur(6px)' : 'none')};
`;

export const ContainerWithMarginTop = styled.div`
  margin-top: 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
`;

export const RoundButton = styled.button`
  border-radius: 50%;
  background-color: #221f20;
  border-width: 0;
  width: 40px;
  height: 40px;

  svg {
    stroke: #fff;
  }

  &[disabled] {
    background-color: #f7f7f7;
    cursor: default;

    svg {
      stroke: #cacecd;
    }
  }
`;

export const ButtonContainerInstallmentsNumber = styled.p`
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  filter: ${({ blur }) => (blur ? 'blur(6px)' : 'none')};
`;
export const ButtonContainerInstallmentsValue = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  filter: ${({ blur }) => (blur ? 'blur(6px)' : 'none')};
`;

export const IconMinus = styled(_IconMinus)``;

export const IconPlus = styled(_IconPlus)``;

export const MoreOptionsButton = styled.button`
  display: flex;
  background: transparent;
  border: 1px solid #231f20;
  color: #231f20;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 24px auto;
  font-family: inherit;
  width: max-content;
  padding: 2px 10px;
  border-radius: 10px;
  cursor: pointer;
`;

export const TotalAndContinueWrapper = styled(Wrapper)`
  margin-top: 120px;
  background-color: #fff;
`;

export const TotalAndContinueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const TotalValue = styled.p`
  font-weight: 500;
  font-size: 1.125rem;
  color: #221f20;
  filter: ${({ blur }) => (blur ? 'blur(6px)' : 'none')};
`;

export const ContinueButton = styled(Button)`
  font-family: inherit;
  background: #221f20;
  border: none;
  color: #fff;
  padding: 16px;
  border-radius: 100px;
  width: auto;
  min-width: 100px;
`;
