import React, { useCallback, useEffect, useState } from 'react';
import { banner } from './data';
import RenderBannerImage from '~/components/RenderBannerImage';
import cmsService from '~/services/cms/pages';
import Faq from './components/FAQ';
import * as S from './styles';

const FaqLoan = () => {
  const [bannerData, setBannerData] = useState([]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerFAQLoan(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banners = bannerData[0] || banner;

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BackgroundContainer>
          <S.Banner>
            <RenderBannerImage {...banners} />
          </S.Banner>
        </S.BackgroundContainer>
        <Faq />
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default FaqLoan;
