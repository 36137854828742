import React from 'react';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const CardsButton = ({
  title,
  description,
  button,
  link,
  contentType,
  flow,
}) => {
  const handleClick = () => {
    trackingSelectContent(contentType, flow);
    window.open(link, '_blank');
  };
  return (
    <S.Container>
      <S.Card>
        <S.Title>{title}</S.Title>
        <S.Line />
        <S.Description>{description}</S.Description>
        <S.Button onClick={handleClick}>{button}</S.Button>
      </S.Card>
    </S.Container>
  );
};

export default React.memo(CardsButton);
