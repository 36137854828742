import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Mobile from '~/components/Responsive/Mobile';
import { trackingSelectContent } from '~/analytics';

import useHasMobileDownloadHeader from '~/hooks/useHasMobileDownloadHeader';
import LogosMenu from '~/components/Header/LogosMenu';
import {
  MenuSeguros,
  MenuEmprestimos,
  MenuCartoes,
} from '~/components/Header/MenuSections';
import MobileMenu from '~/components/Header/MobileMenu';
import * as S from './style';

const PAYMENT_CAMPAIGN_URL =
  '/campanha-quitacao?utm_source=MiAjuda&utm_medium=Header&utm_campaign=quitacao-de-divida&utm_term=Header&utm_content=midwayheader';

const Header = () => {
  const {
    toogles: { isDisableHeaderButtonBilletInvoice },
  } = useSelector(state => state.featuretoggle);

  const hasAppDownloadWidget = useHasMobileDownloadHeader();
  const [, setIsMobile] = useState(window.innerWidth <= 712);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isPaymentCampaign =
    isDisableHeaderButtonBilletInvoice &&
    (window.location.pathname === '/campanha-quitacao' ||
      window.location.pathname === '/atendimento');

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 712);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const firePaymentsButtonClickToGTM = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway-geral',
      eventAction: 'clique:menu-e-sub-menu',
      eventLabel: 'pagamentos',
    });
  };

  const handlePaymentsButtonClick = () => {
    firePaymentsButtonClickToGTM();
  };

  return (
    <S.MenuWrapper>
      <LogosMenu />
      <div className="containermenu">
        <S.PrincipalMenu hasWidget={hasAppDownloadWidget}>
          <S.MenuRow>
            {!isPaymentCampaign && (
              <Link id="download-buttom" to="/app/">
                Baixe o App
              </Link>
            )}
            <S.MobileRow>
              {!showMobileMenu && (
                <button
                  type="button"
                  tabIndex={0}
                  onClick={() => setShowMobileMenu(true)}
                  id="sandwichMenu"
                >
                  Menu
                </button>
              )}
              {showMobileMenu && (
                <button
                  type="button"
                  tabIndex={0}
                  onClick={() => setShowMobileMenu(false)}
                  id="closeMenu"
                >
                  Fechar
                </button>
              )}
              <Mobile>
                {!isPaymentCampaign && (
                  <Link
                    tabIndex={0}
                    onClick={handlePaymentsButtonClick}
                    to="/consulta-boleto"
                    id="paybill-buttom"
                  >
                    <span>Pagar fatura</span>
                  </Link>
                )}
              </Mobile>
            </S.MobileRow>
          </S.MenuRow>

          <ul id="menu">
            <li tabIndex={0} aria-label="Cartões">
              <S.Button>
                Cartões <S.ChevronIcon />
              </S.Button>
              <MenuCartoes />
            </li>
            <li tabIndex={0} aria-label="Conta digital">
              <Link
                to="/conta-digital"
                onClick={() =>
                  trackingSelectContent(
                    'header:conta-digital:conta-digital-midway',
                    'home'
                  )
                }
              >
                Conta Digital
              </Link>
            </li>
            <li tabIndex={0} aria-label="Empréstimos">
              <Link
                onClick={() =>
                  trackingSelectContent('header:emprestimos', 'home')
                }
                to="/emprestimos"
              >
                Empréstimos <S.ChevronIcon />
              </Link>
              <MenuEmprestimos />
            </li>
            <li tabIndex={0} aria-label="Seguros e assistência">
              <Link
                to="/produtos-financeiros"
                onClick={() =>
                  trackingSelectContent('header:seguros-e-assistencias', 'home')
                }
              >
                Seguros e Assistências <S.ChevronIcon />
              </Link>
              <MenuSeguros />
            </li>
            <li tabIndex={0} aria-label="Negociação de Dívidas">
              <Link
                onClick={() =>
                  trackingSelectContent(
                    'header:negociacoes-de-dividas:negociar-divida',
                    'home'
                  )
                }
                to={PAYMENT_CAMPAIGN_URL}
              >
                Negociação de Dívidas
              </Link>
            </li>
            {!isPaymentCampaign && (
              <li
                onClick={() =>
                  trackingSelectContent('header:pagar-fatura', 'home')
                }
              >
                <Link
                  tabIndex={0}
                  onClick={handlePaymentsButtonClick}
                  to="/consulta-boleto"
                  id="paybill-buttom"
                >
                  <span>Pagar fatura</span>
                </Link>
              </li>
            )}
          </ul>
          {showMobileMenu && (
            <MobileMenu
              reduceHeightBy={hasAppDownloadWidget ? '223px' : '160px'}
            />
          )}
        </S.PrincipalMenu>
      </div>
    </S.MenuWrapper>
  );
};

Header.defaultProps = {
  menuItems: [],
};

export default Header;
