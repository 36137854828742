import React from 'react';
import { IconCreditCard, IconDiscount } from '@tabler/icons-react';
import * as S from './styles';

const Toolbar = () => {
  const itemsList = [
    {
      icon: <IconCreditCard />,
      text: <span>Fatura Cartão RCHLO</span>,
    },
    {
      icon: <S.IconPersonalLoan />,
      text: <span>Parcela Empréstimo</span>,
    },
    {
      icon: <IconDiscount />,
      text: <span>Negociação RCHLO</span>,
    },
  ];

  const Items = () => {
    return itemsList.map((item, index) => {
      const { icon, text } = item;
      return (
        <S.Item key={index}>
          <S.RoundButton>
            <S.IconWrapper>{icon}</S.IconWrapper>
          </S.RoundButton>
          <S.Text>{text}</S.Text>
        </S.Item>
      );
    });
  };

  return (
    <S.Wrapper>
      <S.Container>
        <Items />
      </S.Container>
    </S.Wrapper>
  );
};

export default Toolbar;
