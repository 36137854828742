import React from 'react';
import * as S from './styles';

const BilletDescription = ({ value }) => (
  <S.BilletContainer>
    <S.Title>Pague com o código do boleto</S.Title>
    <S.Code>{value}</S.Code>

    <S.Description>
      Atenção: Pagamentos por boleto podem levar até 3 dias úteis para serem
      processados.
    </S.Description>
  </S.BilletContainer>
);

export default BilletDescription;
