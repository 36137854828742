import React from 'react';
import StepNumber from '../StepNumber';
import * as S from './styles';

const ListStepNumber = ({ data }) => {
  return (
    <>
      <S.StepNumber data-testid="list-step-number-container">
        {data.map((value, index) => (
          <S.Container key={index}>
            <S.StepNumberContainer>
              <StepNumber size={70} value={value.value} color="#242424" />
            </S.StepNumberContainer>
            <S.TextContainer>
              <S.ParagraphSection>{value.text}</S.ParagraphSection>
            </S.TextContainer>
          </S.Container>
        ))}
      </S.StepNumber>
    </>
  );
};

export default React.memo(ListStepNumber);
