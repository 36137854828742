import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 100000;
  overflow-y: auto;
`;

export const ModalContainer = styled.div`
  min-height: 100vh;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: auto;
  text-align: start;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  img {
    width: 172px;
    height: 152px;
  }
`;

export const Title = styled.h3`
  color: #2d3748;
  margin-bottom: 24px;
  font-weight: 600;

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const Message = styled.p`
  color: #718096;
  font-size: 18px;
  line-height: 1.6;
  margin: 2rem 0;
  font-weight: 400;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  button {
    border-radius: 4px !important;
    &:active {
      background: #000 !important;
    }
  }
`;
