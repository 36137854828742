import React from 'react';
import { IconConfetti } from '@tabler/icons-react';
import Button from '../../../components/Button';
import * as S from './styles';

const SectionLookHow = ({ onClickButton }) => {
  return (
    <S.GrayWrapper>
      <S.Container>
        <S.Title>Veja como é simples se resolver por aqui</S.Title>
        <S.List>
          <S.ListItem>
            <S.Counter>1</S.Counter>
            Digite CPF e data de nascimento
          </S.ListItem>
          <S.ListItem>
            <S.Counter>2</S.Counter>
            <S.VerticalLine />
            Visualize fatura ou débitos em aberto
          </S.ListItem>
          <S.ListItem>
            <S.Counter>3</S.Counter>
            <S.VerticalLine />
            Negocie com descontos exclusivos, se precisar
          </S.ListItem>
          <S.ListItem>
            <S.Counter>
              <IconConfetti />
            </S.Counter>
            <S.VerticalLine />
            Nome limpo já no 1° pagamento!
          </S.ListItem>
        </S.List>
        <Button onClick={onClickButton}>Consultar</Button>
      </S.Container>
    </S.GrayWrapper>
  );
};

export default SectionLookHow;
