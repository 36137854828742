import React from 'react';
import { Image } from '@midway/web-ui-component';
import PropTypes from 'prop-types';
import Look from '~/assets/img/BePartOfIt/look.png';

import * as S from './styles';

const BannerSection = ({ children }) => {
  return (
    <S.ContentContainer className="space-layout" data-testid="banner-scetion">
      <S.ThirdSection>
        <Image
          src={Look}
          alt="Imagem de uma mulher sorrindo olhando diretamente para câmera"
        />
        {children}
      </S.ThirdSection>
    </S.ContentContainer>
  );
};

BannerSection.propTypes = {
  children: PropTypes.any.isRequired,
};

BannerSection.defaultProps = {};

export default BannerSection;
