import { useMutation } from '@tanstack/react-query';

import CollectionApi from '~/services/CollectionApi';
import { usePaymentChannelContext } from '~/context/PaymentChannel';

const TIMES_TO_RETRY = 3;

const useRegister = () => {
  const { updateData } = usePaymentChannelContext();

  const mutationFn = async ({ token, discount, installmentsNumber }) => {
    const discountValueDefault = 0;
    const user = CollectionApi.dynamicChannel();
    const body = {
      discount: {
        chargeRate: discount ? discount.maxChargesRate : discountValueDefault,
        mainRate: discount ? discount.maxMainRate : discountValueDefault,
      },
      authorizationCodeDiscount: 0,
      installmentsNumber,
      paymentToken: token,
      user,
    };

    const response = await CollectionApi.postRegister(body, 'cards');

    if (!response?.detail || response.error) {
      throw new Error(response.message ?? response.error);
    }

    updateData('uuid', response?.detail?.uuid);

    return response?.detail?.uuid;
  };

  return useMutation({
    mutationFn,
    retry: TIMES_TO_RETRY,
  });
};

export default useRegister;
