import React, { useEffect } from 'react';
import * as S from './styles';
import { IconDivide, IconCurrencyDollar } from '@tabler/icons-react';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import moment from 'moment';
import { isEmpty, upperCase } from 'lodash';
import format from '~/services/format';
import { useHistory } from 'react-router-dom';
import {
  lastRangeOverDue,
  midRangeOverDue,
} from '~/pages/PaymentChannel/constants';
import { trackingViewContent, trackingSelectContent } from '~/analytics';
import { flow, contentPage } from '~/analytics/PaymentChannel';
import { trackingOptionsOverdue } from '~/pages/PaymentChannel/helpers';
import { screens } from '~/pages/PaymentChannel/routes';

const ResulmeTypes = () => {
  const navigate = useHistory();
  const { paymentChannelData, updateData } = usePaymentChannelContext();
  const { elegibility, currentInvoiceCard, cardInfo } = paymentChannelData;
  const dueDate = moment(currentInvoiceCard?.invoiceResponse?.dueDate);

  useEffect(() => {
    trackingViewContent(
      `${contentPage.paymentOptions}:${trackingOptionsOverdue(paymentChannelData)}`,
      flow.cards,
      {
        contrato: paymentChannelData?.selected,
      }
    );
  }, [paymentChannelData]);

  const shouldShow = type => {
    const trueType = type === 'debitUpToDate' ? 'debit' : type;
    if (isEmpty(elegibility[trueType])) return false;

    switch (type) {
      case 'invoicement':
        return !cardInfo?.isOverDue || cardInfo?.daysOverdue <= midRangeOverDue;
      case 'debitUpToDate':
        return !cardInfo?.isOverDue && cardInfo?.daysOverdue <= midRangeOverDue;
      case 'debit':
        return cardInfo?.isOverDue && cardInfo?.daysOverdue < lastRangeOverDue;
      case 'acquittance':
        return cardInfo?.isOverDue && cardInfo?.daysOverdue > midRangeOverDue;
      case 'agreement':
        return cardInfo?.daysOverdue > midRangeOverDue;
      default:
        return false;
    }
  };

  const data = [
    {
      title: 'Parcelar fatura(s)',
      description: `Parcele em até ${elegibility?.invoicement?.maxInstallmentNumber}x`,
      icon: <IconDivide />,
      show: shouldShow('invoicement'),
      type: 'invoicement',
      trackingContentType: 'parcelar-fatura',
    },
    {
      title: 'Pagar fatura(s) à vista',
      description: `${format.currency(elegibility?.debit?.totalValue)}`,
      icon: <IconCurrencyDollar />,
      show: shouldShow('debitUpToDate'),
      type: 'debit',
      trackingContentType: 'pagar-fatura-a-vista',
    },
    {
      title: 'Pagar à vista dívida total (em aberto + parcela(s) futura(s))',
      description: `de ${format.currency(elegibility?.acquittance?.totalValue)} por ${format.currency(elegibility?.acquittance?.totalValue - elegibility?.acquittance?.discount?.maxTotal)}`,
      icon: <IconCurrencyDollar />,
      show: shouldShow('acquittance'),
      type: 'acquittance',
      trackingContentType: 'pagar-a-vista-total',
    },
    {
      title: 'Parcelar dívida total (em aberto + parcela(s) futura(s))',
      description: `Parcele em até ${elegibility?.agreement[0]?.maxInstallmentNumber}x`,
      icon: <IconDivide />,
      show: shouldShow('agreement'),
      type: 'agreement',
      trackingContentType: 'parcelar-divida-total',
    },
    {
      title: 'Pagar à vista apenas dívida em aberto',
      description: `${format.currency(elegibility?.debit?.totalValue - elegibility?.debit?.discount?.maxTotal)}`,
      icon: <IconCurrencyDollar />,
      show: shouldShow('debit'),
      type: 'debit',
      trackingContentType: 'pagar-a-vista-apenas-dividas-em-atraso',
    },
  ];

  const handleSelect = item => {
    const { type } = item;
    updateData('typeSelected', type);

    trackingSelectContent(
      item.trackingContentType,
      flow.cards,
      `${contentPage.paymentOptions}:${trackingOptionsOverdue(paymentChannelData)}`,
      {
        contrato: paymentChannelData?.selected,
      }
    );

    if (type === 'debit' || type === 'acquittance')
      return navigate.push(screens.RESUME_PAYMENT);
    if (type === 'agreement' || type === 'invoicement')
      return navigate.push(screens.INSTALLMENTS);
  };

  const totalValue = () => {
    if (!cardInfo?.isOverDue || cardInfo?.daysOverdue <= 77) {
      return format.currency(elegibility.debit.totalValue);
    }
    const { mainValue, interestValue, financialProductsValue } =
      elegibility.acquittance;
    return format.currency(mainValue + interestValue + financialProductsValue);
  };

  return (
    <S.Wrapper>
      <S.ContainerContent>
        <S.Title>
          {cardInfo?.isOverDue ? 'Pagamento do cartão' : 'Pagamento da fatura'}
        </S.Title>
        <S.ContainerDetails>
          <S.Flex alignItems="left">
            <S.Tag warning={!cardInfo?.isOverDue}>
              {cardInfo?.isOverDue
                ? `${cardInfo?.daysOverdue} dia(s) em atraso`
                : `Em dia`}
            </S.Tag>
            <S.Flex alignItems="flex-start">
              {!cardInfo?.isOverDue ? (
                <>
                  <span>
                    Vencimento {upperCase(moment(dueDate).format('D MMM'))}
                  </span>
                  <span>
                    Fechamento{' '}
                    {upperCase(
                      moment(
                        currentInvoiceCard?.cardDateDetail?.bestDayPurchase
                      ).format('D MMM')
                    )}
                  </span>
                </>
              ) : (
                <></>
              )}
            </S.Flex>
          </S.Flex>
          <S.Flex>
            <S.Flex alignItems="flex-end" align="end">
              <span>
                {cardInfo?.isOverDue ? 'Valor em aberto' : 'Valor a pagar'}
              </span>
              <S.Bold size={18}>{totalValue()}</S.Bold>

              {cardInfo?.daysOverdue >= 78 && (
                <>
                  <span>Parcela(s) futura(s)</span>
                  <span>
                    {format.currency(elegibility.acquittance.balanceFuture)}
                  </span>
                </>
              )}
            </S.Flex>
          </S.Flex>
        </S.ContainerDetails>

        {data.map((item, idx) => {
          if (!item.show) return null;
          const discountTotal =
            elegibility[item.type]?.discount?.maxTotal || null;

          return (
            <S.CardContainer
              key={item.id || item.type}
              onClick={() => {
                handleSelect(item);
              }}
            >
              {discountTotal && idx === 2 && (
                <S.HeaderBar>
                  Economize {format.currency(discountTotal)}
                </S.HeaderBar>
              )}
              <S.Content>
                <S.Flex direction="row" alignItems="center">
                  {item.icon}
                  <S.Flex alignItems="flex-start">
                    <span>{item.title}</span>
                    <span>{item.description}</span>
                  </S.Flex>
                </S.Flex>
                <S.IconChevronRight />
              </S.Content>
            </S.CardContainer>
          );
        })}
      </S.ContainerContent>
    </S.Wrapper>
  );
};

export default ResulmeTypes;
