import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

import { mockData } from './data';

const GridCards = () => {
  const [gridCardsData, setGridCardsData] = useState([]);

  const handleGetGridCardsProductsFinancial = useCallback(async () => {
    try {
      const response = await cmsService.getGridCardsProductsFinancial(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setGridCardsData(response);
        return;
      }
      setGridCardsData(mockData);
    } catch (error) {
      setGridCardsData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetGridCardsProductsFinancial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetGridCardsProductsFinancial]);

  useEffect(() => {
    handleGetGridCardsProductsFinancial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetGridCardsProductsFinancial]);

  const handleButton = linkTitle => {
    const formattedString = linkTitle.toLowerCase().replace(/\s+/g, '-');
    trackingSelectContent(formattedString, 'produtos-financeiros');
  };

  return (
    <S.Container>
      {gridCardsData.map((item, index) => (
        <S.Content key={index} data-testid="content">
          <S.TitleContainer data-testid="title-container">
            {item.name}
          </S.TitleContainer>
          <S.Line />
          <S.ContentCards data-testid="content-cards">
            {item.children.map((children, childIndex) => (
              <S.Cards key={childIndex}>
                <S.Img src={children.img} />
                <S.GroupsContent>
                  <S.Title data-testid="card-title">{children.title}</S.Title>
                  <S.SubTitle data-testid="card-subTitle">
                    {children.subTitle}
                  </S.SubTitle>
                  <S.LineCards />
                  <S.Description data-testid="card-description">
                    {children.description}
                  </S.Description>
                  <S.Button
                    data-testid="button"
                    href={children.link}
                    onClick={() => handleButton(children.linkTitle)}
                  >
                    {children.linkTitle}
                  </S.Button>
                </S.GroupsContent>
              </S.Cards>
            ))}
          </S.ContentCards>
        </S.Content>
      ))}
    </S.Container>
  );
};
export default GridCards;
