import styled, { css } from 'styled-components';
import OriginalBadge from '~/pages/PaymentChannel/components/Badge';

export const Wrapper = styled.div`
  border-radius: 8px;
  padding: 24px 16px;
  background-color: #fff;

  .custom-button {
    background: #9c162d;
    border-radius: 20px;
    font-weight: 500;
    border-color: #9c162d;
    &:hover {
      background-color: #fff;
      border: 1px solid #9c162d;
      color: #9c162d;
    }
    &:active {
      border-color: #9c162d;
      background: #7a0f23 !important;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: #221f20;
`;

export const Money = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #221f20;
`;

export const Subtext = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: #646464;
  flex-basis: 100%;
`;

export const Separator = styled.hr`
  border-bottom: 1px solid #e0e0e0;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const Badge = styled(OriginalBadge)`
  margin-bottom: 12px;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Installments = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  color: #221f20;
  margin-bottom: 8px;

  small {
    font-size: 1.125rem;
    font-weight: inherit;
    color: inherit;
  }
`;

export const AlternativeInstallments = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #646464;
`;

export const ButtonsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const Button = styled.button`
  border-radius: 25px;
  padding: 10px 14px;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  background: transparent;
  border: 1px solid #221f20;
  color: #221f20;
  flex: 1;

  ${props => {
    if (props.$mode === 'filled') {
      return css`
        background: #221f20;
        color: #ffffff;
      `;
    }
    if (props.$mode === 'red') {
      return css`
        background: transparent;
        border: 1px solid #9c162d;
        color: #9c162d;
      `;
    }
    if (props.$mode === 'red-filled') {
      return css`
        background: #9c162d;
        border: 1px solid #9c162d;
        color: #ffffff;
      `;
    }
  }}

  &.disabled, &:disabled {
    background: transparent;
    border: 1px solid #959d9d;
    color: #959d9d;

    ${props => {
      if (props.$mode === 'filled' || props.$mode === 'red-filled') {
        return css`
          background: #959d9d;
          color: #ffffff;
        `;
      }
    }}
  }
`;

export const Label = styled.span`
  font-size: 14px;
  color: #666;
`;

export const Value = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-left: 4px;
`;

export const SavingsSection = styled.div`
  text-align: center;
  margin-bottom: 16px;
  padding-top: 16px;
`;

export const SavingsHighlight = styled.div`
  background-color: #d4f8d8;
  color: #2d8c2f;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 16px;
  display: inline-block;
  margin-bottom: 8px;
`;

export const TotalText = styled.div`
  font-size: 14px;
  color: #666;
`;

export const FinalTotal = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
