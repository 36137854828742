import React, { useState, useEffect } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import DOMPurify from 'dompurify';
import { trackingSelectContent } from '~/analytics';
import { slugify } from '~/utils/utils';
import * as S from './styles';

const FeaturedPartners = ({ partners }) => {
  const [selected, setSelected] = useState(partners[0]);

  useEffect(() => {
    const contentType = `parceiros-em-destaque:${slugify(selected.title)}`;
    trackingSelectContent(contentType, 'beneficios');
  }, [selected]);

  const renderPartners = () => {
    return partners.map(partner => {
      const onClick = () => setSelected(partner);
      const active = partner === selected;

      return (
        <S.LogoWrapper key={partner.id} onClick={onClick} active={active}>
          <S.LogoImage
            src={partner.icon}
            alt={partner.title}
            resizeMode="contain"
            as="img"
          />
        </S.LogoWrapper>
      );
    });
  };

  const navigateClickHandler = () => {
    const isFarmacyPartner = selected.categories.includes('farmacia');
    const contentType = `parceiros-em-destaque:${slugify(selected.title)}:${
      isFarmacyPartner ? 'encontrar-lojas' : 'ir-para-site-parceiro'
    }`;
    trackingSelectContent(contentType, 'beneficios');
    let path = selected.link;

    if (isFarmacyPartner) path += '/lojas';
    window.open(path, '_blank');
  };

  const renderContent = () => {
    if (!selected) return null;

    const isFarmacyPartner = selected.categories.includes('farmacia');

    return (
      <S.ContentContainer>
        <S.Title>Como usar o benefício?</S.Title>
        <S.Text
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(selected.description),
          }}
        />
        {selected.rules.map((rule, index) => (
          <S.Rule key={index}>{rule}</S.Rule>
        ))}
        <S.NavigateButton onClick={navigateClickHandler}>
          {isFarmacyPartner ? 'Encontrar lojas' : 'Ir para site parceiro'}
          <IconChevronRight />
        </S.NavigateButton>
      </S.ContentContainer>
    );
  };

  return (
    <S.Container>
      <S.IconsContainer>{renderPartners()}</S.IconsContainer>
      {renderContent()}
    </S.Container>
  );
};

export default FeaturedPartners;
