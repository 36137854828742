import React from 'react';
import { ArrowIosBackOutline } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import * as S from './styles';

const ProgressHeader = ({
  onBack,
  hideProgressBar = false,
  progress = null,
}) => {
  const showProgressBar = !hideProgressBar && progress !== null;

  return (
    <S.HeaderWrapper>
      <S.Content>
        <S.IconButton onClick={onBack} aria-label="Back">
          <ArrowIosBackOutline size={20} />
        </S.IconButton>
        {showProgressBar && (
          <S.ProgressBarContainer>
            <S.ProgressBar progress={progress} />
          </S.ProgressBarContainer>
        )}
      </S.Content>
    </S.HeaderWrapper>
  );
};

export default ProgressHeader;
