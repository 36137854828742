import React from 'react';
import {
  IconDeviceMobile,
  IconDownload,
  IconConfetti,
  IconSearch,
  IconBuildingStore,
} from '@tabler/icons-react';
import HowToAskImg from '~/assets/img/CardBenefits/how-to-ask.png';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const HowToAsk = () => {
  const buttonClickHandler = target => {
    window.open(target, '_blank');
  };

  return (
    <>
      <S.Container>
        <S.IntroContainer>
          <S.Title>Como pedir o seu Cartão Riachuelo</S.Title>
          <S.Text>
            Para ter o Cartão Mastercard, você precisa primeiro solicitar o
            Cartão Riachuelo através dos seguintes canais:
          </S.Text>
        </S.IntroContainer>
      </S.Container>
      <S.GrayContainer>
        <S.ImageContainer>
          <S.OrangeDetail />
          <S.HowToAskImage
            src={HowToAskImg}
            draggable="false"
            alt="Parabéns! Seu Cartão Riachuelo já está aprovado. Aproveite agora mesmo e comece a acumular cashback e outros benefícios exclusivos."
          />
        </S.ImageContainer>
        <S.Subtitle>
          <IconDeviceMobile />
          Em nosso aplicativo Riachuelo
        </S.Subtitle>
        <S.ListContainer>
          <S.List>
            <S.ListItem>
              <S.Counter>1</S.Counter>
              <div>
                Baixe o App Riachuelo e faça login com seu usuário e senha ou
                cadastre-se;
                <S.Button
                  onClick={() => {
                    trackingSelectContent(
                      'baixar-o-aplicativo-riachuelo',
                      'beneficios'
                    );
                    buttonClickHandler(
                      'https://blog.riachuelo.com.br/baixe-o-app/?gad_source=1&gclid=Cj0KCQjw2a6wBhCVARIsABPeH1smQfcDaxSyE9kD8DWMorsKIQzkueSnsZRMPVoWa-8ZAzeTPhgT5N8aAoUBEALw_wcB'
                    );
                  }}
                >
                  <IconDownload />
                  Baixar o aplicativo Riachuelo
                </S.Button>
              </div>
            </S.ListItem>
            <S.ListItem>
              <S.Counter>2</S.Counter>
              <S.VerticalLine />
              Acesse a aba Cartões e solicite o seu Cartão Riachuelo através do
              banner exibido em tela;
            </S.ListItem>
            <S.ListItem>
              <S.Counter>3</S.Counter>
              <S.VerticalLine />
              Siga os passos exigidos para cadastro como preenchimento de dados
              e validação facial;
            </S.ListItem>
            <S.ListItem>
              <S.Counter>
                <IconConfetti />
              </S.Counter>
              <S.VerticalLine />
              Pronto! Seus dados serão analisados e avisaremos quando seu cartão
              for aprovado!
            </S.ListItem>
          </S.List>
        </S.ListContainer>
      </S.GrayContainer>
      <S.GrayContainer>
        <S.Subtitle>
          <IconBuildingStore />
          Em nossas lojas físicas
        </S.Subtitle>
        <S.ListContainer>
          <S.List>
            <S.ListItem>
              <S.Counter>1</S.Counter>
              <div>
                Encontre uma loja mais próxima de você;
                <S.Button
                  onClick={() => {
                    trackingSelectContent(
                      'encontrar-loja-mais-proxima',
                      'beneficios'
                    );
                    buttonClickHandler(
                      'https://www.midway.com.br/buscar-lojas'
                    );
                  }}
                >
                  <IconSearch />
                  Encontrar loja mais próxima
                </S.Button>
              </div>
            </S.ListItem>
            <S.ListItem>
              <S.Counter>2</S.Counter>
              <S.VerticalLine />
              Ao chegar na loja, fale com nossa equipe de atendimento e peça
              para fazer seu cartão!
            </S.ListItem>
            <S.ListItem>
              <S.Counter>
                <IconConfetti />
              </S.Counter>
              <S.VerticalLine />
              Pronto! Nossa equipe está preparada para iniciar o processo e, se
              aprovado, você já pode usar seu cartão em loja.
            </S.ListItem>
          </S.List>
        </S.ListContainer>
      </S.GrayContainer>
    </>
  );
};

export default HowToAsk;
