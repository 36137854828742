import OAuthColletion from './OAuthCollection';
import instance from './NegotiationCampaignInstance';

const headers = {};

const token = async () => {
  const OAuthToken = await OAuthColletion.getToken();
  headers['x-app-token'] = OAuthToken?.IdToken;
};
const getCurrentInvoiceCard = async (document, birthDate) => {
  const uri = '/v1/portal/bff/current-invoice-card';
  await token();

  return instance
    .get(uri, {
      headers: {
        ...headers,
        'document-number': document,
        'birth-date': birthDate,
      },
    })
    .then(response => response?.data)
    .catch(err => err?.response?.data);
};

const getCardInvoices = async document => {
  const uri = `/v1/portal/bff/card-invoices`;
  await token();

  return instance
    .get(uri, { headers: { ...headers, 'document-number': document } })
    .then(response => response?.data)
    .catch(err => err?.response?.data);
};

const getCardInvoicesDetail = async (document, dueDate) => {
  const uri = `/v1/portal/bff/card-invoices-detail`;
  await token();

  return instance
    .get(uri, {
      headers: { ...headers, 'document-number': document, 'due-date': dueDate },
    })
    .then(response => response?.data)
    .catch(err => err?.response?.data);
};

export default {
  getCurrentInvoiceCard,
  getCardInvoices,
  getCardInvoicesDetail,
};
