import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import Card from './components/Card';
import { banner } from './data';
import RenderBannerImage from '~/components/RenderBannerImage';
import TextPhoneNumber from '~/components/TextPhoneNumber';
import GridCards from './components/GridCards';
import AssistanceCarrousel from './components/AssistanceCarrousel';

import PetImgCardOne from '~/assets/img/Financial/protected-home-insurance/pet-assistance-card1.svg';
import PetImgCardTwo from '~/assets/img/Financial/protected-home-insurance/pet-assistance-card2.svg';
import PetImgCardThree from '~/assets/img/Financial/protected-home-insurance/pet-assistance-card3.svg';
import PetImgCardFour from '~/assets/img/Financial/protected-home-insurance/pet-assistance-card4.svg';
import PetImgCardFive from '~/assets/img/Financial/protected-home-insurance/pet-assistance-card5.svg';
import PetImgCardSix from '~/assets/img/Financial/protected-home-insurance/pet-assistance-card6.svg';

import ElectricalIcon from '~/assets/img/Financial/protected-home-insurance/icon-electrical-damage.svg';
import FamilyIcon from '~/assets/img/Financial/protected-home-insurance/icon-family.svg';
import DiamondsIcon from '~/assets/img/Financial/protected-home-insurance/icon-diamonds.svg';
import GaleIcon from '~/assets/img/Financial/protected-home-insurance/icon-gale.svg';
import GlassBreakageIcon from '~/assets/img/Financial/protected-home-insurance/icon-glass-breakage.svg';
import SofaIcon from '~/assets/img/Financial/protected-home-insurance/icon-sofa.svg';

import ButtonFull from '~/assets/img/Financial/ProtectionInsurance/button-full.svg';
import ZurichLogo from '~/assets/img/Financial/account-paid-insurance/zurich-logo.svg';
import { trackingSelectContent } from '~/analytics';

const PHONE_CAPITALS_AND_REGIONS = '3004 5417';
const PHONE_OTHER_LOCATIONS = '0800 727 4417';
const PHONE_SUPPORT_CAPITALS_AND_REGIONS = ' 4020 4848 ';
const PHONE_SUPPORT_OTHER_LOCATIONS = ' 0800 085 8986 ';
const HEARING_IMPAIRED = '0800 275 8585';
const SAC = ' 0800 284 4848 ';
const SERVICE = '0800 770 1061';

const Anchor = ({ href, children, onClick }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor onClick={onClick} href={href} target="_blank">
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

const CardWrapper = ({ title, icon, widthCard, href, onClick }) => {
  return (
    <S.ContainerCardWrapper>
      <S.CardWrapper minWidth={widthCard}>
        <S.CardAnchor href={href} onClick={onClick} target="_blank">
          <S.Title>{title}</S.Title>
          <S.Icon src={icon} />
        </S.CardAnchor>
      </S.CardWrapper>
    </S.ContainerCardWrapper>
  );
};

const ProtectedHomeInsurance = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerProtectedHome(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banners = bannerData[0] || banner;

  const handleClickSummaryProtectedHomeInsurance = () => {
    trackingSelectContent(
      'condicoes-gerais-na-integra-do-seguro-lar-protegido',
      'seguro-conta-paga'
    );
  };

  const handleClickSummaryGeneralConditionsProtectedHomeInsurance = () => {
    trackingSelectContent(
      'resumo-das-condicoes-gerais-do-seguro-lar-protegido',
      'seguro-conta-paga'
    );
  };

  const handleClickGeneralConditionsFamilyCivilLiability = () => {
    trackingSelectContent(
      'condicoes-gerais-responsabilidade-civil-familiar',
      'seguro-conta-paga'
    );
  };

  const handleServiceZurich = () => {
    trackingSelectContent('central-de-atendimento-zurich', 'seguro-conta-paga');
  };

  const handleVirtualAssistantZurich = () => {
    trackingSelectContent(
      'fale-com-a-lais-assistente-virtual-zurich',
      'seguro-conta-paga'
    );
  };

  const handleClickLinkContentFinal = () => {
    trackingSelectContent('www.consumidor.gov.br', 'seguro-conta-paga');
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banners} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>Seguro Lar Protegido</S.TitleCards>
            <S.subTitleCards>
              Sua casa, sua conquista. Deixe ela sempre protegida. Conheça
              nossas coberturas.
            </S.subTitleCards>
          </S.AdjustamentTitleCard>

          <S.ContainerCards>
            <Card
              icon={ElectricalIcon}
              title="Danos elétricos"
              description="Proteção contra danos causados por falhas elétricas, como curtos-circuitos e surtos de energia."
            />
            <Card
              icon={FamilyIcon}
              title="Responsabilidade civil familiar"
              description="Cobertura para danos involuntários causados a terceiros pelo segurado ou membros da família."
            />
            <Card
              icon={DiamondsIcon}
              title="Roubo e furto de bens"
              description="Garantia contra perdas de bens em caso de roubo ou furto qualificado."
            />
            <Card
              icon={GaleIcon}
              title="Vendaval"
              description=" Cobertura para danos materiais causados por ventos fortes, ciclones ou tornados."
            />
            <Card
              icon={GlassBreakageIcon}
              title="Quebra de vidros"
              description="Proteção para consertos ou substituição de vidros quebrados em janelas ou portas."
            />
            <Card
              icon={SofaIcon}
              title="Incêndio, raio e explosão"
              description="Cobertura para danos estruturais provocados por incêndios, raios ou explosões."
            />
          </S.ContainerCards>

          <S.AssistanceContainer>
            <S.TitleCards>Assistências</S.TitleCards>
            <S.subTitleCards>
              Sua casa, sua conquista. Deixe ela sempre protegida.
              <br />
              Conheça nossas assistências e fique tranquilo quanto a
              imprevistos.
            </S.subTitleCards>
            <S.AssistanceCarrouselContainer>
              <AssistanceCarrousel />
            </S.AssistanceCarrouselContainer>
          </S.AssistanceContainer>

          <S.AdjustamentTitleCardPetAssistance>
            <S.TitleCards>Assistência Pet</S.TitleCards>
            <S.subTitleCards>
              Com a Assistência Pet, você garante o cuidado e proteção para o
              seu animal de estimação. Tenha acesso a diversos serviços que
              oferecem conveniência e segurança para o seu pet, sempre que
              precisar.
            </S.subTitleCards>
            <S.ContainerCardsPetAssistance>
              <GridCards
                img={PetImgCardOne}
                title="Hospedagem de animais"
                description="Cobre despesas com hospedagem em caso de acidente ou doença que impeça o cliente de cuidar do pet."
              />
              <GridCards
                img={PetImgCardTwo}
                title="Vacinas em domicílio"
                description="Veterinário cadastrado vai à residência do cliente para aplicar vacina. "
              />
              <GridCards
                img={PetImgCardThree}
                title="Transporte emergencial pet"
                description="Remoção de pet em situação de urgência."
              />
              <GridCards
                img={PetImgCardFour}
                title="Consultas veterinárias"
                description="Disponibiliza consultas veterinárias, exceto por urgências."
              />
              <GridCards
                img={PetImgCardFive}
                title="Informações veterinárias"
                description="Indicação de serviços veterinários úteis."
              />
              <GridCards
                img={PetImgCardSix}
                title="Assistência funeral pet"
                description="Oferece cremação ou sepultamento, incluindo urna e certificado."
              />
            </S.ContainerCardsPetAssistance>
          </S.AdjustamentTitleCardPetAssistance>

          <S.AdditionalInfoCard>
            <S.AdditionalInfoText>
              *Consulte as condições do seu plano para disponibilidade e
              utilização das coberturas e assistências
              <br />
              **Assistência pet disponível para o Plano Total + Pet
            </S.AdditionalInfoText>
            <S.CardWrapperText>
              <S.CardWrapperUnderline
                data-testid="general-conditions"
                href="https://midway.com.br/static/documents/tags/condicoes-particulares/condicao-geral-residencial-n-154140031062009-17-vigencia02062021.pdf"
                target="_blank"
                onClick={handleClickSummaryProtectedHomeInsurance}
              >
                Condições gerais na íntegra do Seguro Lar Protegido
              </S.CardWrapperUnderline>
              <S.CardWrapperUnderline
                data-testid="summary-of-general-conditions"
                href="https://midway.com.br/static/documents/tags/condicoes-particulares/2024.09.26-resumo-condicao-geral.pdf"
                target="_blank"
                onClick={
                  handleClickSummaryGeneralConditionsProtectedHomeInsurance
                }
              >
                Resumo das condições gerais do Seguro Lar Protegido
              </S.CardWrapperUnderline>
              <S.CardWrapperUnderline
                data-testid="general-family-civil-conditions"
                href="https://midway.com.br/static/documents/tags/condicoes-particulares/condicao-geral-residencial-rc-familiar-n-154149019492013-67-vigencia08012021.pdf"
                target="_blank"
                onClick={handleClickGeneralConditionsFamilyCivilLiability}
              >
                Condições gerais responsabilidade civil familiar
              </S.CardWrapperUnderline>
            </S.CardWrapperText>
          </S.AdditionalInfoCard>

          <S.ContainerCardsZurich>
            <S.ContentCardWrapper>
              <CardWrapper
                title="Central de atendimento Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/atendimento"
                onClick={handleServiceZurich}
              />
              <CardWrapper
                widthCard="531px"
                title="Fale com a Laiz - Assistente virtual Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/chatonline"
                onClick={handleVirtualAssistantZurich}
              />
            </S.ContentCardWrapper>
          </S.ContainerCardsZurich>

          <S.ContentText>
            <S.TextContent>
              Você também pode contratar na loja Riachuelo mais próxima ou, se
              preferir, pode optar pelas centrais de atendimento através dos
              telefones:
            </S.TextContent>
            <TextPhoneNumber
              text="Capitais e regiões metropolitanas:"
              phoneNumber={PHONE_CAPITALS_AND_REGIONS}
            />
            <TextPhoneNumber
              text="Demais localidades:"
              phoneNumber={PHONE_OTHER_LOCATIONS}
            />
          </S.ContentText>

          <S.ContainerCardBlackBottom>
            <S.CardBlackBottomTitle>
              Para utilizar o Seguro
            </S.CardBlackBottomTitle>
            <S.CardBlackBottomText>
              Acompanhe seu sinistro de forma rápida e prática acessando o site
              :
              <S.UnderlineText>
                www.zurich.com.br/pt-br/atendimento
              </S.UnderlineText>
            </S.CardBlackBottomText>
            <S.CardBlackBottomText paddingTop="20px">
              Ligue para: {PHONE_SUPPORT_CAPITALS_AND_REGIONS}
              capitais e regiões metropolitanas ou <br />{' '}
              {PHONE_SUPPORT_OTHER_LOCATIONS}
              de segunda à sexta-feira, das 8h às 20h e aos sábados das 8h às
              18h.
            </S.CardBlackBottomText>
            <S.CardBlackBottomText paddingTop="20px">
              SAC{SAC} - atendimento 24h. <br />
              Deficiente Auditivo: {HEARING_IMPAIRED} <br />
              Ouvidoria: {SERVICE} <br />
            </S.CardBlackBottomText>
          </S.ContainerCardBlackBottom>

          <S.ContentZurich>
            <S.ZurichLeft src={ZurichLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                Fundada na Suíça em 1872, com sede na cidade de Zurique, somos
                uma das seguradoras globais mais experientes do mundo. Com cerca
                de 56 mil funcionários, oferecemos uma ampla gama de produtos e
                serviços de ramos elementares e de vida em mais de 210 países e
                territórios. Atendemos pessoas físicas e jurídicas – de pequenas
                empresas a multinacionais.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>

          <S.ContentFinal>
            <S.ContentTextFinal>
              Seguro garantido pela Zurich Minas Brasil Seguros S.A. CNPJ:
              17197.385/0001-21 – Código SUSEP: 05495, processo SUSEP
              Residencial: 15414.003106/2009-17 e Processo SUSEP
              Responsabilidade Civil: 15414.901949/2013-67. Consulte a íntegra
              das Condições Gerais do Seguro em www.zurich.com.br. O registro do
              produto é automático e não representa aprovação ou recomendação
              por parte da SUSEP. Central de Atendimento Zurich – SAC: 0800 254
              4848. Deficiente auditivo: 0800275 8585, horário de atendimento:
              24 horas, 7 dias por semana. Ouvidoria: 0800 770 1061. O seguro é
              opcional e é proibido condicionar desconto no preço à aquisição do
              seguro. Link da plataforma digital oficial para registro de
              reclamações dos consumidores dos mercados supervisionados:
              <Anchor
                href="https://www.consumidor.gov.br"
                onClick={handleClickLinkContentFinal}
                fontSize="16px"
              >
                www.consumidor.gov.br.
              </Anchor>
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default ProtectedHomeInsurance;
