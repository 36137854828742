import React from 'react';
import Accordion from '../../../components/Accordion';
import Button from '../../../components/Button';
import * as S from './styles';

const SectionFaq = ({ onClickButton }) => {
  const categories = [
    {
      title: 'Sobre Dívidas',
      questions: [
        {
          question: 'Consigo desconto para negociar minha dívida?',
          answer:
            'Sim! Além de poder parcelar em até 24x*, existe a possibilidade de você obter descontos de até 90%* para pagar o débito em atraso. *Mediante condições.',
        },
        {
          question: 'Posso parcelar a minha fatura?',
          answer:
            'Sim, você pode parcelar sua fatura por aqui, pelo app RCHLO ou pelo app Midway, além de ter a disposição a nossa central de relacionamento. É só ligar para 3004 5417 ou 0800 727 4417',
        },
        {
          question:
            'Posso utilizar meu cartão após quitar ou parcelar minha dívida?',
          answer:
            'Sim! Após quitar a sua fatura Riachuelo, o seu cartão pode ser desbloqueado para novas compras. Dependendo do tempo em atraso da dívida, pode ser necessária uma nova avaliação de crédito. Para mais detalhes, é só ligar para a nossa central de relacionamento, nos números 3004 5417 ou 0800 727 4417.',
        },
        {
          question: 'Como pagar a fatura do Cartão Riachuelo?',
          answer:
            'Sim, você consegue gerar a 2ª via do boleto por aqui. Caso precise, você também pode contar com a nossa central de relacionamento nos números 3004 5417 ou 0800 727 4417',
        },
      ],
    },
  ];

  const Category = ({ category }) => {
    return (
      <S.CategoryContainer>
        <S.CategoryTitle>{category.title}</S.CategoryTitle>
        <S.QuestionsContainer>
          {category.questions.map((q, i) => (
            <Accordion title={q.question} key={i}>
              {q.answer}
            </Accordion>
          ))}
        </S.QuestionsContainer>
      </S.CategoryContainer>
    );
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>Perguntas frequentes</S.Title>
        <S.CategoriesContainer>
          {categories.map((category, i) => (
            <Category category={category} key={i} />
          ))}
        </S.CategoriesContainer>
        <Button onClick={onClickButton}>Consultar</Button>
      </S.Container>
    </S.Wrapper>
  );
};

export default SectionFaq;
