import React from 'react';
import HeaderMain from '../HeaderMain';
import HeaderProgress from '../HeaderProgress';
import { screens } from '~/pages/PaymentChannel/routes';
import { useHistory } from 'react-router-dom';

const Header = () => {
  const navigate = useHistory();
  const pathName = navigate?.location?.pathname;

  const screensAtributes = {
    [screens.HOME]: {
      showHomeHeader: true,
      showProgressHeader: false,
      progressIndex: null,
    },
    [screens.DUPLICATE]: {
      showHomeHeader: true,
      showProgressHeader: true,
      progressIndex: null,
    },
    [screens.CONTRACTS]: {
      showHomeHeader: true,
      showProgressHeader: true,
      progressIndex: null,
    },
    [screens.CARDS_HOME]: {
      showHomeHeader: true,
      showProgressHeader: true,
      progressIndex: null,
    },
    [screens.RESUME_TYPES]: {
      showHomeHeader: true,
      showProgressHeader: true,
      progressIndex: 1,
    },
    [screens.INSTALLMENTS]: {
      showHomeHeader: true,
      showProgressHeader: true,
      progressIndex: 1,
    },
    [screens.RESUME_PAYMENT]: {
      showHomeHeader: true,
      showProgressHeader: true,
      progressIndex: 2,
    },
    [screens.GENERATED_PAYMENT]: {
      showHomeHeader: true,
      showProgressHeader: true,
      progressIndex: 3,
    },
  };

  const getProgressIndex = () => {
    return screensAtributes[pathName]?.progressIndex;
  };

  const getProgress = () => {
    const withInstallments = navigate?.entries.includes(screens.INSTALLMENTS);
    let total = 3;
    let index = getProgressIndex();

    if (index === null) return null;

    if (withInstallments) {
      total += 1;
      index += 1;
    }

    return Math.round((index / total) * 100);
  };

  const showHomeHeader = screensAtributes[pathName]?.showHomeHeader;
  const showProgressHeader = screensAtributes[pathName]?.showProgressHeader;

  const onBackHandler = () => {
    if (pathName === screens.DUPLICATE) {
      return navigate.go(-2);
    }
    if (pathName === screens.GENERATED_PAYMENT) {
      return navigate.replace('/');
    }
    return navigate.go(-1);
  };

  return (
    <>
      {showHomeHeader && <HeaderMain />}
      {showProgressHeader && (
        <HeaderProgress progress={getProgress()} onBack={onBackHandler} />
      )}
    </>
  );
};

export default Header;
