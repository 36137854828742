import { useQuery } from '@tanstack/react-query';
import PaymentChannelApi from '~/services/PaymentChannelApi';
import { usePaymentChannelContext } from '~/context/PaymentChannel';

const useCurrentInvoiceCard = ({ document, birthDate }) => {
  const { updateData } = usePaymentChannelContext();

  const queryFn = async ({ document, birthDate }) => {
    const response = await PaymentChannelApi.getCurrentInvoiceCard(
      document,
      birthDate
    );

    if (!response || response.error) {
      throw new Error(response.message ?? response.error);
    }

    updateData('currentInvoiceCard', response);

    return response;
  };

  return useQuery([document, birthDate], {
    queryFn: () => queryFn({ document, birthDate }),
    enabled: Boolean(document && birthDate),
    refetchOnWindowFocus: false,
  });
};

export default useCurrentInvoiceCard;
