import React from 'react';
import { trackingSelectContent } from '~/analytics';

import * as S from './styles';

const LogosMenu = () => {
  return (
    <S.Wrapper>
      <S.Nav>
        <S.List>
          <S.ListItem className="header-logo">
            <S.StyledLink tabIndex={0} id="midway-logo" to="/">
              Midway
            </S.StyledLink>
          </S.ListItem>
          <S.ListItem
            onClick={() => trackingSelectContent('mega-menu:riachuelo', 'home')}
          >
            <S.StyledLink
              tabIndex={0}
              id="rchlo-logo"
              to={{ pathname: 'http://www.riachuelo.com.br' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Riachuelo
            </S.StyledLink>
          </S.ListItem>
          <S.ListItem>
            <S.StyledLink
              onClick={() =>
                trackingSelectContent('mega-menu:casa-riachuelo', 'home')
              }
              tabIndex={0}
              id="casa-logo"
              to={{
                pathname:
                  'https://www.riachuelo.com.br/campanha/r/lp/casa/colecao',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Casa Riachuelo
            </S.StyledLink>
          </S.ListItem>
          <S.ListItem>
            <S.StyledLink
              onClick={() => trackingSelectContent('mega-menu:carters', 'home')}
              tabIndex={0}
              id="carters-logo"
              to={{
                pathname:
                  'https://www.riachuelo.com.br/campanha/r/marca/carters',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Carters
            </S.StyledLink>
          </S.ListItem>
          <S.ListItem>
            <S.StyledLink
              onClick={() => trackingSelectContent('mega-menu:fanlab', 'home')}
              tabIndex={0}
              id="fan-logo"
              to={{
                pathname:
                  'https://www.fanlab.com.br/?utm_source=riachuelo&utm_medium=web&utm_campaign=topo-multisites&utm_term=topo-multisites-fun-lab_riachuelo-web',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Fan lab
            </S.StyledLink>
          </S.ListItem>
          <S.ListItem>
            <S.StyledLink
              onClick={() => trackingSelectContent('mega-menu:blog', 'home')}
              tabIndex={0}
              id="blog-logo"
              to={{ pathname: 'https://blog.riachuelo.com.br/' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              .blog
            </S.StyledLink>
          </S.ListItem>
        </S.List>
      </S.Nav>
    </S.Wrapper>
  );
};

export default LogosMenu;
