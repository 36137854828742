import styled from 'styled-components';
import { IconChevronRight as _IconChevronRight } from '@tabler/icons-react';
import { ContentContainer, ContentTitle } from '~/pages/PaymentChannel/styles';

export const Wrapper = styled.div`
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  background-color: #f5f5f5;
`;

export const ContainerContent = styled(ContentContainer)``;

export const Title = styled(ContentTitle)`
  margin-bottom: 24px;
`;

export const ContainerDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  text-align: ${({ align }) => align};
  align-items: ${({ alignItems }) => alignItems};
  margin-top: ${({ top }) => top}px;
  justify-content: space-between;

  svg {
    margin-right: 1rem;
  }
`;

export const Tag = styled.span`
  width: max-content;
  padding: 4px 8px;
  background: ${({ warning }) => (warning ? '#FFE7A8' : '#edabb6')};
  border-radius: 8px;
`;

export const Bold = styled.span`
  font-weight: bold;
  font-size: ${({ size }) => size}px;
`;

export const CardContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 16px 0;
  cursor: pointer;
`;

export const IconChevronRight = styled(_IconChevronRight)`
  width: 16px;
  flex-basis: 16px;
  height: 19px;
`;

export const HeaderBar = styled.div`
  background-color: #28a745; /* Verde */
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 6px 0;
  font-size: 0.875rem;
`;

export const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
