import React from 'react';
import { useDispatch } from 'react-redux';
import { showCallToAction } from '~/store/modules/calltoaction/actions';
import CallToAction from '~/components/CallToAction';
import { trackingSelectContent } from '~/analytics';

import Midway from '~/assets/img/Financial/financial-products/ilustracao.svg';
import GiftIconCoin from '~/assets/img/Financial/financial-products/coin.svg';
import GiftIconMobile from '~/assets/img/Financial/financial-products/mobile.svg';
import GiftIconBuilding from '~/assets/img/Financial/financial-products/building.svg';
import GiftIconBusinessplan from '~/assets/img/Financial/financial-products/businessplan.svg';
import GiftIconPix from '~/assets/img/Financial/financial-products/pix.svg';
import GiftIconGift from '~/assets/img/Financial/financial-products/gift.svg';
import * as S from './styles';

const BlockOptions = ({ icon, description }) => {
  return (
    <S.ContainerBlock data-testid="block-option">
      <S.Card>
        <S.Icon src={icon} />
        <S.CentralizeText>
          <S.Description>{description}</S.Description>
        </S.CentralizeText>
      </S.Card>
    </S.ContainerBlock>
  );
};

const DigitalAccount = () => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(showCallToAction(true));
    trackingSelectContent('abrir-conta-gratis', 'produtos-financeiros');
  };

  return (
    <S.InformationPanel>
      <S.LeftPanel>
        <S.Midway src={Midway} />
      </S.LeftPanel>
      <S.RightPanel>
        <S.TextRight>Conta Digital Midway</S.TextRight>
        <S.SubTextRight>
          Conheça algumas vantagens da Conta Digital Midway
        </S.SubTextRight>
        <S.CentralizeCards>
          <S.Options>
            <BlockOptions icon={GiftIconCoin} description="Conta gratuita" />
            <BlockOptions
              icon={GiftIconBusinessplan}
              description="Seu saldo rende mais do que a poupança "
            />
            <BlockOptions
              icon={GiftIconMobile}
              description="Recarga de celular"
            />
            <BlockOptions icon={GiftIconPix} description="Pix no crédito" />
            <BlockOptions
              icon={GiftIconBuilding}
              description="Saque e depósito nas lojas Riachuelo"
            />
            <BlockOptions
              icon={GiftIconGift}
              description="Descontos exclusivos na Riachuelo"
            />
          </S.Options>
          <S.RightButton
            role="button"
            aria-label="Clique para abrir uma conta grátis"
            tabIndex={0}
            onClick={handleOpenModal}
            data-testid="open-account-button"
          >
            Abrir Conta grátis
          </S.RightButton>
          <CallToAction />
        </S.CentralizeCards>
      </S.RightPanel>
    </S.InformationPanel>
  );
};

export default DigitalAccount;
