import React, { useEffect } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import { usePaymentChannelContext } from '~/context/PaymentChannel';

import useCurrentInvoiceCard from '../../hooks/useCurrentInvoiceCard';
import useUnifiedBillet from '../../hooks/useUnifiedBillet';
import useElegibility from '../../hooks/useElegibility';
import useBalance from '../../hooks/useBalance';
import useCardInvoices from '../../hooks/useCardInvoices';

import CardInfoCard from '~/pages/PaymentChannel/components/CardInfoCard';
import DueCard from '~/pages/PaymentChannel/components/DueCard';

import * as S from './styles';

import { trackingViewContent } from '~/analytics';
import { flow, contentPage } from '~/analytics/PaymentChannel';
import { screens } from '~/pages/PaymentChannel/routes';

const CardsHome = () => {
  const navigate = useHistory();
  const { paymentChannelData } = usePaymentChannelContext();
  const { cardInfo, totalOverdueInvoice, user, selected, billet } =
    paymentChannelData;
  const today = moment();

  const { data, isLoading: isLoadingCurrentInvoiceCard } =
    useCurrentInvoiceCard({
      document: user?.document,
      birthDate: moment(user?.birthDate).format('YYYY-MM-DD'),
    });

  const { isLoading: isLoadingCardInvoices } = useCardInvoices({
    document: user.document,
  });

  const {
    isLoading: isLoadingElegibility,
    isError: isErrorEligibility,
    error: errorEligibility,
  } = useElegibility({
    date: moment(today).add(1, 'days').format('YYYY-MM-DD'),
    documentNumber: user?.document,
    contract: selected,
    type: 'cards',
  });

  useUnifiedBillet({ contract: selected, document: user?.document });
  if (!isEmpty(billet)) navigate.push(screens.DUPLICATE);

  const { isLoading: isLoadingBalance } = useBalance({
    contract: selected,
    document: user?.document,
  });

  const dataInfoCard = {
    finalCardNumber: data?.finalCardNumber,
    cardDateDetail: data?.cardDateDetail,
    value: data?.invoiceResponse?.value,
    dueDate: data?.invoiceResponse?.dueDate,
    ...data?.limitAvailable,
  };

  useEffect(() => {
    trackingViewContent(contentPage.cardsHome, flow.cards, {
      contrato: paymentChannelData?.selected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isErrorEligibility) {
      navigate.push(screens.ERROR, {
        flow: flow.cards,
        contentPage: contentPage.cardsHome,
        errorObject: errorEligibility,
        errorType: errorEligibility?.errorType,
      });
    }
  }, [isErrorEligibility, errorEligibility, navigate]);

  const isLoading =
    isLoadingBalance ||
    isLoadingElegibility ||
    isLoadingCurrentInvoiceCard ||
    isLoadingCardInvoices;

  return (
    <>
      <S.Wrapper>
        {!cardInfo?.isOverDue ? (
          <>
            <S.Container>
              <CardInfoCard data={dataInfoCard} isLoading={isLoading} />
            </S.Container>
          </>
        ) : (
          <>
            <S.Container>
              <S.TitleContainer>
                <S.Title>Dividas totais</S.Title>
                <S.Text>
                  Você tem 1 dívida total referente a {totalOverdueInvoice}{' '}
                  fatura(s) em aberto
                </S.Text>
              </S.TitleContainer>
              <DueCard daysOverdue={cardInfo?.daysOverdue} />
            </S.Container>
          </>
        )}
      </S.Wrapper>
    </>
  );
};

export default CardsHome;
