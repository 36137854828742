import React from 'react';
import {
  MenuCartoes,
  MenuContaDigital,
  MenuEmprestimos,
  MenuNegociacaoDeDividas,
  MenuSeguros,
} from '~/components/Header/MenuSections';
import * as S from './styles';

const MobileMenu = ({ reduceHeightBy }) => (
  <S.Wrapper reduceHeightBy={reduceHeightBy}>
    <nav id="menuMobile">
      <MenuNegociacaoDeDividas />
      <MenuCartoes />
      <MenuContaDigital />
      <MenuEmprestimos />
      <MenuSeguros />
    </nav>
  </S.Wrapper>
);

export default MobileMenu;
