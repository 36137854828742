import React, { createContext, useContext, useState } from 'react';

export const PaymentChannelContext = createContext();

export const PaymentChannelProvider = ({
  children,
  initialPaymentChannelData = {},
}) => {
  const [paymentChannelData, setPaymentChannelData] = useState(
    initialPaymentChannelData
  );
  const [loading, setLoading] = useState(false);

  const updateData = (stepKey, data) => {
    setPaymentChannelData(prev => ({
      ...prev,
      [stepKey]: data,
    }));
  };

  return (
    <PaymentChannelContext.Provider
      value={{
        paymentChannelData,
        updateData,
        loading,
        setLoading,
      }}
    >
      {children}
    </PaymentChannelContext.Provider>
  );
};

export const usePaymentChannelContext = () => {
  const context = useContext(PaymentChannelContext);

  if (!context)
    throw new Error(
      'usePaymentChannelContext must be used within a PaymentChannelProvider'
    );

  return context;
};
