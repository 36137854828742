import styled from 'styled-components';
import { Link as LinkDOM } from 'react-router-dom';
import DownloadIco from '~/assets/img/BrandsMenu/download.svg';
import Chevron from '~/assets/img/BrandsMenu/chevron.svg';
import SadwichIco from '~/assets/img/BrandsMenu/menu.svg';
import CloseIco from '~/assets/img/BrandsMenu/close.svg';

import IconCreditCard from '~/assets/img/BrandsMenu/ico/credit-card.svg';
import IconMaster from '~/assets/img/BrandsMenu/ico/mids-mastercard.svg';
import IconRchlo from '~/assets/img/BrandsMenu/ico/mids-rchlo.svg';
import IconStars from '~/assets/img/BrandsMenu/ico/stars.svg';
import IconCash from '~/assets/img/BrandsMenu/ico/cash.svg';
import IconMoney from '~/assets/img/BrandsMenu/ico/report-money.svg';
import IconTicket from '~/assets/img/BrandsMenu/ico/ticket.svg';
import IconSingleCard from '~/assets/img/BrandsMenu/ico/single-card.svg';

import IconShield from '~/assets/img/BrandsMenu/ico/shield.svg';
import IconHeart from '~/assets/img/BrandsMenu/ico/mids-heart.svg';
import IconBlock from '~/assets/img/BrandsMenu/ico/mids-custom-permanent-block.svg';
import IconDental from '~/assets/img/BrandsMenu/ico/mids-dental.svg';
import IconTrendinUp from '~/assets/img/BrandsMenu/ico/trending-up.svg';
import IconCirclePlus from '~/assets/img/BrandsMenu/ico/circle-plus.svg';

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 186px;
  height: 48px;
  margin-right: 100px;
  color: #006464;
  background-color: transparent;
  font-size: 14px;
  text-align: center;
  border: 1px solid #006464;
  border-radius: 25px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #006464;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
`;

export const Link = styled(LinkDOM)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #006464;
  border-radius: 7rem;

  &:focus {
    background-color: #006464;
    color: #fff;
    border: 1px solid #006464;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  line-height: 20px;
  color: #000000;
  transition: 0.2s ease;
  background: transparent;
  border: none;

  &::after {
    content: ' ';
    background-color: #a06d2c;
    width: 0;
    height: 4px;
    display: flex;
    position: absolute;
    bottom: 6px;
    transition: 0.2s ease;
  }

  &:hover,
  &:focus {
    &:after {
      width: 100%;
    }
  }
`;

export const ChevronIcon = styled.span`
  display: flex;
  background-image: url(${Chevron});
  height: 8px;
  width: 14px;
  margin-left: 15px;
`;

export const MenuMobile = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 30px;
`;

export const LinkAccess = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #47c8cd;
  border-radius: 7rem;
  background-color: #000000;
  border: 1px solid #000000;

  &:focus {
    background-color: #000000;
    color: #47c8cd;
    border: 1px solid #000000;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
`;

export const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MobileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 15px;
`;

export const PrincipalMenu = styled.nav`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  #sandwichMenu {
    display: none;
  }
  #closeMenu {
    display: none;
  }
  @media (max-width: 900px) {
    #sandwichMenu {
      border: none;
      display: block;
      background: #fff;
      background-image: url(${SadwichIco});
      background-position: center;
      width: 33px;
      height: 33px;
      text-indent: -999px;
      overflow: hidden;
    }
    #closeMenu {
      border: none;
      display: block;
      background: #fff;
      background-image: url(${CloseIco});
      background-position: center;
      width: 33px;
      height: 33px;
      text-indent: -999px;
      overflow: hidden;
      z-index: 6;
    }
  }

  ul#menu {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 0 15px;
    justify-content: space-between;
    li {
      display: flex;
      position: relative;

      &:hover,
      &:focus,
      &:active {
        .submenu {
          visibility: visible;
          opacity: 1;
        }
      }

      a {
        display: flex;
        align-items: center;
        font-family: Montserrat, sans-serif;
        font-size: 1rem;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #000000;
        position: relative;
        padding: 10px 0;
        transition: 0.2s ease;
        @media screen and (max-width: 1024px) {
          font-size: 1rem !important;
        }
        &:hover,
        &:focus {
          &:after {
            width: 100%;
          }
        }

        span.chevron {
          display: flex;
          background-image: url(${Chevron});
          height: 8px;
          width: 14px;
          margin-left: 15px;
        }

        &:after {
          content: ' ';
          background-color: #a06d2c;
          width: 0;
          height: 4px;
          display: flex;
          display: flex;
          position: absolute;
          bottom: 6px;
          transition: 0.2s ease;
        }
      }
    }
  }

  .submenu {
    visibility: hidden;
    opacity: 0;
    padding: 40px 30px 20px;
    position: absolute;
    background: #fff;
    top: 67px;
    width: 608px;
    border-radius: 10px;
    box-shadow: 0 2px 12px -4px rgba(0, 0, 0, 0.44);
    transition: 0.3s ease;

    a {
      font-size: 16px !important;
      margin: 10px 0 !important;
      padding: 0 !important;

      &:hover,
      &:focus {
        &:before {
          margin-right: 13px;
          margin-left: 3px;
        }
      }

      &:before {
        content: '';
        background-image: url(${IconCreditCard});
        width: 24px;
        height: 24px;
        margin-right: 20px;
        transition: 0.2s ease;
      }
      &:after {
        content: none !important;
      }
      &#ico-master {
        &:before {
          background-image: url(${IconMaster});
        }
      }
      &#ico-rchlo {
        &:before {
          background-image: url(${IconRchlo});
        }
      }
      &#ico-ticket {
        &:before {
          background-image: url(${IconTicket});
        }
      }
      &#ico-stars {
        &:before {
          background-image: url(${IconStars});
        }
      }
      &#ico-single-card {
        &:before {
          background-image: url(${IconSingleCard});
        }
      }
      &#ico-cash {
        &:before {
          background-image: url(${IconCash});
        }
      }
      &#ico-money {
        &:before {
          background-image: url(${IconMoney});
        }
      }
      &#ico-plus {
        &:before {
          background-image: url(${IconCirclePlus});
        }
      }
      &#ico-shield {
        &:before {
          background-image: url(${IconShield});
        }
      }
      &#ico-block {
        &:before {
          background-image: url(${IconBlock});
        }
      }
      &#ico-heart {
        &:before {
          background-image: url(${IconHeart});
        }
      }
      &#ico-dental {
        &:before {
          background-image: url(${IconDental});
        }
      }
      &#ico-trending-up {
        &:before {
          background-image: url(${IconTrendinUp});
        }
      }
    }

    &:before {
      content: attr(data-header);
      color: #704810;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
    @media (max-width: 900px) {
      &:before {
        font-size: 14px;
      }
    }

    &:after {
      content: ' ';
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid white;
      position: absolute;
      top: -7px;
      left: 50px;
      box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.44);
    }
  }

  @media (max-width: 900px) {
    ul#menu {
      display: none;
    }
    #menuMobile {
      height: ${props =>
        props.hasWidget ? 'calc(100vh - 223px)' : 'calc(100vh - 160px)'};
      overflow: scroll;
      padding-bottom: 20px;

      .submenu {
        visibility: visible;
        opacity: 1;
        position: relative;
        top: auto;
        box-shadow: none;
        width: auto;
        padding: 10px 15px;

        li {
          display: flex;
          align-items: center;

          a {
            font-size: 12px !important;
            margin: 10px 0 !important;
            padding: 0 !important;
            color: #1f2b2a;

            &.MenuLinkMobile {
              display: flex;
              flex-direction: row;
              align-items: center;
              border-bottom: 1px solid #eee;
              padding-bottom: 7px !important;
              width: 100%;
              &:after {
                background-image: url(${Chevron});
                content: '' !important;
                width: 14px;
                height: 8px;
                transform: rotate(270deg);
                right: 30px;
                position: absolute;
              }
            }

            &:before {
              display: inline-flex;
              padding: 6px;
              margin-right: 10px;
            }

            &:hover,
            &:focus {
              margin: 10px 0 !important;

              &:before {
                margin-right: 10px !important;
              }
            }
          }
        }
      }
    }
  }

  a#download-buttom {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 300;
    line-height: 27px;
    color: #212121;
    text-indent: 10px;
    transition: 0.2s ease;
    margin: 10px 15px;
    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:before {
      content: ' ';
      background-color: #ffffff;
      background-image: url(${DownloadIco});
      background-size: contain;
      width: 24px;
      height: 24px;
      display: flex;
    }

    @media (max-width: 900px) {
      font-size: 16px;
      margin: 15px 20px;

      &:hover,
      &:focus {
        text-indent: 10px !important;
        margin: 15px 20px !important;
      }

      &:before {
        background-size: contain;
        width: 23px;
        height: 23px;
      }
    }
  }

  a#paybill-buttom {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    background: #221f20;
    padding: 3px 12px !important;
    color: #fff !important;
    font-size: 1.14rem !important;
    transition: 0.2s ease;
    border: 2px solid transparent;
    font-weight: bold;
    &:after {
      display: none !important;
    }
    &:hover,
    &:focus {
      background: #000000;
      border-color: #704810;
      transform: translateY(-5px);
    }
    @media (max-width: 425px) {
      font-size: 0.8rem;
      font-weight: normal;
    }
  }
`;
