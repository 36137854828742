import { useQuery } from '@tanstack/react-query';
import CollectionApi from '~/services/CollectionApi';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
const STALE_TIME = 60 * 1000; // 1 minute

const NOT_FOUND = 404;
const useUnifiedBillet = ({ uuid, document, contract }) => {
  const { updateData } = usePaymentChannelContext();

  const queryFn = async ({ uuid, document, contract }) => {
    const response = await CollectionApi.getUnifiedBillet({
      billetId: uuid,
      document,
      contract,
    });

    if (response.status === NOT_FOUND) return [];

    if (!response?.detail || response.error) {
      throw new Error(response.message ?? response.error);
    }

    updateData('billet', response?.detail);

    return response?.detail ?? [];
  };

  return useQuery([uuid, document, contract], {
    queryFn: () => queryFn({ uuid, document, contract }),
    staleTime: STALE_TIME,
    enabled: Boolean(uuid || (document && contract)),
    refetchOnWindowFocus: false,
  });
};

export default useUnifiedBillet;
