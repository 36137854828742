/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Image } from '@midway/web-ui-component';
import Modal from '~/components/Modal';
import CartaoPL from '~/assets/img/cartoes/cartao-pl.png';
import CartaoMaster from '~/assets/img/cartoes/cartoes-master.png';
import ListGift from '~/assets/img/cartoes/list-gift.svg';
import ListCoin from '~/assets/img/cartoes/list-coin.svg';
import ListCheck from '~/assets/img/cartoes/list-check.svg';
import ListArrow from '~/assets/img/cartoes/list-arrow.svg';
import ListConfetti from '~/assets/img/cartoes/list-confetti.svg';
import ListShoppingCart from '~/assets/img/cartoes/list-shopping-cart.svg';
import Company1 from '~/assets/img/cartoes/company-extrafarma.png';
import Company2 from '~/assets/img/cartoes/company-shopclub.png';
import Company3 from '~/assets/img/cartoes/company-pague.png';
import Company4 from '~/assets/img/cartoes/company-mobly.png';
import Company5 from '~/assets/img/cartoes/company-merqueo.png';
import Company6 from '~/assets/img/cartoes/company-buser.png';
import Company7 from '~/assets/img/cartoes/company-veloe.png';
import Company8 from '~/assets/img/cartoes/company-justo.png';
import Company9 from '~/assets/img/cartoes/company-cna.png';
import Company10 from '~/assets/img/cartoes/company-extra.png';
import CardIconModal from '~/assets/img/cartoes-pl/card.svg';
import GiftIconModal from '~/assets/img/cartoes-pl/gift.svg';
import CoinIconModal from '~/assets/img/cartoes-pl/coin.svg';
import ConfettiIconModal from '~/assets/img/cartoes-pl/confetti.svg';
import FileCheckIconModal from '~/assets/img/cartoes-pl/file-check.svg';
import PercentageIconModal from '~/assets/img/cartoes-pl/percentage.svg';
import ShoppingIconModal from '~/assets/img/cartoes-pl/shopping-cart.svg';
import BuildingIconModal from '~/assets/img/cartoes-pl/building-store.svg';
import QrCodeImage from '~/assets/img/cartoes-pl/qrcode.svg';
import { trackingSelectContent } from '~/analytics';
import HeroCarousel from './components/HeroCarrousel';
import * as S from './styles';

const BlockModalOptions = ({ icon, description, observation }) => {
  return (
    <S.ContainerBlockModal data-testid="block-modal-options">
      <S.CardModal>
        <S.IconModal src={icon} data-testid="icon-modal" />
        <S.CentralizeTextModal>
          <S.DescriptionModal data-testid="description-modal">
            {description}
          </S.DescriptionModal>
          {observation && (
            <S.ObservationModal data-testid="observation-modal">
              {observation}
            </S.ObservationModal>
          )}
        </S.CentralizeTextModal>
      </S.CardModal>
    </S.ContainerBlockModal>
  );
};

const Cards = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <Modal onClose={closeModal} data-testid="modal">
          <S.ModalStyle>
            <S.RightModal>
              <S.AdjustamentRightModal>
                <S.TitleModal data-testid="modal-title">
                  Peça já o seu cartão Riachuelo{' '}
                </S.TitleModal>
                <S.TextModal data-testid="modal-text">
                  E aproveite todas as vantagens
                </S.TextModal>
                <S.CentralizeCardsModal>
                  <S.ModalOptions data-testid="modal-options">
                    <BlockModalOptions
                      icon={GiftIconModal}
                      description="Ganhe 10% OFF na primeira compra!"
                      observation="Válido para cartões da loja e em compras feitas no app, site ou lojas físicas. Exceto eletrônicos"
                    />
                    <BlockModalOptions
                      icon={CoinIconModal}
                      description="Condições especiais de parcelamento no site e nas lojas físicas"
                    />
                    <BlockModalOptions
                      icon={PercentageIconModal}
                      description="Até 35% de desconto em farmácias parceiras"
                    />
                    <BlockModalOptions
                      icon={FileCheckIconModal}
                      description="Acesse e pague sua fatura nos apps Midway ou Riachuelo"
                    />
                    <BlockModalOptions
                      icon={CardIconModal}
                      description="Programa Mastercard Surpreenda*"
                      observation="*Apenas para clientes do cartão Mastercard."
                    />
                    <BlockModalOptions
                      icon={ConfettiIconModal}
                      description="Parcerias exclusivas"
                    />
                    <BlockModalOptions
                      icon={BuildingIconModal}
                      description="Entrega instantânea do cartão RCHLO na loja"
                    />
                    <BlockModalOptions
                      icon={ShoppingIconModal}
                      description="Compra on-line com retirada em loja"
                    />
                  </S.ModalOptions>
                </S.CentralizeCardsModal>
                <S.TextfinalModal data-testid="modal-final-text">
                  Baixe o aplicativo da Riachuelo, peça o seu cartão e já comece
                  a usar!
                </S.TextfinalModal>
              </S.AdjustamentRightModal>
              <S.QRCodeImage src={QrCodeImage} data-testid="qr-code-image" />
              <S.ModalButton
                target="_blank"
                href="https://www.midway.com.br/app/"
                data-testid="modal-button"
              >
                Baixar App Riachuelo
              </S.ModalButton>
            </S.RightModal>
          </S.ModalStyle>
        </Modal>
      )}
      <S.Container data-testid="cards-container">
        <S.BannerContainer>
          <HeroCarousel data-testid="hero-carousel" />
        </S.BannerContainer>
        <S.ContentContainer>
          <S.SessionCards>
            <div className="leftSide">
              <div className="card-mobile">
                <Image src={CartaoPL} data-testid="card-image" />
              </div>

              <h2 data-testid="card-title">Cartão Riachuelo</h2>
              <p data-testid="card-description">
                Tenha condições exclusivas de parcelamento na loja e no site com
                isenção de anuidade
              </p>
              <div className="list-content">
                <div className="list-item">
                  <Image src={ListGift} />
                  <div>
                    <h4>Ganhe 10% OFF na primeira compra!</h4>
                    <p>
                      Desconto válido para cartões da loja e em compras feitas
                      no app, site ou lojas físicas. Exceto eletrônicos
                    </p>
                  </div>
                </div>
                <div className="list-item">
                  <Image src={ListCoin} />
                  <div>
                    <h4>Parcele compras em 5 vezes sem juros</h4>
                    <p>
                      Com o Cartão Riachuelo você compra em até 5x sem juros em
                      nossas lojas físicas e virtual
                    </p>
                  </div>
                </div>
                <div className="list-item">
                  <Image src={ListCheck} />
                  <div>
                    <h4>
                      Comece a pagar em até 60 dias de 8 a 10 vezes com juros¹
                    </h4>
                    <p>
                      Fazendo o Cartão Riachuelo, você tem a opção de começar a
                      pagar em até 60 dias da data da sua compra com condições
                      especiais de parcelamento¹.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="rightSide">
              <div className="cardImage">
                <Image src={CartaoPL} />
                <div className="content-butons">
                  <S.ButtonBlack
                    data-testid="button-request-now"
                    onClick={() => {
                      openModal();
                      trackingSelectContent(
                        'cartao-riachuelo:peca-agora',
                        'cartao'
                      );
                    }}
                  >
                    Peça agora
                  </S.ButtonBlack>
                  <S.ButtonWhite
                    data-testid="button-learn-more-riachuelo"
                    onClick={() => {
                      trackingSelectContent(
                        'cartao-riachuelo:saiba-mais',
                        'cartao'
                      );
                      window.location.href = '/cartao-rchlo';
                    }}
                  >
                    Saiba mais
                  </S.ButtonWhite>
                </div>
              </div>
            </div>
          </S.SessionCards>
          <S.SessionCards>
            <div className="leftSide">
              <div className="cardImage-lft">
                <Image src={CartaoMaster} />
                <div className="content-butons-lft">
                  <S.ButtonBlack
                    onClick={() => {
                      openModal();
                      trackingSelectContent(
                        'cartao-mastercard:peca-agora',
                        'cartao'
                      );
                    }}
                  >
                    Peça agora
                  </S.ButtonBlack>
                  <S.ButtonWhite
                    data-testid="button-learn-more-master"
                    onClick={() => {
                      trackingSelectContent('mastercard:saiba-mais', 'cartao');
                      window.location.href = '/cartao-de-credito';
                    }}
                  >
                    Saiba mais
                  </S.ButtonWhite>
                </div>
              </div>
            </div>
            <div className="rightSide">
              <h2>Cartão Mastercard</h2>
              <p>
                Tem vantagens exclusivas dentro e fora da Riachuelo com a menor
                anuidade dentre as lojas de moda.
              </p>
              <div className="list-content">
                <div className="list-item">
                  <Image src={ListArrow} />
                  <div>
                    <h4>Faça upgrade do seu cartão Riachuelo</h4>
                    <p>
                      Além de todos os benefícios cartão Riachuelo, conte com
                      mais no seu cartão MasterCard
                    </p>
                  </div>
                </div>
                <div className="list-item">
                  <Image src={ListConfetti} />
                  <div>
                    <h4>Limite de até R$15.000,00</h4>
                    <p>
                      Para aproveitar no ecossistema de lojas Riachuelo e demais
                      lojas de mercado
                    </p>
                  </div>
                </div>
                <div className="list-item">
                  <Image src={ListCoin} />
                  <div>
                    <h4>São 3 possibilidades de benefícios Surpreenda</h4>
                    <p>
                      Compre 1, leve 2. Troque pontos por desconto. Troque
                      pontos por produtos.
                    </p>
                  </div>
                </div>
                <div className="list-item">
                  <Image src={ListShoppingCart} />
                  <div>
                    <h4>Descontos de até 35% em farmácias parceiras</h4>
                    <p>
                      Descontos exclusivos em parceiros (Drogaria São Paulo,
                      Drogaria Pacheco, Pague Menos e Extrafarma)
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-butons-mbl">
                <S.ButtonBlack
                  onClick={() => {
                    openModal();
                    trackingSelectContent(
                      'cartao-mastercard:peca-agora',
                      'cartao'
                    );
                  }}
                >
                  Peça agora
                </S.ButtonBlack>
                <S.ButtonWhite
                  data-testid="button-learn-more-master"
                  onClick={() => {
                    trackingSelectContent('mastercard:saiba-mais', 'cartao');
                    window.location.href = '/cartao-de-credito';
                  }}
                >
                  Saiba mais
                </S.ButtonWhite>
              </div>
            </div>
          </S.SessionCards>
          <S.Pacerias>
            <h2>Benefícios e parcerias</h2>
            <p>
              A Riachuelo e a Mastercard uniram-se aos seus parceiros para
              trazer a você superbenefícios em gastronomia, lazer, pets, saúde e
              muito mais. Com seu <strong>Cartão Riachuelo Mastercard</strong>{' '}
              você tem descontos imperdíveis de até <strong>40%</strong> em
              nossos parceiros exclusivos!
            </p>
            <div className="partners-logo">
              <Image src={Company1} />
              <Image src={Company2} />
              <Image src={Company3} />
              <Image src={Company4} />
              <Image src={Company5} />
              <Image src={Company6} />
              <Image src={Company7} />
              <Image src={Company8} />
              <Image src={Company9} />
              <Image src={Company10} />
            </div>
            <div className="consult-partners">
              <S.ButtonBlack
                onClick={() =>
                  trackingSelectContent(
                    'consulte-nossos-parceiros',
                    'cartao'
                  )(
                    (window.location.href =
                      'https://apps.apple.com/br/app/riachuelo-comprar-roupas/id1488532824')
                  )
                }
              >
                Consulte nossos parceiros
              </S.ButtonBlack>
            </div>
          </S.Pacerias>
        </S.ContentContainer>
        <S.ObservationSectionContainer>
          <S.ObservationText>
            ¹Condição de parcelamento somente para compras em lojas físicas da
            Riachuelo, com pagamento exclusivo com Cartão Riachuelo, em até 10x
            com juros e sem entrada, com início do pagamento em até 60 dias e
            juros de 7,9% ao mês ou 149,03% ao ano + IOF, inclusive sobre o
            período de carência (60 dias), condicionada à data de compra e à
            data de vencimento da fatura. Sujeita a atualização cadastral.
            Condições válidas para todos os produtos, exceto: eletrônicos,
            perfumaria, relógios e recarga e celular.
          </S.ObservationText>
        </S.ObservationSectionContainer>
      </S.Container>
    </>
  );
};

export default Cards;
