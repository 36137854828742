import React, { useEffect } from 'react';
import * as S from './styles';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import { getSelectedContract } from '~/pages/PaymentChannel/helpers';
import { trackingViewContent, trackingSelectContent } from '~/analytics';
import { flow, contentPage, contentType } from '~/analytics/PaymentChannel';
import { useHistory } from 'react-router-dom';
import { screens } from '~/pages/PaymentChannel/routes';
import HeaderContracts from '~/pages/PaymentChannel/components/HeaderContracts';
import { first, words } from 'lodash';

const Contracts = () => {
  const navigate = useHistory();
  const { paymentChannelData, updateData } = usePaymentChannelContext();
  const { listContracts } = paymentChannelData;

  const trackingSelectedContract = selectedContract => {
    let ct = contentType.contractTypeOthers;
    if (selectedContract.type === 'loan') ct = contentType.contractTypeLoan;
    if (selectedContract.productDescription === 'Cartão PL')
      ct = contentType.contractTypeCardPl;
    if (
      selectedContract.productDescription === 'Cartão Visa' ||
      selectedContract.productDescription === 'Cartão Master'
    )
      ct = contentType.contractTypeCardPl;
    trackingSelectContent(ct, flow.default, contentPage.contracts);
  };

  const onSelectContract = item => {
    const isOverDue = item?.isOverdue;
    const daysOverdue = item?.overDueDays;
    const selected = item.id;

    trackingSelectedContract(getSelectedContract(paymentChannelData, selected));

    updateData('cardInfo', { isOverDue, daysOverdue });
    updateData('selected', selected);
    updateData('system', item?.system);

    navigate.push(screens.CARDS_HOME);
  };

  useEffect(() => {
    trackingViewContent(contentPage.contracts, flow.default);
  }, []);

  return (
    <>
      <HeaderContracts
        name={first(words(paymentChannelData?.contracts[0]?.name))}
      />
      <S.Wrapper>
        <S.Container>
          {listContracts?.map((item, index) => (
            <S.ItemContract key={index} onClick={() => onSelectContract(item)}>
              <S.ItemRow>
                <S.ItemTitle>{item.name}</S.ItemTitle>
                <S.IconChevronRight />
              </S.ItemRow>
              <S.ItemRow>
                {item.vencimento && (
                  <S.ItemText>Venc {item.vencimento}</S.ItemText>
                )}
                <S.Badge type={item.status}>{item.statusText}</S.Badge>
              </S.ItemRow>
            </S.ItemContract>
          ))}
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Contracts;
