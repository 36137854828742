import React from 'react';
import { IconCreditCard, IconCoin, IconCheck } from '@tabler/icons-react';
import Button from '../../../components/Button';
import * as S from './styles';

const SectionCheckDetails = ({ onClickButton }) => {
  const ListItem = ({ children }) => {
    return (
      <S.ListItem>
        <S.CheckIconWrapper>
          <IconCheck />
        </S.CheckIconWrapper>
        {children}
      </S.ListItem>
    );
  };

  return (
    <S.GrayWrapper>
      <S.Container>
        <S.Title>Confira detalhes do que você encontra aqui</S.Title>
        <S.Card>
          <S.CardIcons>
            <S.IconWrapper>
              <IconCreditCard />
            </S.IconWrapper>
            <S.IconWrapper>
              <IconCoin />
            </S.IconWrapper>
          </S.CardIcons>
          <S.CardContent>
            <S.CardText>Cartões Riachuelo e Empréstimos</S.CardText>
            <S.List>
              <ListItem>Consultar Fatura</ListItem>
              <ListItem>2ª via do boleto</ListItem>
              <ListItem>Negociações</ListItem>
            </S.List>
          </S.CardContent>
        </S.Card>
        <Button onClick={onClickButton}>Consultar</Button>
      </S.Container>
    </S.GrayWrapper>
  );
};

export default SectionCheckDetails;
