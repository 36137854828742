import styled from 'styled-components';
import { IconChevronRight as _IconChevronRight } from '@tabler/icons-react';

export const Wrapper = styled.div`
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
`;

export const Container = styled.div`
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid #d9d9d9;
`;

export const BannerContainer = styled.div`
  height: 91px;
  margin: 8px 0;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

export const Title = styled.h2`
  font-size: 1.125rem !important;
  font-weight: 500;
  color: #221f20;
  margin-bottom: 4px;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: #646464;
  flex-basis: 100%;
`;

export const ShowInvoiceButton = styled.button`
  border: none;
  background: transparent;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: inherit;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  line-height: 1;
`;

export const IconChevronRight = styled(_IconChevronRight)`
  width: 16px;
  flex-basis: 16px;
  height: 19px;
`;
