import React from 'react';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import { getUserName } from '../../helpers';
import * as S from './styles';

const HeaderCards = () => {
  const { paymentChannelData } = usePaymentChannelContext();
  const { mdm, contracts } = paymentChannelData;

  const fullName = mdm?.fullName || contracts[0].name || '';

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>Olá, {getUserName(fullName)?.firstName}</S.Title>
        <S.Description>
          Escolha um contrato para conferir mais detalhes.
        </S.Description>
      </S.Content>
    </S.Wrapper>
  );
};

export default HeaderCards;
