import styled from 'styled-components';
import IconSearch from '~/assets/img/icons/IconSearch.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  margin: 69px 0px 54px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const Input = styled.input`
  ${props => (props.isMobile ? 'width: 100%;' : 'width: 412px;')};
  ${props => (props.isMobile ? 'margin-bottom: 10px;' : 'margin-bottom: 0px;')};
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #c8c8c8;
  border-radius: 30px;
  background-image: url(${IconSearch});
  background-position: 15px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;

  @media (max-width: 1000px) {
    height: 44px;
  }
`;

export const FilterAccordion = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FilterContent = styled.div`
  margin-right: 16px;
  @media (max-width: 1000px) {
    margin-right: 0px;
    margin-bottom: 23px;
  }
`;

export const ButtonFilter = styled.a`
  width: 148;
  height: 32;
  gap: 10px;
  border-radius: 100px;
  padding-top: 7px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  background-color: #f7f7f7;
`;

export const AccordionContainer = styled.div`
  width: 100%;
  margin-bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 1000px) {
    margin-top: 45px;
  }
`;

export const Accordion = styled.div`
  margin-bottom: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const AccordionContent = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
`;

export const AccordionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AccordionNoResultsMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoResultsMessage = styled.p`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0%;
`;

export const TitleAccordion = styled.p`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: 0%;
  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;
