import styled from 'styled-components';
import * as Common from '../../styles';

export const Header = styled.div`
  width: 100%;
  background-color: #000;
  position: sticky;
  height: 56px;
  top: 0;
  z-index: 999;
`;

export const Container = styled(Common.Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: unset;
`;

export const MenuContainer = styled.div`
  position: fixed;
  background: white;
  z-index: 1010;
  width: calc(100% - 6px);
  top: 56px;
`;

export const LogoWrapper = styled.div`
  svg {
    fill: #ffffff;
  }
`;

export const LogoText = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.2;
  color: #9e9e9e;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const MenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border: none;
  background: transparent;
  flex-basis: 24px;

  svg {
    stroke: #d9d9d9;
    height: 24px;
    width: 24px;
  }
`;

export const Title = styled.h2`
  color: #fff;
  font-size: 1.25rem !important;
  line-height: 1;
  margin-bottom: -0.2rem;
`;
