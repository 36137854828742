const dataLayer = window.dataLayer || [];
const isDevEnvironment = process.env.NODE_ENV === 'development';

export const trackingViewPage = (
  pageLocation = '',
  pageTitle = '',
  pageReferrer = '',
  pagePath = ''
) => {
  dataLayer.push({
    event: 'page_view',
    page_location: pageLocation,
    page_title: pageTitle,
    page_referrer: pageReferrer,
    page_path: pagePath,
  });
};

export const trackingViewContent = (contentPage, flow, otherParams = {}) => {
  const payload = {
    event: 'view_content',
    flow,
    content_page: contentPage,
    ...otherParams,
  };
  if (isDevEnvironment) console.log(payload);
  dataLayer.push(payload);
};

export const trackingException = (
  description,
  flow,
  fatal = false,
  contentPage = '',
  otherParams = {}
) => {
  const payload = {
    event: 'exception',
    fatal,
    description,
    flow,
    content_page: contentPage,
    ...otherParams,
  };
  if (isDevEnvironment) console.log(payload);
  dataLayer.push(payload);
};

export const trackingSelectContent = (
  contentType,
  flow,
  contentPage = '',
  otherParams = {}
) => {
  const payload = {
    event: 'select_content',
    content_type: contentType,
    content_page: contentPage,
    flow,
    ...otherParams,
  };
  if (isDevEnvironment) console.log(payload);
  dataLayer.push(payload);
};

export const trackingViewPromotion = (
  promotionId,
  flow,
  creativeSlot,
  creativeName = {}
) => {
  const payload = {
    event: 'view_promotion',
    promotion_id: promotionId,
    reative_slot: creativeSlot,
    creative_name: creativeName,
    flow,
  };
  if (isDevEnvironment) console.log(payload);
  dataLayer.push(payload);
};

export const trackingSelectPromotion = (
  promotionId,
  flow,
  creativeSlot,
  creativeName = {}
) => {
  const payload = {
    event: 'select_promotion',
    promotion_id: promotionId,
    reative_slot: creativeSlot,
    creative_name: creativeName,
    flow,
  };
  if (isDevEnvironment) console.log(payload);
  dataLayer.push(payload);
};
