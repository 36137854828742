import React from 'react';
import * as S from './styles';

const Button = ({ children, onClick, isLoading, ...props }) => (
  <S.Button onClick={onClick} {...props}>
    {isLoading ? <S.IconLoader /> : children}
  </S.Button>
);

export default Button;
