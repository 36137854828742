import React from 'react';
import * as S from './styles';

const Table = ({ data }) => {
  return (
    <S.TableContainer data-testid="cards-table">
      <thead>
        <tr>
          <S.TableHeader> </S.TableHeader>
          <S.TableHeader>Cartão Riachuelo</S.TableHeader>
          <S.TableHeader>Cartão Mastercard</S.TableHeader>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <S.TableData>
              <S.Text>{row.column1}</S.Text>
              <S.SubText>{row.subtext1}</S.SubText>
            </S.TableData>
            <S.TableData>
              <S.CentralizeIcon>
                <S.Text>{row.column2}</S.Text>
              </S.CentralizeIcon>
            </S.TableData>
            <S.TableData>
              <S.CentralizeIcon>
                <S.Text>{row.column3}</S.Text>
              </S.CentralizeIcon>
            </S.TableData>
          </tr>
        ))}
      </tbody>
    </S.TableContainer>
  );
};

export default Table;
