import styled from 'styled-components';
import { ContentContainer, ContentTitle } from '~/pages/PaymentChannel/styles';

export const Wrapper = styled.div`
  font-family: Larsseit, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  background-color: #f5f5f5;
`;

export const ContainerContent = styled(ContentContainer)``;

export const Title = styled(ContentTitle)``;

export const ContainerDuplicate = styled.div`
  background: #ffffff;
  padding: 1rem;
  margin: 2rem 0;
  border-radius: 8px;
`;

export const Span = styled.span`
  font-size: ${({ size }) => (size ? size : 16)}px;
  font-weight: ${({ weight }) => weight};
  text-decoration: ${({ line }) => line && 'line-through'};
  color: ${({ color }) => color};
  margin-top: ${({ top }) => top}px;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: space-between;
  margin-top: ${({ top }) => top}px;
`;

export const Authenticate = styled.div`
  margin-bottom: -2rem;
  margin-top: 2rem;
  svg {
    margin-right: 4px;
  }
`;
