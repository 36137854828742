import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';

import useRecaptchaMiAjuda from '~/hooks/useRecaptcha';
import useContracts from '../../hooks/useContracts';
import { trackingViewContent, trackingSelectContent } from '~/analytics';
import { flow, contentPage, contentType } from '~/analytics/PaymentChannel';

import { screens } from '../../routes';
import SectionHero from './SectionHero';
import SectionFaq from './SectionFaq';
import SectionCheckDetails from './SectionCheckDetails';
import SectionFindNewWays from './SectionFindNewWays';
import SectionLookHow from './SectionLookHow';
import SectionPayOffDebts from './SectionPayOffDebts';
import SectionSatisfiedClients from './SectionSatisfiedClients';
import SectionOtherChannels from './SectionOthersChannels';
import Toolbar from '../../components/Toolbar';
import useInsiderQueue from '~/hooks/useInsiderQueue';

const Home = () => {
  const { insiderQueueCustom, insiderQueueInit } = useInsiderQueue();
  const [documentNumber, setDocumentNumber] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const navigate = useHistory();

  const { data, isFetching, isError, error } = useContracts({
    documentNumber,
    birthDate,
  });

  const handleNext = useCallback(() => {
    navigate.push(screens.CONTRACTS);
  }, [navigate]);

  useEffect(() => {
    if (isError) {
      navigate.push(screens.ERROR, {
        flow: flow.cards,
        contentPage: contentPage.home,
        errorObject: error,
        errorType: error?.errorType,
      });
    }
  }, [isError, error, navigate]);

  useEffect(() => {
    trackingViewContent(contentPage.home, flow.default);
    insiderQueueCustom('home_pgr_page_view', {});
    insiderQueueInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) handleNext();
  }, [data, handleNext]);

  const searchCallback = useCallback((document, date) => {
    setDocumentNumber(document);
    setBirthDate(date);
    trackingSelectContent(
      contentType.loginButton,
      flow.default,
      contentPage.home
    );
  }, []);

  const scrollToHero = () => {
    const element = document.querySelector('#section-hero');
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  return (
    <>
      <Toolbar />
      <SectionHero searchCallback={searchCallback} isLoading={isFetching} />
      <SectionCheckDetails onClickButton={scrollToHero} />
      <SectionFindNewWays />
      <SectionLookHow onClickButton={scrollToHero} />
      <SectionPayOffDebts onClickButton={scrollToHero} />
      <SectionSatisfiedClients />
      <SectionFaq onClickButton={scrollToHero} />
      <SectionOtherChannels />
    </>
  );
};

export default () => {
  const { captchaKey } = useRecaptchaMiAjuda('miajuda');

  return (
    <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
      <Home />
    </GoogleReCaptchaProvider>
  );
};
