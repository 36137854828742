import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import Card from './components/Card';
import { banner } from './data';
import RenderBannerImage from '~/components/RenderBannerImage';
import TextPhoneNumber from '~/components/TextPhoneNumber';
import DownloadStore from '~/components/DownloadStore';
import ListStepNumber from '~/components/ListStepNumber';

import MorteIcon from '~/assets/img/Financial/ProtectionInsurance/morte-icon.svg';
import InvalidezIcon from '~/assets/img/Financial/ProtectionInsurance/invalidez-icon.svg';
import DesempregoIcon from '~/assets/img/Financial/ProtectionInsurance/desemprego-icon.svg';
import IncapacidadeFisicaIcon from '~/assets/img/Financial/ProtectionInsurance/incapacidade-fisica-icon.svg';
import InternacaoIcon from '~/assets/img/Financial/ProtectionInsurance/internacao-icon.svg';
import ButtonFull from '~/assets/img/Financial/ProtectionInsurance/button-full.svg';
import ZurichLogo from '~/assets/img/Financial/account-paid-insurance/zurich-logo.svg';
import Iphone from '~/assets/img/Financial/account-paid-insurance/app-midway-cell.svg';
import PlayStore from '~/assets/img/lending/play-store.svg';
import AppleStore from '~/assets/img/lending/apple-store.svg';
import { trackingSelectContent } from '~/analytics';

const PHONE_CAPITALS_AND_REGIONS = '3004 5417';
const PHONE_OTHER_LOCATIONS = '0800 727 4417';
const PHONE_SUPPORT_CAPITALS_AND_REGIONS = '4020 4848';
const PHONE_SUPPORT_OTHER_LOCATIONS = '0800 285 4141';

const Anchor = ({ href, children, onClick }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor onClick={onClick} href={href} target="_blank">
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

const CardWrapper = ({ title, icon, widthCard, href, onClick }) => {
  return (
    <S.ContainerCardWrapper>
      <S.CardWrapper minWidth={widthCard}>
        <S.CardAnchor href={href} onClick={onClick} target="_blank">
          <S.Title>{title}</S.Title>
          <S.Icon src={icon} />
        </S.CardAnchor>
      </S.CardWrapper>
    </S.ContainerCardWrapper>
  );
};

const STEP_LIST = [
  {
    value: '1',
    text: 'Baixe o nosso aplicativo Midway;',
  },
  {
    value: '2',
    text: 'Encontre a opção Serviços e procure por Seguros;',
  },
  {
    value: '3',
    text: 'Encontre o Seguro Conta Paga',
  },
  {
    value: '4',
    text: 'Selecione o plano e a forma de pagamento;',
  },
  {
    value: '5',
    text: 'Confira o resumo, aceite os termos e finalize a contratação.',
  },
];

const AccountPaidInsurance = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerAccountPaidInsurance(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banners = bannerData[0] || banner;

  const handleClickSummaryOfConditions = () => {
    trackingSelectContent(
      'resumo-das-condicoes-gerais-do-seguro-conta-paga',
      'seguro-conta-paga'
    );
  };

  const handleServiceZurich = () => {
    trackingSelectContent('central-de-atendimento-zurich', 'seguro-conta-paga');
  };

  const handleVirtualAssistantZurich = () => {
    trackingSelectContent(
      'fale-com-a-lais-assistente-virtual-zurich',
      'seguro-conta-paga'
    );
  };

  const handleClickPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.midway&hl=en&gl=BR',
      '_blank'
    );
    trackingSelectContent('download:google-play', 'seguro-conta-paga');
  };

  const handleClickAppleStore = () => {
    window.open(
      'https://apps.apple.com/br/app/midway-%C3%A9-riachuelo/id1548732480',
      '_blank'
    );
    trackingSelectContent('download:apple-store', 'seguro-conta-paga');
  };

  const handleClickLinkContentFinal = () => {
    trackingSelectContent('www.consumidor.gov.br', 'seguro-conta-paga');
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banners} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>Seguro Conta Paga</S.TitleCards>
            <S.subTitleCards>
              O Seguro Conta Paga liquida o saldo devedor do cartão Riachuelo
              (até o limite contratado) em caso de:
            </S.subTitleCards>
          </S.AdjustamentTitleCard>

          <S.ContainerCards>
            <Card
              icon={MorteIcon}
              title="Morte"
              description="Capital fixo R$ 1.200,00. Carência: Não há. Franquia: Não há."
            />
            <Card
              icon={InvalidezIcon}
              title="Invalidez permanente total por acidente"
              description="Capital fixo R$ 1.200,00. Carência: Não há. Franquia: Não há."
            />
            <Card
              icon={DesempregoIcon}
              title="Desemprego involuntário*"
              description="Até R$ 1.200,00. Carência: 60 dias consecutivos. Franquia: 15 dias consecutivos."
            />
            <Card
              icon={IncapacidadeFisicaIcon}
              title="Incapacidade física total e temporária**"
              description="Até R$ 1.200,00. Carência: 60 dias consecutivos. Franquia: 15 dias consecutivos."
            />
            <Card
              icon={InternacaoIcon}
              title="Diária de internação hospitalar por acidente ou doença"
              description="Até 30 diárias R$ 200,00. Carência: Não há. Franquia: 2 dias."
            />
          </S.ContainerCards>
          <S.AdditionalInfoCard>
            <S.AdditionalInfoText>
              *Cobertura exclusiva para profissionais assalariados, regidos pela
              CLT
              <br />
              **Cobertura exclusiva para profissionais autônomos regulamentados
            </S.AdditionalInfoText>
            <S.CardWrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  data-testid="summary-of-conditions"
                  href="https://www.midway.com.br/resumo-das-condicoes-gerais-novo-seguro-conta-paga.pdf"
                  target="_blank"
                  onClick={handleClickSummaryOfConditions}
                >
                  Resumo das condições gerais do Seguro Conta Paga
                </S.CardWrapperUnderline>
                Comercializado a partir de 11 de setembro de 2023
              </S.WrapperText>
            </S.CardWrapperText>
          </S.AdditionalInfoCard>

          <S.ContainerCardsZurich>
            <S.ContentCardWrapper>
              <CardWrapper
                title="Central de atendimento Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/atendimento"
                onClick={handleServiceZurich}
              />
              <CardWrapper
                widthCard="531px"
                title="Fale com a Laiz - Assistente virtual Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/chatonline"
                onClick={handleVirtualAssistantZurich}
              />
            </S.ContentCardWrapper>
          </S.ContainerCardsZurich>
          <S.InformationPanel>
            <S.LeftPanel>
              <S.LeftImagePanel src={Iphone} />
            </S.LeftPanel>
            <S.RightPanel>
              <S.CentralizeRightPanel>
                <S.TextPanel>
                  Para contratar o Seguro Conta Paga é simples!{' '}
                </S.TextPanel>
                <S.GroupLeft>
                  <ListStepNumber data={STEP_LIST} />
                </S.GroupLeft>
                <S.GroupColumn>
                  <S.TextMidway>Baixe o aplicativo Midway</S.TextMidway>
                  <S.Agroup>
                    <DownloadStore
                      iconSrc={PlayStore}
                      onClick={handleClickPlayStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Google Play</S.TextBold>
                    </DownloadStore>
                    <DownloadStore
                      iconSrc={AppleStore}
                      onClick={handleClickAppleStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Apple Store</S.TextBold>
                    </DownloadStore>
                  </S.Agroup>
                </S.GroupColumn>
              </S.CentralizeRightPanel>
            </S.RightPanel>
          </S.InformationPanel>
          <S.ContentText>
            <S.TextContent>
              Você também pode contratar nas Lojas Riachuelo mais próximas ou,
              se preferir, pode optar pelas centrais de atendimento através dos
              telefones:
            </S.TextContent>
            <TextPhoneNumber
              text="Capitais e regiões metropolitanas:"
              phoneNumber={PHONE_CAPITALS_AND_REGIONS}
            />
            <TextPhoneNumber
              text="Demais localidades:"
              phoneNumber={PHONE_OTHER_LOCATIONS}
            />
          </S.ContentText>
          <S.ContainerCardBlackBottom>
            <S.CardBlackBottomTitle>
              Para utilizar o Seguro
            </S.CardBlackBottomTitle>
            <S.CardBlackBottomText>
              Abertura de sinistro via central de atendimento
            </S.CardBlackBottomText>
            <S.CardBlackBottomText>
              <S.UnderlineText>
                {PHONE_SUPPORT_CAPITALS_AND_REGIONS}
              </S.UnderlineText>{' '}
              (Capitais e região metropolitana) <br />
              <S.UnderlineText>
                {PHONE_SUPPORT_OTHER_LOCATIONS}
              </S.UnderlineText>{' '}
              (demais localidades) <br />
              Horário de atendimento de Segunda à Sexta-feira 8h às 20h
            </S.CardBlackBottomText>
          </S.ContainerCardBlackBottom>

          <S.ContentZurich>
            <S.ZurichLeft src={ZurichLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                Fundada na Suíça em 1872, com sede na cidade de Zurique, somos
                uma das seguradoras globais mais experientes do mundo. Com cerca
                de 56 mil funcionários, oferecemos uma ampla gama de produtos e
                serviços de ramos elementares e de vida em mais de 210 países e
                territórios. Atendemos pessoas físicas e jurídicas – de pequenas
                empresas a multinacionais.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>

          <S.ContentFinal>
            <S.ContentTextFinal>
              Seguro Conta Paga garantido pela Zurich Minas Brasil Seguros S/A –
              CNPJ 17.197.385/0001-21 – Código Susep: 05495 e Processo Susep
              15414.900031/2019-96 (Prestamista Capital Fixo) e
              15414.901061/2014-13. (Seguro de Acidentes Pessoais). O registro
              do produto é automático e não representa aprovação ou recomendação
              por parte da Susep. Central de atendimento Zurich: SAC: 0800 284
              4848, Deficiente Auditivo: 0800 275 8585, Horário de atendimento:
              24 horas, 7 dias na semana; Ouvidoria: 0800 770 1061, Horário de
              atendimento: segunda a sexta-feira, das 8h30 às 18h, exceto
              feriados. Link da plataforma digital oficial para registro de
              reclamações dos consumidores dos mercados supervisionados
              <Anchor
                href="https://www.consumidor.gov.br"
                onClick={handleClickLinkContentFinal}
                fontSize="16px"
              >
                (www.consumidor.gov.br).
              </Anchor>
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default AccountPaidInsurance;
